import { useEffect, useRef, useState } from "react";
import Navbar from "../components/navbar";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useApi from "../hooks/useApi";
import useUtils from "../utils/useutils";
import Snowfall from "react-snowfall";

const ResetPassword = ({action, btn}) => {
 const history = useHistory();
 const {requestMaker} = useApi();
 const {setToast, isPending, isSending} = useUtils();
 const [ResetPassword, setResetPassword] =  useState({action, email:""});
 const nameRef = useRef();
 const [suc, setSuc] = useState(false)

 const submitHandler = async(e) => {
  e.preventDefault();
  if(ResetPassword?.email === ''){
    setToast('Enter Email Adderss');
    nameRef.current.focus();
    return;
  }
  isPending('veriy_btn', true)
  const res = await requestMaker('forgotpass/passwords.php', ResetPassword);
  if(res?.status === 'successful'){
    isPending('veriy_btn', "Submit");
    setSuc(true);
  }else {
    isPending('veriy_btn', 'Submit');
    setSuc(false)
  }
 }

 return ( <>
  <div className="my-modal bg-blur">
   <div className="my-col-4 off-4 down-10 bg-faded my-container rad-10  my-bottom-50 down-1 xs-down-20vh">
    <div className="my-col-10 off-1 xs-10 xs-off-1 down-3 xs-down-5">
     <div className="bd-bottom-bold mother bold monR"> <i className="fas fa-angle-left pd-10 c-pointer" onClick={()=> {btn(false)}}></i> Password Reset</div>
     <form action="" className="mother down-8 xs-down-5">
      <div className="mother"><div><input ref={nameRef} placeholder="Email Address" onChange={(e)=> {setResetPassword(prev => ({...prev, email:e.target.value}))}} type="email" className="input bg-white px13" /></div></div>
      <button className="my-btn-sm mother down-5 xs-down-5 bg-color-code-1 white px13 monR" id="veriy_btn" onClick={(e)=> {submitHandler(e)}}>Submit</button>
      {suc && <div className="px13 monR mother down-5 xs-down-8 centered"><span className="pd-10 color-code-1 px13 bold">We have sent a password reset link to your email address.</span></div>}
     </form>
    </div>
   </div>
 </div>
 </> );
}
 
export default ResetPassword;