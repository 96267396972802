import { useEffect, useRef, useState } from "react";
import Navbar from "../components/navbar";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useApi from "../hooks/useApi";
import useUtils from "../utils/useutils";
import EmailVar from "../components/emailvar";
import PageNav from "../components/pagenav";
import ResetPassword from "../components/resetpassword";

const SellerLogin = () => {
 const token = localStorage.getItem('l-seller');
 const history = useHistory();
 const {requestMaker} = useApi();
 const {setToast, isPending, seePassword} = useUtils();
 const [params, setParams] = useState({email:"",  password:""});
 const emailRef = useRef()
 const passwordRef = useRef();

 useEffect(()=> {
  document.title = 'L-Mobile ~ seller Login'
  return(()=> {document.body.style.backgroundColor='white'; document.title = 'L-mobile - Supermaket'})
 })

 useEffect(()=> {
  if(token){
   history.push('/l-seller/account')
  }
 }, [token])

const submitHandler = async(e) => {
  e.preventDefault()
  if(params?.email.trim() === '' ){
   emailRef.current.focus();
   setToast('Please enter email');
   return;
  }
  if(!params?.email.includes('@')){
   emailRef.current.focus();
   setToast('Please enter a valid email');
   return;
  }
  if(params?.password.trim() === '' ){
   passwordRef.current.focus();
   setToast('Please enter password');
   return;
  }
  isPending('sub_btn', true);
  const res = await requestMaker('sellers/login', params);
  if(res?.status === 'successful'){
   localStorage.setItem('l-seller', res?.data);
   history.push('/l-seller/account');
  }else {
   isPending('sub_btn', 'Login');
  }
 }


 const [re, setRe] = useState(false)

 return ( <>
 {re &&  <ResetPassword action={'seller'} btn={setRe} />}
 
 <PageNav/>
 <div className="mother down-8 xs-down-10vh my-fade-in">
  {/* <div className="my-col-4 off-4 my-container"><i className="pd-5 fas fa-arrow-left" onClick={()=> {history.push('/')}}></i></div> */}
   <div className="my-col-6 off-3 rad-10 xs-container bg-faded my-bottom-50 xs-down-10vh">
    <div className="my-col-10 off-1 xs-10 xs-off-1 down-1 xs-down-7">
     <div className="mother bold px20 xs-px20 centered xs-down-2 down-5">Merchants Login</div>
     <div className="mother xs-down-5 down-1 centered monR faded"><span className="px12">Sell Products. Earn Smart. Fast delivery within 2 to 3 hours of order.</span></div>
     <form action="" className="my-col-10 off-1 down-3 xs-down-8 xs-12">
      <div className="mother down-2">
       <div className="my-col-12 down-1 xs-12 xs-down-1"><input placeholder="Email Address" ref={emailRef} type="text" onChange={(e)=> {setParams(prev => ({...prev, email:e.target.value}))}} className="input px13 bg-white" /></div>
      </div>
      <div className="mother down-1 xs-down-4">
       <div className="my-col-12 xs-12 down-1">
        <input id="passwerd-we" placeholder="Password"  ref={passwordRef} type="password" onChange={(e)=> {setParams(prev => ({...prev, password:e.target.value}))}} className="input bg-white px13" /></div>
        <div className="mother down-1"><button className="my-btn-sm down-2 mother xs-down-5 bg-color-code-1 white monR px13" id="sub_btn" onClick={(e)=> {submitHandler(e)}}>Login</button></div>
      </div>
      <div className="mother centered down-4 xs-down-5"><span className="pd-5 rad-10 c-pointer color-code-1 px13 monR" onClick={()=> {setRe(true)}}>Forgotten Password ?</span></div>
     </form>
   <div className="mother down-1 xs-down-5 centered"><span className="pd-5 color-code-1 px13 monR" onClick={()=> {history.push('/seller_sign_up')}}>I haven't registered <i className="fas fa-angle-right"></i> </span></div>
    </div>
   </div>
 </div>
 </> );
}
 
export default SellerLogin;