import { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Accounts = ({title}) => {
 const caretRef = useRef();
 const optionsRef = useRef();
 const history = useHistory();
 var x = 0; 



 const dpHandler = () => {
  if(x === 0){
   caretRef.current.classList.add('rotate')
   optionsRef.current.style.display = 'block';
   x = 1
  }else {
   caretRef.current.classList.remove('rotate')
   optionsRef.current.style.display = 'none';
   x = 0
  }
 }


 return ( <>
  <div className="mother xs-px10 bd-bottom" onClick={dpHandler}>
   <span className='black upper-case'>{title}</span>
   <span className='faded xs-down-3 fl-right values_from_array' ref={caretRef}><i className='fas fa-angle-right'></i></span>
  </div>
  <div className="mother values_from_array fade-in my-d-none" ref={optionsRef}>
  <div className="mother sub_car_dp top-8 pd-5">
   <div className="xs-11 my-col-12 down-3 xs-down-5 pd-5"  onClick={()=> {history.push('/user_sign_up')}}> 
     <span className={`px10 pd-5 faded-2 upper-case`}>User Sign Up</span>
   </div>
   <div className="xs-11 my-col-12 down-3 xs-down-5 pd-5"  onClick={()=> {history.push('/user_login')}}> 
     <span className={`px10 pd-5 faded-2 upper-case`}>User Login</span>
   </div>
  </div>
  </div>
 </> );
}
 
export default Accounts;