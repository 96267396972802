import { useContext, useEffect, useState } from "react";
import { AiOutlineBars, AiOutlineBell, AiOutlineCopy, AiOutlineCreditCard, AiOutlineHistory, AiOutlineHome, AiOutlineLogout, AiOutlineSetting, AiOutlineUser, AiOutlineUserAdd, AiOutlineUserSwitch, AiOutlineWhatsApp} from "react-icons/ai";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { AgentContext } from "../../context/agentcontext";
import Updatebank from "./updaatebank";
import Referees from "./referee";
// import AddBank from "./addbank";

const Layout = ({active}) => {
  const [out, setOut] = useState(false);
  const history = useHistory();
  const {currentUser, getAgent, referee, logoutOut} = useContext(AgentContext);
  const token = localStorage.getItem('l-agent')
  const [changeBank, setChangeBank] = useState(false);
 
  useEffect(() => {
   if(currentUser?.account_number === null) {
    setChangeBank(true);
   }
  }, [currentUser])


  const whatsapp =()=> {
    window.open(`https://api.whatsapp.com/send?phone=08137242631&text=Hello%2C%20my%20ref_code%20is%20${currentUser?.ref_code}%0A%0AMessage%3A%20`)
  }
 
  const [referal, setReferal] = useState(false)

  return (  <>
 {referal && <Referees setReferal={setReferal}/>}
  {changeBank &&  <Updatebank setChangeBank={setChangeBank}/>}
   <div className="seller-nav-bar bg-faded hidden-ls">
    <div className="my-container">
    <div className="my-col-12">
    <div className="xs-1 xs-down-3"><span className="profile-pics bg-faded"><i className="fas fa-user"></i></span></div>
     <div className="xs-9 xs-top- xs-down-2 upper-case">
      <span className="mg-10 px10 bold monR">{currentUser?.name}</span>
      <div className="mother xs-top-2">
      <span className="mg-10 pd- bg-color-co color-code faded px10 monR">Welcome</span>
     </div>
  </div>
    <div className="xs-2 xs-down-3 right"><span className="profile-pics bg-faded color-code-1" onClick={()=> {setReferal(true)}}><AiOutlineUserAdd/></span><sup className="notification-dot"></sup></div>
   </div>
  </div>
 </div>
 {out && <div className="my-modal my-fade-in bg-blur">
  <div className="my-col-4 off-4 rad-10 down-10 xs-down-20vh my-container bg-white my-bottom-50">
   <div className="my-col-10 off-1 xs-10 xs-off-1 xs-down-5 down-3">
    <div className="mother bd-bottom"><span className="px13 bold black-2 monR">Logout</span></div>
    <div className="mother down-5 monR faded xs-down-5">
     <span>Are you sure you want to logout ?</span>
    </div>
    <div className="down-5 mother righ xs-down-8">
     <span className="pd-10 rad-10 bg-color-code-2 color-code-1 px13" onClick={logoutOut}>Yes</span>
     <span className="pd-10 rad-10 bg-color-code-1 white mg-10 px13" onClick={()=> {setOut(false)}}>Cancel</span>
    </div>
   </div>
  </div>
 </div> }
 <div className="agent-code-bar bg-color-code-1 hidden-xs">
  <div className="container down-60"> 
   <div className="anchors down-50 hover down-10" onClick={()=> {history.push('/agents/dashboard')}}>
     <span className="alice px30"><AiOutlineBars/></span>
   </div>
   <div className="anchors down-60 hover" title="Referees"  onClick={()=> {setReferal(true); setChangeBank(false)}}>
     <span className="alice px30"><AiOutlineUser/></span>
   </div>
   <div className="anchors down-60 hover" title="Prefrences"  onClick={()=> {setChangeBank(true); active = 'home'}}>
    <span><span className="alice px30"><AiOutlineSetting/></span></span>
   </div>
   <div className="anchors down-60 hover" title="Customer Service"  onClick={()=> {whatsapp(); active = 'home'}}>
    <span><span className="alice px30"><AiOutlineWhatsApp/></span></span>
   </div>
   <div className="anchors down-60 hover" onClick={()=> {setOut(true)}}>
    <span><span className="alice px30"><AiOutlineLogout/></span></span>
   </div>
  </div>
 </div>
  
 <div className="bottom-nav hidden-ls bg-color-code-1 alice">
  <div className="xs-container xs-top-3 centered">
    <div className="xs-4" onClick={()=> {whatsapp()}}>
    <div className="mother xs-down-10"> <span className={`px20 ${active == 'home' && 'color-code-1'}`}><AiOutlineWhatsApp/></span></div>
     <div className="mother xs-top-5"><span className="px9">Live Chat</span></div>
    </div>  
    <div className="xs-4" onClick={()=> {setChangeBank(true); active = 'home'}}>
    <div className="mother xs-down-10"> <span className={`px20 ${active == 'home' && 'color-code-1'}`}><AiOutlineCreditCard/></span></div>
     <div className="mother xs-top-5"><span className="px9">Bank</span></div>
    </div>
    <div className="xs-4"  onClick={()=> {setOut(true)}}>
    <div className="mother xs-down-10"> <span className={`px20 ${active == 'home' && 'color-code-1'}`}><AiOutlineLogout/></span></div>
     <div className="mother xs-top-5"><span className="px9">Logout</span></div>
    </div>
  </div>
 </div>
  </>);
}
 
export default Layout;