import { useContext } from "react";
import { AuthContext } from "../context/authcontex";
import Navbar from "./navbar";
import UserNavbar from "./usernav";

const PageNav = ({active}) => {
 const {currentUser} = useContext(AuthContext);
 const whatsapp =()=> {
  window.open(`https://api.whatsapp.com/send?phone=+2348187898582`)
 }


 return (<>
 <div className="top-fixed-nav upper-case bg-black white centered">
 <marquee behavior="3000s" direction="">
   <span className="off-1 xs-off-1"> <i className="fas fa-circle px9 color-code-1"></i> <b className="mg-10">customer care service - 08187898582 </b></span>
   <span className="off-1 xs-off-1"> <i className="fas fa-circle px9 color-code-1"></i> <b className="mg-10">No delivery after 8pm daily</b></span>
   <span className="bold off-1 xs-off-1"> <i className="fas fa-circle px9 color-code-1"></i> 
   <span className="mg-10">Products Delivery is within 24hrs within Lagos and  2 - 5 days outside Lagos</span>
  </span>
  </marquee> 
</div>
 <div className="mother down-2 xs-down-6">{currentUser === null ? <Navbar/>: <UserNavbar focus={active} currentUser={currentUser} />}</div>
<span className="customer-care monR  bg-color-code-1" onClick={whatsapp}>
 <div className="mother down-30 xs-down-2vh  white "> <i className="fab px20 fa-whatsapp"></i>
  </div>
</span>
 </> );
}
 
export default PageNav;