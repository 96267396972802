import { useEffect, useRef, useContext,  useState } from "react";
import useUtils from "../../utils/useutils";
import useApi from "../../hooks/useApi";
import { CategoryContext } from "../../context/categorycontext";
import { SellerContext } from "../../context/sellercontext";

const EditProduct = ({setEditProduct, item}) => {
 const token = localStorage.getItem('l-seller');
 const {allCat, state, path}  = useContext(CategoryContext);
 const {getProduct} = useContext(SellerContext)
 const {requestMaker} = useApi()
 const {clickHandler, setToast, isPending, copyTextToClipboard, isSending} = useUtils();
 const [isLoading, setIsLoading] = useState(null);
 const nameRef = useRef()
 const subRef = useRef()
 const priceRef = useRef()
 const locationRef = useRef()
 const categoryRef = useRef()
 const ratRef = useRef()
 const [whichCat, setWhichCat] = useState();
 const [subCat, setSubCat] = useState([]);
 const [Delete, setDelete] = useState(null); 
 
 useEffect(() => {
  const arr = allCat?.find((i) => i.cat_name === whichCat);
  if(arr){
   const subCat = JSON.parse(arr?.sub_cat);
   setSubCat(subCat, whichCat) 
  }
 }, [allCat, whichCat])

 const [preview, setPreview] = useState({
  'image_1':null,
  'image_2':null,
  'image_3':null
 });

 const [locations, setLocations] = useState([]);

 const [items, setitems] = useState({
  image_1:"",
  image_2:"",
  image_3:"",
  item_id:"",
  item_name:"",
  main_cat:"Groceres",
  category:"",
  sub_cat:"",
  price:"",
  availability:"1",
  slash_price:"",
  status:"",
  description:"",
  ratings:"",
  owner:"",
  phone:"",
  total_sales:"",
  weight:"",
  email:"",
  variations:[],
  token:token
 });


 useEffect(()=> {
  setitems(item)
  setWhichCat(item?.category)
  setPreview(prev =>  ({...prev, 
   image_1:item.image_1 === ""? null : path + item.image_1, 
   image_2:item.image_2 === ""? null : path + item.image_2, 
   image_3:item.image_3 === ""? null : path + item.image_3,
 }));
 setLocations(JSON.parse(item?.variations))
 }, [])



 const handleImageChange = async(event, arr_index) => {
  const file = event.target.files[0];
  let url;
  if (file && (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') && file.size < 4 * 1024 * 1024) {
   setIsLoading('uploading');
   const reader = new FileReader();
   reader.readAsDataURL(file);
    reader.onload = () => {
      const img = new Image();
      img.src = reader.result;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const MAX_WIDTH = 400;
        const MAX_HEIGHT = 400;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
        const dataURL = canvas.toDataURL(file.type);
        url = dataURL;
       };
      };    
      let oldImage;
      if(arr_index === '1'){
       oldImage = items.image_1;
      }else if (arr_index === '2'){
       oldImage = items.image_2;
      }else {
       oldImage = items.image_3;
      }
      // send requet to db;
     const res =  await UploadToDB(file, arr_index, oldImage);
     if(res === true) {
      arr_index === '1' && setPreview((prev)=> ({...prev, image_1:url}));
      arr_index === '2' && setPreview((prev)=> ({...prev, image_2:url}));
      arr_index === '3' && setPreview((prev)=> ({...prev, image_3:url}));
      setIsLoading('change');
     }else {
      arr_index === '1' && setPreview((prev)=> ({...prev, image_1:null}));
      arr_index === '2' && setPreview((prev)=> ({...prev, image_2:null}));
      arr_index === '3' && setPreview((prev)=> ({...prev, image_3:null}));
      setIsLoading('change');
     }
  } else {
    setToast('Please select a JPG, JPEG, or PNG image and must be less than 4mb in size');
   };
  }

 const UploadToDB = async (file, index, old_image) => {
  const res = await requestMaker('utils/upload', {token:token, image:file, old_image});
  if(res?.status === 'successful'){
   index === '1' && setitems((prev)=> ({...prev, image_1:res?.data}));
   index === '2' && setitems((prev)=> ({...prev, image_2:res?.data}));
   index === '3' && setitems((prev)=> ({...prev, image_3:res?.data}));
   return true;
  }else {
   setToast('could not upload image. please try again')
   return false;
  }
 }


 const addLocation = (value) => {
  var s = locations?.filter((i) => i.location == value);
  if(s.length > 0){
   setToast('You added this location already');
   return;
  }
  const obj = {location:value};
  setLocations(prev => [...prev, obj])
 }

 const removeLocation = (value) => {
  var s = locations?.filter((i) => i.location !== value);
  if(s.length > 0) {
   setLocations(s)
  }
 }

  const saveItemHandler = async(e) => {
   if(items?.item_name.trim() === ''){
    setToast('Please enter item name');
    nameRef.current.focus();
    return;
   }
   if(items?.description.trim() === ''){
    setToast('Please describe this item');
    ratRef.current.focus();
    return;
   }
   if(items?.category.trim() === ''){
    setToast('Please enter item category');
    categoryRef.current.focus();
    return;
   }
   if(items?.sub_cat.trim() === ''){
    setToast('Please enter item sub-category');
    subRef.current.focus();
    return;
   }
   if(items?.price.trim() === ''){
    setToast('Please enter item price');
    priceRef.current.focus();
    return;
   }

   if(items?.image_1 === '' & items?.image_2 === '' & items?.image_2 === ''){
    setToast('Please upload at least one image');
    return;
   }
   items['old_item_name'] = item?.item_name;
   items['action'] = 'update_item_all_locations';
   items['token'] = token;
   isPending('update_item_btn', true);
   const res = await requestMaker('/sellers/product', items);
   if(res?.status === 'successful'){
   await getProduct(token)
   isPending('update_item_btn', 'Save Changes');
   setToast('Changes Saved !');
  }else {
   isPending('update_item_btn', 'Save Changes');
   setToast('There Was an Error Saving Changes. Please try again later');
  }
 }

 const resubmitHander = async(e) => {
  if(items?.item_name.trim() === ''){
   setToast('Please enter item name');
   nameRef.current.focus();
   return;
  }
  if(items?.description.trim() === ''){
   setToast('Please describe this item');
   ratRef.current.focus();
   return;
  }
  if(items?.category.trim() === ''){
   setToast('Please enter item category');
   categoryRef.current.focus();
   return;
  }
  if(items?.sub_cat.trim() === ''){
   setToast('Please enter item sub-category');
   subRef.current.focus();
   return;
  }
  if(items?.price.trim() === ''){
   setToast('Please enter item price');
   priceRef.current.focus();
   return;
  }

  if(items?.image_1 === '' & items?.image_2 === '' & items?.image_2 === ''){
   setToast('Please upload at least one image');
   return;
  }
  items['action'] = 'resubmit';
  items['token'] = token;
  items['old_item_name'] = item?.item_name
  isPending('update_item_btn', true);
  const res = await requestMaker('/sellers/product', items);
  if(res?.status === 'successful'){
  await getProduct(token)
  isPending('update_item_btn', 'Save Changes');
  setToast('Your Product has been submitted and is currently under review by L-Mobile product regulatory team');
 }else {
  isPending('update_item_btn', 'Save Changes');
  setToast('There Was an Error submitting Product. Please try again later');
 }
}


const deleteProductHandler = async () => {
  const imArr = [item.image_1, item.image_2, item.image_3]
  const images = JSON.stringify(imArr);
  const params = {token, item_id:item?.item_id, images:images, action:'delete_item'}
  isSending(true)
  const res = await requestMaker('/sellers/product', params);
  if(res?.status === 'successful'){
    await getProduct(token);
    isSending(false)
    setToast('Product Deleted Successful');
  }else {
    isSending(false)
  }
}

 return ( <>
 <div className="add-item-modal">
  <div className="add-container xs-12 in-ov-scroll">
   <div className="my-col-10 off-1 xs-10 xs-off-1 xs-down-20 down-3">
    <div className="mother bd-bottom down-5">
     <span className="px13 bold">View Product  <i className="fas pd-5 rad-10 faded fa-copy bg-faded px13 mg-10" onClick={()=> {copyTextToClipboard(`https://lmobile.com.ng/item/${items?.id}`)}}></i> </span>
     <span className="my-btn-s fl-right px13 pd-5 top-1 c-pointer" onClick={(e) => {setEditProduct(e)}}><i className="fas fa-times"></i></span>
   </div>
   <div className="mother down-3">
    {item?.review_status == '2' && <div className="px13 color-code-1 pd-5 bg-color-code-2"><b>Product Rejected</b>: {item.reasons == 'null' ? 'No Reasons': item.reasons}</div>}
    
    <div className="mother down-1">
     <span className="px10">Product Name</span>
     <div className="mother"><input type="text" maxLength={50} ref={nameRef} value={items?.item_name} onChange={(e)=>{setitems(prev => ({...prev, item_name:e.target.value}))}} className="input px10" /></div>
   </div>
   <div className="mother down-1">
     <span className="px10">Product Description</span>
     <div className="mother"><input  ref={ratRef} type="text" maxLength={100} placeholder="e.g Gucci, lois Viton"  value={items?.description} onChange={(e)=>{setitems(prev => ({...prev, description:e.target.value}))}} className="input px10" /></div>
   </div>
    <div className="mother down-2">
     <span className="px10">Product Category <span className="mgl-10 pd-5 faded c-pointer" title="Add new sub category"> <i className="fas fa-plus "></i> </span> </span>
     <div className="mother">
      <select className="input px10" ref={categoryRef} value={items?.category} onChange={(e)=>{setWhichCat(e.target.value); setitems(prev => ({...prev, category:e.target.value}))}} id="">
       <option value="">---</option>
       {allCat?.map((i, index) => (
         <option value={i.cat_name} key={'sdsd' + index}>{i?.cat_name}</option>
       ))}
      </select>
      <div className="mother down-2">
      <span className="px10">Product Sub-cat <span className="mgl-10 pd-5 faded c-pointer" title="Add new sub category"> <i className="fas fa-plus "></i> </span> </span>
      <select className="input px10" ref={subRef} value={items?.sub_cat} onChange={(e)=>{setitems(prev => ({...prev, sub_cat:e.target.value}))}} id="">
       <option value="">...</option>
       {subCat?.map((i, index) => (
         <option value={i.name} key={'jkjk' + index}>{i?.name}</option>
       ))}
      </select>
      </div>
     </div>
   </div>
   <div className="mother down-1">
     <span className="px10">Price</span>
     <div className="mother"><input min={0} ref={priceRef} type="number" value={items?.price} onChange={(e)=>{setitems(prev => ({...prev, price:e.target.value}))}} className="input px10" /></div>
   </div>
   <div className="mother down-1">
     <span className="px10">Slash Price (Optional)</span>
     <div className="mother"><input min={0} type="number" value={items?.slash_price} onChange={(e)=>{setitems(prev => ({...prev, slash_price:e.target.value}))}} className="input px10" /></div>
   </div> 
   <div className="mother down-1">
     <span className="px10">Weight (Kg)</span>
     <div className="mother"><input min={0} type="number" value={items?.weight} onChange={(e)=>{setitems(prev => ({...prev, weight:e.target.value}))}} className="input px10" /></div>
   </div> 
    <div className="mother down-1">
     <span className="px10">Ratings <i className="fas fa-star px10 color-code-1"></i> </span>
     <div className="mother">
      <input  type="number" min={0} max={5} value={items?.ratings} onChange={(e)=>{setitems(prev => ({...prev, ratings:e.target.value}))}} className="input px10" id=""/>
     </div>
   </div>
   
   <div className="mother down-1">
     <span className="px10">Product Enquiry Contact</span>
     <div className="mother">
      <input  type="tel" value={items?.phone} onChange={(e)=>{setitems(prev => ({...prev, phone:e.target.value}))}} className="input px10" id=""/>
     </div>
   </div>
    {item?.review_status !== '1' && <>
    <div className="mother down-2">
      <span className="px10">Available Locations <span className="mgl-10 pd-5 faded c-pointer" title="Add new sub category"> <i className="fas fa-plus "></i> </span> </span>
      <select className="input px10" ref={locationRef} onChange={(e)=>{addLocation(e.target.value)}}>
       <option value="">---</option>
       {state?.map((i, index) => (
         <option value={i?.value} key={'jkjk' + index}>{i?.value}</option>
       ))}
      </select>
   </div>
    {locations?.length > 0 &&  <div className="mother down-2 my-bottom-10">
   {locations?.map((i) => (
   <span className="bg-color-code-2 mg-5 c-pointer down-1 px10 color-code-1 pd-5" onClick={() => {removeLocation(i.location)}} title="Click to remove">{i.location}</span>
  ))}  
   </div>}
    </>}
    <div className="mother down-1">
     <span className="px10">Availability Status</span>
     <div className="mother">
      <select value={items?.availability}  onChange={(e)=>{setitems(prev => ({...prev, availability:e.target.value}))}} name="" id="" className="input px10">
       <option value="1">Instock</option>
       <option value="0">Out of Stock</option>
      </select>
     </div>
   </div>
 <div className="my-col-10 down-3 xs-12 xs-down-8">
  <div><span className="px10"> Item images <span className="color-code-1">( Must be White background/png file of 4mb max )</span></span></div>
 <div className="my-col-4 down-2 xs-4 xs-down-5">
  <input type="file" accept=".jpg,.jpeg,.png" id='upload-pp_2' hidden onChange={(e)=> {handleImageChange(e, '1')}} />
   <div className="upload-cat-img-container img-container-w" onClick={()=> {clickHandler('upload-pp_2')}}>
    <div className="scarf-1"> 
     {isLoading === 'uploading' && <i className="fas fa-spinner fa-pulse px13"></i>}
     {isLoading === 'change' && <i className="fas fa-image px13"></i>}
      </div>
     {preview.image_1?  <img src={preview?.image_1} alt="" />: <i className="fas fa-image px13"></i>}
     </div>
   </div>
     <div className="my-col-4 down-2 xs-4 xs-down-5">
     <input type="file" accept=".jpg,.jpeg,.png" id='upload-pp_1' hidden onChange={(e)=> {handleImageChange(e, '2')}} />
     <div className="upload-cat-img-container img-container-w" onClick={()=> {clickHandler('upload-pp_1')}}>
          <div className="scarf-1"> 
          {isLoading === 'uploading' && <i className="fas fa-spinner fa-pulse px13"></i>}
          {isLoading === 'change' && <i className="fas fa-image px13"></i>}
           </div>
          {preview.image_2 ?  <img src={preview.image_2} alt="" />: <i className="fas fa-image px13"></i>}
         </div>
     </div>
     <div className="my-col-4 down-2 xs-4 xs-down-5">
     <input type="file" accept=".jpg,.jpeg,.png" id='upload-pp_3' hidden onChange={(e)=> {handleImageChange(e, '3')}} />
     <div className="upload-cat-img-container img-container-w" onClick={()=> {clickHandler('upload-pp_3')}}>
          <div className="scarf-1"> 
          {isLoading === 'uploading' && <i className="fas fa-spinner fa-pulse px13"></i>}
          {isLoading === 'change' && <i className="fas fa-image px13"></i>}
           </div>
          {preview.image_3?  <img src={preview.image_3} alt="" />: <i className="fas fa-image px13"></i>}
         </div>
     </div>
 </div>
 {item?.review_status == '2' &&  <div className="my-col-7 down-10 my-bottom-50 xs-12 xs-down-10"><span id="update_item_btn" className="mother centered my-btn-sm bg-color-code-1 white px13" onClick={()=> {resubmitHander()}}>Re-submit</span></div>} 
 {item?.review_status == '1' &&  <div className="my-col-7 down-10 my-bottom-50 xs-12 xs-down-10"><span id="update_item_btn" className="mother centered my-btn-sm bg-color-code-1 white px13" onClick={()=> {saveItemHandler()}}>Save Changes</span></div>} 
   </div>
   <div className="mother down-2 my-bottom-50 xs-down-2">
    <span className="px13 pd-10 bg-faded monR c-pointer"  onClick={(e)=> {setDelete(item?.id)}}>Delete Product <i className="fas fa-trash-alt px13 mg-10"></i></span>
   </div>
   </div>
   {Delete === item?.id && <div className="my-modal bg-blur">
          <div className="my-col-4 xs-10 xs-off-1 off-4 rad-10 my-bottom-50 xs-down-20vh down-8 bg-white">
            <div className="my-col-10 xs-10 xs-off-1 xs-down-10 off-1 down-5 monR">
              <div className="bd-bottom-bold px13 blold">Delete Product</div>
              <div className="black-2 mother down-5 bold xs-px13 xs-down-5">Are you sure to delete {item?.item_name?.slice(0, 10)} ?</div>
              <div className="mother px13 faded xs-down-5">Product will be permanently deleted</div>
              <div className="mother down-5 xs-down-8">
                <span className="pd-10 bg-color-code-1 rad-10 white px13 c-pointer" onClick={deleteProductHandler}>Yes</span>
                <span className="pd-10 bg-color-code-2 rad-10 color-code-1 mg-10 px13 c-pointer" onClick={()=> {setDelete(null)}}>Cancel</span>
            </div>
           </div>
       </div>
    </div>}
  </div>
 </div>
 </> );
}
 
export default EditProduct;