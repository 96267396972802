import axios from 'axios';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import useUtils from '../utils/useutils';

const useApi = () => {
 const {setToast, errorHandler, isPending} = useUtils();
 const history = useHistory()

 const requestMaker = async (api, params) => {
 	const form = new FormData();
  const key = Object.keys(params);
  const value = Object.values(params);
  for(var i = 0; i < key.length; i++){
   form.append(key[i], value[i]);
  }
  const request = {
    method: 'post',  
    url: api, 
    headers: { 'Content-Type': 'multipart/form-data'}, 
    data: form,
  }  
  try {
   const status = await axios(request);
   const res = status?.data;
   if(res?.status == 'successful'){      // invalid login credenils
    return res; 
   }
   if(res?.status === 'SESSION EXPIRED'){
    return res; 
    return;
   }else {
    setToast(`<div class='down-3 mother'>${res?.data}</div>`)
   }
   if(res?.status === 'deleted'){
    localStorage.removeItem('lm-user');
    history.push('/');
    return;
   }else {
    setToast(`<div class='down-3 mother'>${res?.data}</div>`)
   }
  }catch (error) {
    if(error.response?.status === 500 ){
     setToast(`<div class='black'>Error 500 - Internal Server Error</div>
     <div class='down-5 mother'>Unable to connect to the server. Please try again later.</div>
     <div class='mother down-2'><a href="">Learn More</a></div>`)
     isPending('login_btn', 'Login')
     return;
    }
    if(error.response?.status === 400 ){
     setToast(`<div class='black'>Error 400 - Internet Error</div>
     <div class='down-3 mother'>Unable to connect to the server. Please check your internet and try again.</div>
     <div class='mother down-3'><a href="">Learn More</a></div>`)
     isPending('login_btn', 'Login')
     return;
    }
   if(error.message === 'Network Error' ){
    setToast(`<div class='black'>Error 400 - Internal Server Error</div>
     <div class='down-3 mother'>Unable to connect to the server. Please try again later.</div>
     <div class='mother down-3'><a href="">Learn More</a></div>`)
     isPending('login_btn', 'Login')
     return;
   }
  }
}

// fix handle errors well..

const handleBankVerify = async (params) => {
  const request = {
    method: 'GET',  
    url: `https://api.paystack.co/bank/resolve?account_number=${params?.account_number}&bank_code=${params?.bank_code}`, 
    headers:{'Authorization': 'Bearer sk_test_749876991a0b83eaa23f8a8ce367dfd3fd222ec8'} 
   }  
   try{
    const result = await axios(request);
    return result.data;
  } catch (error) {
   if(error.message === 'Network Error'){
    setToast(`<div class='black'>Error 400 - Internal Server Error</div>
    <div class='down-3 mother'>Unable to connect to the server. Please try again later.</div>
    <div class='mother down-3'><a href="">Learn More</a></div>`)
    // isPending('login_btn', 'Login')
    return;
    }
    }
   }
 
return {
  requestMaker,
  handleBankVerify
 };
}
 
export default useApi;