import { useContext, useEffect, useState } from "react";
import PageNav from "./pagenav";
import { AuthContext } from "../context/authcontex";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { OrderContext } from "../context/ordercontext";
import useUtils from "../utils/useutils";
import CatSkeleton from "./skeleton/catSeleton";
import moment from "moment";

const MyOrders = () => {
 const {token} = useContext(AuthContext);
 const {orders, getOrders} = useContext(OrderContext)
 const history = useHistory(); 
 const {formatNumber, formatNigerianPhoneNumber, formatTransactionTime} = useUtils()
 const [orderStatus, setOrderStatus] = useState(0);
 const [myOrders, setMyOrders] = useState([])
 const [pending, setPending] = useState(0) 
 const now = moment();
 const formattedTime = now.format('YYYY-MM-DD HH:mm:ss');
 useEffect(()=> {
   if(!token){
     history.push('/')
    }
  }, [token])
  
  useEffect(()=> {
   if(token){
    getOrders(token);
   }
  }, [token])

 useEffect(() => {
  const pending = orders?.filter((i) => i.delivery_status == orderStatus);
  const len = orders?.filter((i) => i.delivery_status == 0);
  setMyOrders(pending);
  setPending(len?.length)
 }, [orders, orderStatus])

 const [viewOrder, setViewOrders] = useState(null);
 
 return ( <>
 <PageNav active={'orders'} />
 <div className="mother down-8 xs-down-20 my-bottom-50">
  <div className="my-col-10 off-1 xs-10 xs-off-1">
   {/* <div className="mother down-1"><i className="fas fa-arrow-left pd-5" onClick={()=> {history.push('/')}}></i></div> */}
   <div className="bd-bottom mother down-1 xs-down-2"><span className="bold monR black-2"> My Orders</span></div>
   <div className="xs-down-8 down-3 mother">
    <div onClick={()=> {setOrderStatus(0)}} className={`${orderStatus === 0 && 'color-code-1'} my-col-2 xs-4 bd-bottom px13`}>Pending {pending > 0 && <sup className="pd-5 bg-color-code-2 px10 color-code-1">{pending}</sup>}</div>
    <div onClick={()=> {setOrderStatus(1)}} className={`${orderStatus === 1 && 'color-code-1'} off-1 my-col-2 xs-4 xs-off-2 bd-bottom px13`}>Delivered</div>
   </div>
   <div className="mother down-2 xs-down-3">
   <div className="hidden-xs"> {orders === null &&  <CatSkeleton slideToShow={5}/>}</div>
   <div className="hidden-ls"> {orders === null &&  <CatSkeleton slideToShow={3}/>}</div>


   {myOrders?.map((i, index) => (
   <div className="my-col-4" key={index + 'jskdjsd'}>
    {viewOrder === `open${i.id}`  &&
    <div className="my-modal" onClick={()=> {setViewOrders(null)}}>
      <div className="my-col-6 off-3 xs-container xs-down-10 down-3 rad-10 bg-white my-bottom-20" onClick={(e) => {e.stopPropagation()}}>
        <div className="my-col-10 off-1 down-3 pd-10 bold bd-bottom"><i className="fas fa-shopping-cart pd-10"></i> <span>Order Ticket</span></div>
        <div className="my-col-10 off-1 xs-container">
          <div className="mother down-1 px10 pd-10">
          <div className="my-col-3 xs-3">
            <div className="mother"><span className="bold" >Order ID:</span> <br /> <span className="faded px10">{i.order_id}</span></div>
          </div>
          <div className="my-col-3 xs-3">
            <div className="mother"><span className="bold" >Total Items: </span> <br /> <span className="faded px10">{JSON.parse(i.items)?.length}</span></div>
          </div>
          <div className="my-col-3 xs-3">
            <div className="mother"><span className="bold" >Total Paid:</span>  <br /> <span className="faded px10">N {formatNumber(i.total_paid)}</span></div>
          </div>
          <div className="my-col-3 xs-3">
            <div className="mother"><span className="bold" >Transaction Date: </span> <br /> <span className="faded px10">{formatTransactionTime(i?.trn, formattedTime)?.slice(0,14)}</span> </div>
          </div>
          </div>
          <div className="mother down-1 px10 bold pd-10 bg-faded"><span className="upper-case ">Items</span></div>
          <div className="mother down-3">
          <div className="mother pd-10 in-sc-order">
            {JSON.parse(i.items).map((x, indexs) => (
              <div className="my-col-12 top-1 xs-12 xs-down-2" key={'iyw' + indexs}>
               <div className="my-col-8 xs-8">
                <span className="px10 bold">{x.item_name.slice(0,30)}</span>
                <div className="mother top-3 xs-top-4"><span className="px10 faded">Quantity: <b>{x.quantity}</b></span></div>
              </div>
              <div className="my-col-4 xs-4 right">
                <span className="px10 faded"><b>NGN {formatNumber(x.price)}</b></span>
             </div>
            </div>
           ))} 
         </div>
         <div className="mother pd-10 bg-faded bold px10">
          <span className="upper-case">Total</span>
          <span className="upper-case fl-right">NGN {formatNumber(i.total_paid)}</span>
        </div>
       </div>
        <div className="mother down-1 xs-down-5">
          {i.delivery_status == 0 &&    <div className="mother">
          <div className="mother">
            <span className="px10 bold">Delivery Status:<div className="mother"><span className=" rad-10 pd-0 bg-color-code- color-code-1">{i.dl_name ? i.dl_name:"Your order is on it's way"}</span></div></span>
          </div>
           {/* {i.dl_name &&  <div className="mother xs-down-1">
            <div className="mother"><span className="px9 faded">Contact personel</span></div>
            <span className="px10 bold pd-10 color-code-1 bg-faded c-pointer" onClick={()=> {window.open(`https://api.whatsapp.com/send?phone=${formatNigerianPhoneNumber(i.dl_phone)}`)}} ><i className="fab fa-whatsapp"></i></span>
            <a href={`tel:${i.dl_phone}`} className="px10 bold pd-10  color-code-1 bg-faded mg-10"><i className="fa fa-phone"></i></a>
          </div>} */}
          </div>}
          <div className="mother down-1 xs-down-3">
           <span className="px10 bold">Address: <div><span className="faded">{i.address} ({i.location})</span></div></span>
           </div>
          <div className="mother down-1 xs-down-3">
           <span className="px10 bold">Phone Number: <div><span className="faded">{i.phone}</span></div></span>
           </div>
          <div className="mother down-1 xs-down-8">
            <span className="px10 anchors bg-faded unset-mg faded">Delivery Code: <div><span className="black-2 bold">{i.delivery_code}</span></div></span>
           </div>
        </div>
        </div>
      </div>
    </div>
 }

    <div  className={`my-b-shadow pd-10 my-col-11 down-2 xs-12 xs-down-2 rad-10 ${i.delivery_status == 0 ?'bg-white':"bg-white"}`} onClick={()=> {setViewOrders(`open${i.id}`)}}>
      <div className="my-col-2 xs-2"><span className={`pd-10 profile-pics ${i.delivery_status == 0 ?'bg-faded color-code-1':"bg-faded faded"}`}><i className="fas fa-bag-shopping"></i></span></div>
      <div className="my-col-10 xs-10">
        <div><span className="px13 bold">Order-<span className="px12">{i.order_id}</span> </span></div>
        <div className="my-mother">
          <div className="my-col-4 xs-4">
            <div><span className="px10 bold">total Items: <span className="faded">{JSON.parse(i.items)?.length}</span></span></div>
          </div>
          <div className="my-col-8 xs-8">
            <div><span className="px10 bold">total Paid: <span className="faded">NGN {formatNumber(i.total_paid)}</span></span></div>
          </div>
        </div>
        <div className="my-mother">
          <span className="my-mother down-1 px10 faded"> <i className="fas fa-cl green"></i> {formatTransactionTime(i?.trn, formattedTime)}</span>
        </div>
      </div>
    </div>
   </div>    
  ))}
   </div>
  </div>
  
 </div>
 
 </> );
}
 
export default MyOrders;