// import { useEffect, useState } from "react";

import Slider from "react-slick/lib/slider";
import Bglanding from "../components/bglanding";
import Category from "../components/category";
import Footer from "../components/footer";
import Navbar from "../components/navbar";
import PageNav from "../components/pagenav";
import Sidebar from "../components/sidebar";
import TopSelling from "../components/topselling";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import NewPage from "../components/homelanding";

// import Alert from 'react-bootstrap/Alert';
const Home = () => {
  const history = useHistory();
 const settings = {
  dots: false,
  fade: false,
  arrows: false,
  infinite: false,
  speed: 1000,
  autoplay: true,
  autoplaySpeed: 7000,
  pauseOnHover: true, // prevent pausing on hover
  slidesToShow: 1,
  slidesToScroll: 1
 };

 return ( 
  <>
 <NewPage/>   
   <div className="mother bg-faded">
    <div className="my-col-10 off-1 down-5 xs-container xs-down-10 hidden-ls my-bottom-50">
     <Footer/>
    </div>
    <div className="my-col-10 off-1 down-5 xs-container hidden-xs xs-down-10">
     <Footer/>
    </div>
   </div>
  </>
 );
}
export default Home;