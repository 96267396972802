import { useEffect, useRef, useState } from "react";
import Navbar from "../components/navbar";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useApi from "../hooks/useApi";
import useUtils from "../utils/useutils";
import PageNav from "./pagenav";
import Snowfall from "react-snowfall";

const UserLogin = () => {
 const history = useHistory();
 const {requestMaker} = useApi();
 const {setToast, isPending, seePassword} = useUtils();
 const [params, setParams] = useState({email:""});
 const emailRef = useRef()

 useEffect(()=> {
  document.title = 'L-Mobile ~  User Login'
  // document.body.style.background = '#f3f3f3';
  return(()=> {document.body.style.backgroundColor='white';  document.title = 'L-Mobile - stores'})
 })

const submitHandler = async(e) => {
  e.preventDefault()
  if(params?.email.trim() === '' ){
   emailRef.current.focus();
   setToast('Please enter email');
   return;
  }
  isPending('sub_btn', true);
  const res = await requestMaker('user/login', params);
  if(res?.status === 'successful'){
   localStorage.setItem('lm-user', res?.data)
   history.push('/')
  }else {
   isPending('sub_btn', 'Login');
  }
 }


 return ( <>
 <PageNav/>
 <div className="mother down-8 xs-down-10vh my-fade-in">
   <div className="my-col-6 off-3 xs-container my-bottom-50 rad-10 down-1 bg-white my-b-shadow xs-down-10vh">
 {/* <Snowfall/> */}
    <div className="my-col-10 off-1 xs-10 xs-off-1 down-3 xs-down-5">
    {/* <div className="my-bottom-10 mother"><i className="fas fa-arrow-left pd-5 c-pointer" onClick={()=> {history.push('/')}}></i></div> */}
    {/* <div className="img-location"></div> */}
     <div className=" mother xs-down-4 down-5 bold px20 xs-px20 centerd">Login</div>
     <div className="cenered mother xs-down-3"><span className="px13 black">Shop Quality products today.✨</span></div>
     <form action="" className="mother down-3 xs-down-5">
      <div className="my-col-12 down-1">
       <div className="mother">
       </div>
       <div className="my-col-12 down-1 xs-down-2 xs-12 bg-white centered"><input ref={emailRef} placeholder="Enter Registered Email Address" type="text" onChange={(e)=> {setParams(prev => ({...prev, email:e.target.value}))}} className="centered input px13 bg-faded mother" /></div>
      </div>
      <div className="my-col-12">
        <button className="my-btn-sm mother centered down-3 xs-down-5 bg-color-code-1 white px13" id="sub_btn" onClick={(e)=> {submitHandler(e)}}>Login</button>
      </div>
     </form>
   <div className="mother centered down-4 xs-down-10"><span className="pd-5 color-code-1 px13 c-pointer" onClick={()=> {history.push('/user_sign_up')}}>I haven't registered <i className="fas fa-angle-right"></i></span></div>
    </div>
   </div>
 </div>
 </> );
}
 
export default UserLogin;