import { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import PageNav from "../components/pagenav";
import { AuthContext } from "../context/authcontex";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useUtils from "../utils/useutils";
import useApi from "../hooks/useApi";

const MySettings = () => {
  const history = useHistory();
  const token = localStorage.getItem('lm-user');
  const {currentUser, logoutOut, getUser} = useContext(AuthContext);
  const [update, setUpdate] = useState(false);
  const {setToast, isPending} = useUtils()
  const {requestMaker} = useApi()
  const [param, setParam] = useState({
    phone:"",
    address:"",
    action:"update_user_cred",
    id:"",
    token
  }) 
const phoneRef = useRef()
const adRef = useRef()


  const UpdateUserHandler = async (e) => {
    e.preventDefault();
   if(param?.phone === ""){
    setToast('Please enter Phone Number')
    phoneRef.current.focus();
    return
  } 
  if(param?.address === ""){
    setToast('Please enter address')
    adRef.current.focus();
    return
   } 
   isPending('update_cres', true)
   const res = await requestMaker('user/settings', param);
   if(res?.status === "successful") {
     await getUser(token);
     setToast("Update Successful");
     isPending('update_cres', "Update")
    }else{
      isPending('update_cres', "Update")
    }
  }
  

  useEffect(() => {
    setParam((prev) => ({...prev, phone:currentUser?.phone, address:currentUser?.address, id:currentUser?.id}))
  }, [currentUser])

  useLayoutEffect(() => {
    if(!currentUser){
      history.push('/')
    }
  }, [currentUser])
  return ( <>
   <PageNav  active={'settigs'}/>
   <div className="mother down-8 xs-down-10">
    <div className="my-col-10 off-1 down-2  xs-down-15 xs-10 xs-off-1">
      <div className="bd-bottom"><span className="black-2 bold monR">User Settings</span></div>
      <div className="mother down-1 xs-down-5 px13 faded monR">
        <span className="anchors bg-faded rad-10 xs-12"  onClick={()=> {setUpdate(true)}}><i className="fas fa-user-plus"></i> <span className="mg-10">Phone Number / Address Update</span></span>
      </div>
       <div className="mother down-10 xs-down-10"><span className="xs-12 xs-centered rad-10 anchors bg-color-code-1 px13 white" onClick={logoutOut} >Logout</span></div>
    </div>
   </div>
  {update && <div className="my-modal monR" onClick={()=> {setUpdate(false)}}>
    <div className="my-col-6 off-3 down-8 xs-container xs-down-15vh bg-white rad-10 my-bottom-50" onClick={(e) => {e.stopPropagation()}}>
      <div className="my-col-10 off-1 down-5 xs-10 xs-off-1 xs-down-10">
        <div className="bd-bottom black-2 px13 bold"><span>User Update</span></div>
        <form onSubmit={(e) => {UpdateUserHandler(e)}} className="mother down-2 xs-down-5">
          <div>
            <div><span className="px13 faded monR">Phone Number</span></div>
             <input type="tel" ref={phoneRef} value={param?.phone} onChange={(e) => {setParam((prev) => ({...prev, phone:e.target.value}))}} className="input px13 faded bg-faded down-1 xs-down-1" />
          </div>
          <div className="mother down-3 xs-down-5">
            <div><span className="px13 faded monR">Delivery Address</span></div>
             <input ref={adRef} type="text"  value={param?.address} onChange={(e) => {setParam((prev) => ({...prev, address:e.target.value}))}} className="input px13 faded bg-faded down-1 xs-down-1" />
          </div>
          <div className="mother down-3 xs-down-10">
            <button className=" my-btn-sm white px13 my-bottom-10 centered bd-unset bg-color-code-1" id="update_cres">Update</button>
          </div>
        </form>
      </div>
    </div>
   </div>
}

   
  </> );
}
 
export default MySettings;