import { useContext, useEffect, useState } from "react";
import Layout from "./layouts";
import Withdrawal from "./withdrawal";
import { AgentContext } from "../../context/agentcontext";
import { AiOutlineLoading } from "react-icons/ai";
import useUtils from "../../utils/useutils";
import moment from "moment";
import Updatebank from "./updaatebank";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Referees from "./referee";

const Dashboard = () => {
  const [withdraw, setWithdraw] = useState(false)
  const {getAgent, isLoading, currentUser, pendingWithdrawal, getPendingWithdrawal, getTrns, transactions} = useContext(AgentContext);
  const token = localStorage.getItem('l-agent');
  const {formatNumber, formatTransactionTime, getBankName, copyTextToClipboard} = useUtils();
  const now = moment();
  const formattedTime = now.format('YYYY-MM-DD HH:mm:ss');
  const history = useHistory()
 

  useEffect(() => {
    if(token) {
      getAgent(token, true); 
    }else {
      history.push('/agents_login')
    }
  }, [token])


  useEffect(() => {
    if(currentUser) {
      getPendingWithdrawal(token, 'referer', currentUser?.email); 
      getTrns(token, currentUser?.email); 
    }
  }, [currentUser])

  const [changeBank, setChangeBank] = useState(false);
  const [referee, setReferal] = useState(false);
 

  return ( <>
  <Layout currentUser={currentUser} />
  {changeBank &&  <Updatebank setChangeBank={setChangeBank}/>}
  {referee && <Referees setReferal={setReferal}/>}
  {withdraw && <Withdrawal setWithdraw={setWithdraw} balance={currentUser?.balance} />}
  {isLoading &&   <div className="my-modal">
    {isLoading === true &&  <div className="centered mother xs-down-20vh down-20 px20 white"><AiOutlineLoading className="fas fa-spin" /></div> }
    {isLoading === 'error' &&      <div className="my-col-4 off-4 xs-off-1 bg-white my-bottom-20 rad-10 xs-10 down-10 xs-down-10">
      <div className="my-container down-5 xs-down-4 centered"> 
        <div><i className="fas fa-circle-exclamation px02"></i></div>
        <div className="mother xs-down-8 down-4 centered px13">Internal Server Error</div>
        <div className="mother down-3 xs-down-4"><span className="my-btn-sm bg-color-code-1 c-pointer white px13">Retry</span></div>
      </div>
    </div> }
  </div>}
  <div className="xs-12 my-col-10 off-1 down-3 xs-down-10vh">
    <div className="my-col-10 off-1 xs-container xs-down-1">
      {currentUser &&  <div className="my-bottom-50 my-col-12 xs-container hidden-xs">
         <span className="pd-10  xs-px12 bg-faded rad-10"><i className="fas fa-user"></i></span><span className="rad-10 px13 mg-10 black-2 monR bold">Welcome, <span className="faded upper-case" > {currentUser?.name}</span> </span>
         <span className="fl-right top-1 monR faded px13" onClick={()=> {setReferal(true)}}><span className="profile-pics px13 black"> <i className="fas fa-users"></i> </span> Referees</span>
         </div>}
   
    <div className="my-col-5 my-bottom-0 xs-12 bg-white b-shadow rad-10 ">
     <div className="my-col-10 off-1 xs-10 xs-off-1 xs-down-6 down-5">
      <span className="px13 faded monR">Available Balance <i className="fas fa-ey mg-10 px12"></i></span>
      <div className="mother down-4 xs-down-4 bold  px20 black-2">{!isLoading? <span>₦ <span className="ralewayR">{formatNumber(currentUser?.balance)}</span></span> : <span className="fas fa-spin"> <AiOutlineLoading/> </span> }</div>
      {/* <div className="mother down-2 xs-down-2 faded"><span className="px13">All </span></div> */}
      <div className="my-col-12 xs-4 left down-7 xs-down-10"><span className="anchors bg-color-code-1 unset-mg  white my-btn-sm c-pointer px10" onClick={()=> {setWithdraw(true)}}>Withdraw  <i className="fas fa-arrow-right"></i> </span></div>
      <div className="my-col-12 xs-6 mg-10 left down-7 xs-down-10 hidden-ls"><span className="anchors unset-mg bg-color-code-1 white my-btn-sm c-pointer px10"  onClick={()=> {copyTextToClipboard(`https://lmobile.com.ng/signUp/${currentUser?.ref_code}`)}}>Copy Referal Link</span></div>

     </div>
      <svg xmlns="http://www.w3.org/2000/svg" className="mother down-3 xs-down-5 rad-10" viewBox="0 0 1440 320"><path fill="#FF9C33" fill-opacity="1" d="M0,32L10.4,37.3C20.9,43,42,53,63,69.3C83.5,85,104,107,125,128C146.1,149,167,171,188,149.3C208.7,128,230,64,250,74.7C271.3,85,292,171,313,213.3C333.9,256,355,256,376,218.7C396.5,181,417,107,438,64C459.1,21,480,11,501,42.7C521.7,75,543,149,563,170.7C584.3,192,605,160,626,122.7C647,85,668,43,689,58.7C709.6,75,730,149,751,197.3C772.2,245,793,267,814,250.7C834.8,235,856,181,877,144C897.4,107,918,85,939,69.3C960,53,981,43,1002,85.3C1022.6,128,1043,224,1064,250.7C1085.2,277,1106,235,1127,192C1147.8,149,1169,107,1190,96C1210.4,85,1231,107,1252,144C1273,181,1294,235,1315,234.7C1335.7,235,1357,181,1377,144C1398.3,107,1419,85,1430,74.7L1440,64L1440,320L1429.6,320C1419.1,320,1398,320,1377,320C1356.5,320,1336,320,1315,320C1293.9,320,1273,320,1252,320C1231.3,320,1210,320,1190,320C1168.7,320,1148,320,1127,320C1106.1,320,1085,320,1064,320C1043.5,320,1023,320,1002,320C980.9,320,960,320,939,320C918.3,320,897,320,877,320C855.7,320,835,320,814,320C793,320,772,320,751,320C730.4,320,710,320,689,320C667.8,320,647,320,626,320C605.2,320,584,320,563,320C542.6,320,522,320,501,320C480,320,459,320,438,320C417.4,320,397,320,376,320C354.8,320,334,320,313,320C292.2,320,271,320,250,320C229.6,320,209,320,188,320C167,320,146,320,125,320C104.3,320,83,320,63,320C41.7,320,21,320,10,320L0,320Z"></path></svg>
    </div>
    <div className="my-col-5 off-1 my-bottom-2 xs-12 bg-white b-shadow bg-color-code-1 rad-10 hidden-xs">
     <div className="my-col-10 off-1 xs-10 xs-off-1 xs-down-6 down-5">
     <span className="px13 faded monR">Account Number <i className="fas fa-ey mg-10 px12"></i></span>
      <div className="mother down-2 xs-down-4 bold  black-2">{!isLoading? <span><span className="ralewayR">{currentUser?.account_number}</span></span> : <span className="fas fa-spin"> <AiOutlineLoading/> </span> }</div>
       <div className="mother top- down-1"><span className="faded px13">{getBankName(currentUser?.bank_code)}</span></div>
      {/* <div className="mother down-2 xs-down-2 faded"><span className="px13">All </span></div> */}
      <div className="my-col-6 down-2 xs-down-10"><span className="anchors unset-mg  bg-color-code-1 white my-btn-sm c-pointer px10" onClick={()=> {setChangeBank(true)}}>Update Bank Details</span></div>
      <div className="my-col-6 down-2 xs-down-10"><span className="anchors unset-mg  bg-color-code-2 color-code-1 my-btn-sm c-pointer px10"  onClick={()=> {copyTextToClipboard(`https://lmobile.com.ng/signUp/${currentUser?.ref_code}`)}}>Copy Referal Link</span></div>
     </div>
      <svg xmlns="http://www.w3.org/2000/svg" className="mother down-3 rad-10" viewBox="0 0 1440 320"><path fill="#FF9C33" fill-opacity="1" d="M0,32L10.4,37.3C20.9,43,42,53,63,69.3C83.5,85,104,107,125,128C146.1,149,167,171,188,149.3C208.7,128,230,64,250,74.7C271.3,85,292,171,313,213.3C333.9,256,355,256,376,218.7C396.5,181,417,107,438,64C459.1,21,480,11,501,42.7C521.7,75,543,149,563,170.7C584.3,192,605,160,626,122.7C647,85,668,43,689,58.7C709.6,75,730,149,751,197.3C772.2,245,793,267,814,250.7C834.8,235,856,181,877,144C897.4,107,918,85,939,69.3C960,53,981,43,1002,85.3C1022.6,128,1043,224,1064,250.7C1085.2,277,1106,235,1127,192C1147.8,149,1169,107,1190,96C1210.4,85,1231,107,1252,144C1273,181,1294,235,1315,234.7C1335.7,235,1357,181,1377,144C1398.3,107,1419,85,1430,74.7L1440,64L1440,320L1429.6,320C1419.1,320,1398,320,1377,320C1356.5,320,1336,320,1315,320C1293.9,320,1273,320,1252,320C1231.3,320,1210,320,1190,320C1168.7,320,1148,320,1127,320C1106.1,320,1085,320,1064,320C1043.5,320,1023,320,1002,320C980.9,320,960,320,939,320C918.3,320,897,320,877,320C855.7,320,835,320,814,320C793,320,772,320,751,320C730.4,320,710,320,689,320C667.8,320,647,320,626,320C605.2,320,584,320,563,320C542.6,320,522,320,501,320C480,320,459,320,438,320C417.4,320,397,320,376,320C354.8,320,334,320,313,320C292.2,320,271,320,250,320C229.6,320,209,320,188,320C167,320,146,320,125,320C104.3,320,83,320,63,320C41.7,320,21,320,10,320L0,320Z"></path></svg>
    </div>
   {pendingWithdrawal.length > 0 && 
    <>
    {pendingWithdrawal?.map((i, index) => (
        <div className="mother xs-down-10 down-4">
        <div className="my-bottom-10 my-b-shadow mother pd-10 bg-faded px13 faded">
          <div className="my-container xs-down-4 down-3">
            <div className="my-col-1 top-2 xs-2">
              <span className="profile-pics bg-white color-code-1"><i className="fas fa-arrow-up px13"></i></span>
          </div>
            <div className="my-col-8 xs-7 top-2 xs-down-1">
              <span className="black bold">Withdrawal In progress</span>
              <div><span className="px10 faded">{formatTransactionTime(i.trn, formattedTime)}</span></div>
              <div><span className="px10 pd-5 bg-color-code-2 color-code-1">Transfer may take up to 24 hrs</span></div>
          </div>
            <div className="my-col-3 right top-1 xs-3 xs-down-1"><span className="color-code-1 bold">- {formatNumber(i.amount)}</span></div>
          </div>
        </div>
      </div>
    ))}
    </>
   }
  <div  className="my-col-12 xs-container down-3 xs-down-10  bd-bottom"><span className="bold px13 monR black-2">My Transactions</span></div>
    {transactions?.length > 0 ?
    <>
     {transactions?.map((i, index) => (
      <div className="mother xs-down-1 down-1">
       <div className="my-bottom-10 my-b-shadow mother bg-faded pd-10 px13 faded">
          <div className="my-container xs-down-4 down-3">
               <div className="my-col-1 top-2 xs-2">
                 <span className="profile-pics bg-white color-code-1">
                  {i.type === 'credit'?  <i className="fas fa-arrow-down px13"></i> :  <i className="fas fa-arrow-up px13"></i>}
                 
                </span>
             </div>
               <div className="my-col-8 xs-7 top-2 down-1 xs-down-1">
                 <span className="black bold">{i.type === 'credit'? 'Account Credited Successfully' : 'Withdrawal Successful' }</span>
                 <div><span className="px10 faded">{formatTransactionTime(i.trn, formattedTime)}</span></div>
             </div>
               <div className="my-col-3 right top-1 xs-3 xs-down-1"><span className={`bold ${i.type === 'credit'? 'color-code-1': 'red'}`}> {i.type==='credit'?"+":"-"}{formatNumber(i.amount)}</span></div>
             </div>
           </div>
         </div>
     ))}
    </>:
 <div className="mother centered xs-down-10 down-5"><span className="faded px13">You have no transactions</span></div>}



    </div>
  </div>

  
  </> );
}
 
export default Dashboard;