import Slider from "react-slick/lib/slider";
import {AiOutlineShoppingCart } from "react-icons/ai";
import { useContext } from "react";
import { CategoryContext } from "../context/categorycontext";
import { CartContext } from "../context/cartcontext";
import useUtils from "../utils/useutils";
import PageNav from "../components/pagenav";
import parse from 'react-html-parser';

const ViewProducts = ({setViews, i}) => {
 const {path} = useContext(CategoryContext);
 const {addToCart} = useContext(CartContext)
 const {formatNumber, formatNigerianPhoneNumber, copyTextToClipboard} = useUtils();
 const settings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 1000,
  autoplay: true,
  autoplaySpeed: 7000,
  pauseOnHover: true, // prevent pausing on hover
  slidesToShow: 1,
  slidesToScroll: 1
 };
 const whatsapp =()=> {
  window.open(`https://api.whatsapp.com/send?phone=${formatNigerianPhoneNumber(i.phone)}&text=Hello%2C%20I%20want%20more%20information%20about%20${i.item_name}`)
 }
 return (
 <>
 {/* <PageNav/> */}
 <div className="view-product">
 <div className="my-container my-col-10 off-1 xs-down-15 down-5 hidde-xs"><span className="pd-5 px20" onClick={()=> {setViews(null)}}><i className="fas fa-arrow-left"></i> </span></div>
 <div className="my-container xs-down-2 hidden-ls">
  <Slider {...settings} >
  {i.image_1 !== '' && <div className="view-pro-img-con">
   <div className="darkscarf"></div>
       <img src={path + i.image_1} alt="" />
  </div>}
  {i.image_2 !== '' && <div className="view-pro-img-con">
   <div className="darkscarf"></div>
       <img src={path + i.image_2} alt="" />
  </div>}
  {i.image_3 !== '' && <div className="view-pro-img-con">
   <div className="darkscarf"></div>
       <img src={path + i.image_3} alt="" />
  </div>}
  </Slider>
 </div>

 <div className="my-col-10 off-1 down-1 hidden-xs">
 <div className="my-col-4">
 <Slider {...settings} >
  {i.image_1 !== '' && <div className="view-pro-img-con">
   <div className="dark-scarf"></div>
       <img src={path + i.image_1} alt="" />
  </div>}
  {i.image_2 !== '' && <div className="view-pro-img-con">
   <div className="dark-scarf"></div>
       <img src={path + i.image_2} alt="" />
  </div>}
  {i.image_3 !== '' && <div className="view-pro-img-con">
   <div className="dark-scarf"></div>
       <img src={path + i.image_3} alt="" />
  </div>}
  </Slider>
 </div>
 <div className="my-col-8 xs-down-10 top-1 my-bottom-50 monR">
  <div className="my-container off-1">
   <div className="mother top-1 upper-case px10 faded"><span className="">Products
   <i className="fas fa-angle-right mg-5 px10"></i>
    <span className="mg-5">{i.category}</span>
    <i className="fas fa-angle-right mg-5 px10"></i>
    <span className="mg-5">{i.sub_cat}</span>
    </span>
    </div>
   <div className="mother down-1"><span className="bold px20">{i.item_name} --- (₦{formatNumber(i.price)})</span></div>
   <div className="mother down-4">
    <div className="mother xs-down-3 bd-botto">
    <div className="my-col-6"><span className="color-code-1 px13 bold upper-case">About Item</span></div>
    </div>
    <div className="mother down-2 my-bottom-50"> <span className=" px13">{parse(i.description)}</span></div>
    </div>
    <div className="my-bottom-5 top-4 mother">
  <div className="mother">
   <span className="upper-case px13 faded"><b>Seller</b></span>
   <div><span className="black-2 px13 bold upper-case">{i.owner}</span></div>
  </div>
  <div className="mother down-2">
   <div><span className="upper-case px13 faded"><b>Enquires</b> </span></div>
    <div className="mother down-1">
    <a href={`tel: ${i.phone}`}><i className="pd-10 color-code-1 bg-color-code-2 px10 fas my-b-shadow rad-10 c-pointer fa-phone"></i></a>
   <i className="pd-10 color-code-1 bg-color-code-2 px10 my-b-shadow fab rad-10 mg-10 c-pointer fa-whatsapp" onClick={whatsapp}></i>
    <span title="Share link" onClick={()=> {copyTextToClipboard(`https://pvma.com.ng/item/${i?.id}`)}}><i className="pd-10 color-code-1 bg-color-code-2 px10 mg-10 fas my-b-shadow rad-10 c-pointer fa-share"></i></span>
    </div>
    <div className="mother down-3"><span className="my-btn-sm bg-color-code-1 white px13 c-pointer rad-10" onClick={(e)=> {addToCart(e, i)}}>Add To Cart</span></div>
  </div>
 </div>
 </div>
 </div>
 </div>
 <div className="my-container xs-down-10 my-bottom-50 hidden-ls">
  <div className="my-container">
   <div><span className="px13 faded">{i.main_cat} 
   <i className="fas fa-angle-right mg-5 px10"></i>
    <span className="mg-5">{i.category}</span>
    <i className="fas fa-angle-right mg-5 px10"></i>
    <span className="mg-5">{i.sub_cat}</span>
    </span>
    </div>
   <div className="mother xs-down-3"><span className="bold px20">{i.item_name} --- (₦{formatNumber(i.price)})</span> <span title="Share link" onClick={()=> {copyTextToClipboard(`https://pvma.com.ng/item/${i?.id}`)}}><i className="pd-10 color-code-1  mg-10 bg-color-code-2 px10 fas my-b-shadow rad-10 c-pointer fa-share"></i></span></div>
   <div className="mother xs-down-4">
    <div className="mother xs-down-3 bd-botto">
    <div className="xs-5"><span className="pd-5 bg-color-code-2 color-code-1 xs-px13">About Item</span></div>
    </div>
    <div className="mother xs-down-5 my-bottom-10"> <span className="faded-2 px13">{parse(i.description)}</span></div>
    </div>
 </div>
 <div className="my-bottom-50 mother"></div>
 </div>

 <div className="price-container bg-color-code-1 xs-top-15 hidden-ls">
  <div className="my-container">
   <div className="xs-4 xs-down-2">
    <a href={`tel: ${i.phone}`}>
    <span  className="px20 pd-10 bg-color-code-2 color-code-1 rad-10"><i className="fas fa-phone"></i></span>
    </a>
    <span className="px20 pd-10 bg-color-code-2 xs-off-1 color-code-1 rad-10" onClick={whatsapp}><i className="fab fa-whatsapp"></i></span>
  </div>
   <div className="xs-8"><span className="add-to-cart-btn bg-color-code-1 white" onClick={(e)=> {addToCart(e, i)}}>Add to Cart <AiOutlineShoppingCart/> </span></div>
  </div>
 </div>
 </div></>);
}
 
export default ViewProducts;