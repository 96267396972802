import moment from "moment";
import useUtils from "../../utils/useutils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useContext } from "react";
import { AgentContext } from "../../context/agentcontext";

const Referees = ({setReferal}) => {
  const {formatNumber, formatTransactionTime, getBankName, copyTextToClipboard} = useUtils();
  const now = moment();
  const {referee, currentUser} = useContext(AgentContext)
  const formattedTime = now.format('YYYY-MM-DD HH:mm:ss');
  const history = useHistory()
  return ( <>
  <div className="modal-v ov-scroll my-fade-in">
    <div className="my-col-10 off-2 xs-container xs-down-10 down-5">
      <div className="mother bd-bottom">
        <i className="fas fa-angle-left pd-10" onClick={() => {setReferal(false)}}></i>
        <span className="bold monR">Your Referees</span>
      </div>  
       {referee?.length < 1 ? 
      <><div className="mother down-5 xs-down-10 centered">
        <div><span><span className="px13 faded monR">You haven't refered any one yet</span></span></div>
        <div><span className="my-btn-sm bg-color-code-1 xs-down-5 px13 white rad-10" onClick={()=> {copyTextToClipboard(`https://lmobile.com.ng/signUp/${currentUser?.ref_code}`)}}>Share referal link</span></div>
        </div></>
      : <>
        <div className="px13 mother xs-down-3 down-2 my-bottom-10 faded pd-10">You have refered {referee?.length} customer(s)</div>
       {referee?.map((i, index) => (
      <div className="my-col-4" key={'ref' + index}>
         <div className="my-col-11 xs-12 xs-12 my-col-4 bd-om rad-10 bg-faded pd-10 xs-down-2">
         <div className="my-col-2  cetered xs-2 xs-down-1 down-"><span className="profile-pics bg-white down-5"><i className="fas fa-user px13 color-code-1"></i></span></div>
        <div className="my-col-10 xs-10">
          <span className="px13 bold upper-case">{i?.name}</span>
          <div className="px10 faded mother xs-down-1">Joined {formatTransactionTime(i.trn, formattedTime)}</div>
      </div>
         </div>
      </div>
       ))}
      </>}
    </div>
  </div>
  
  </>  );
}
 
export default Referees;