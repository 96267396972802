import { useEffect, useRef, useState } from "react";
// import Navbar from "../components/navbar";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useApi from "../hooks/useApi";
import useUtils from "../utils/useutils";
import EmailVar from "../components/emailvar";
import PageNav from "../components/pagenav";
import ResetPassword from "../components/resetpassword";

const SellerSignUp = () => {
 const history = useHistory();
 const {requestMaker} = useApi();
 const {setToast, isPending, makeid} = useUtils();
 const [params, setParams] = useState({name:"", email:"", ref_code:"", location:"",  action:'signup', phone:"", password:"", retype:""});
 const [emailVar, setEmailVar] = useState(false);
 const nameRef = useRef()
 const emailRef = useRef()
 const phoneRef = useRef()
 const locationRef = useRef()
 const passwordRef = useRef();


 const states = [
  "Abia",
  "Adamawa",
  "Akwa Ibom",
  "Anambra",
  "Bauchi",
  "Bayelsa",
  "Benue",
  "Borno",
  "Cross River",
  "Delta",
  "Ebonyi",
  "Edo",
  "Ekiti",
  "Enugu",
  "Gombe",
  "Imo",
  "Jigawa",
  "Kaduna",
  "Kano",
  "Katsina",
  "Kebbi",
  "Kogi",
  "Ilorin",
  "Lagos",
  "Nasarawa",
  "Niger",
  "Ogun",
  "Ondo",
  "Osun",
  "Oyo",
  "Plateau",
  "Rivers",
  "Sokoto",
  "Taraba",
  "Yobe",
  "Zamfara",
  "Federal Capital Territory (FCT)"
];


 useEffect(()=> {
  document.title = 'L-Mobile ~ Refer & Earn'
  return(()=> {document.body.style.backgroundColor='white'})
 })


 const submitHandler = async(e) => {
  e.preventDefault()
  if(params?.email.trim() === '' ){
   emailRef.current.focus();
   setToast('Please enter email');
   return;
  }
  if(params?.name.trim() === '' ){
   nameRef.current.focus();
   setToast('Please enter company name');
   return;
  }
  if(params?.location.trim() === '' ){
   locationRef.current.focus();
   setToast('Please choose location');
   return;
  }
  if(!params?.email.includes('@')){
   emailRef.current.focus();
   setToast('Please enter a valid email');
   return;
  }
  if(params?.phone.trim() === '' ){
   phoneRef.current.focus();
   setToast('Please enter phonenumber');
   return;
  }
  if(params?.password.trim() === '' ){
   passwordRef.current.focus();
   setToast('Please enter password');
   return;
  }
  if(params?.password.trim() !== params?.retype.trim()){
   passwordRef.current.focus();  
   setToast('Password does not match !');
   return;
  }
  isPending('sub_btn', true);
  const res = await requestMaker('agents/signup', params);
  if(res?.status === 'successful'){
   setEmailVar(true);
  }else {
   isPending('sub_btn', 'Create Account');
  }
 }
 const [re, setRe] = useState(false)

 return ( <>
  {re &&  <ResetPassword action={'referer'} btn={setRe} />}
 <PageNav/>
 {emailVar? <EmailVar params={params} api={'agents/signup'} tokenTitle={'l-agent'} path={'/agents_login'} /> : <>
 <div className="mother down-8 xs-down-20 my-fade-in">
   <div className="my-col-6 off-3  rad-10  bg-faded xs-container  my-bottom-20 xs-down-3">
    <div className="my-col-10 off-1 xs-10  xs-off-1 down-3 xs-down-5">
      <div className="img-location"></div>
     <div className="mother centered xs-down-5 bold down-1 px20">Refer & Earn</div>
     <div className="mother centered xs-down-2"><span className="px13 black-2 monR">Refer customers and get 3% of every of their purchase</span></div>
     <form action="" className="mother down-1 xs-down-5">
      <div className="mother down-2 xs-down-3">
       <div><input ref={emailRef} type="text" placeholder="Email address" onChange={(e)=> {setParams(prev => ({...prev, email:e.target.value}))}} className="input px13 bg-white" /></div>
      </div>
      <div className="my-col-6 xs-6 xs-down-2 down-1">
       <div className="my-col-11 xs-11"><input ref={nameRef} placeholder="Full Name" onChange={(e)=> {setParams(prev => ({...prev, name:e.target.value}))}} type="text" className="input px13 bg-white" /></div>
      </div>
      <div className="my-col-6 xs-6 down-1 xs-down-3">
       <div><input ref={phoneRef} type="text" placeholder="Phone Number" onChange={(e)=> {setParams(prev => ({...prev, phone:e.target.value}))}} className="input px13 bg-white" /></div>
      </div>
      <div className="my-col-6 down-1 xs-6 xs-down-3">
       <select ref={locationRef} onChange={(e)=> {setParams(prev => ({...prev, location:e.target.value}))}} className="input my-col-11 xs-11 px13 bg-white">
        <option value="">Select Location</option>
        {states.map((state, index) => (
         <option key={index} value={state}>
          {state}
         </option>
      ))}
    </select>
      </div>
      <div className="my-col-6 xs-6 down-1 xs-down-3">
       <div className="xs-12"><input ref={passwordRef} type="text" placeholder="Password" onChange={(e)=> {setParams(prev => ({...prev, password:e.target.value}))}} className="input px13 bg-white" /></div>
      </div>
      <div className="mother down-1 xs-down-3">
       <div><input type="text" placeholder="Confirm Password"  onChange={(e)=> {setParams(prev => ({...prev, retype:e.target.value}))}} className="input px13 bg-white" /></div>
      </div>
     </form>
    </div>
    <div className="my-col-10 off-1 xs-10 xs-off-1 xs-down-1">
      <button className="my-btn-sm xs-12 down-1 xs-down-5 bg-color-code-1 mother white px13" id="sub_btn" onClick={(e)=> {submitHandler(e)}}>Create Account</button>
    </div>
    {/* <div className="my-col-10 off-1 xs-10 xs-off-1 down-2 xs-down-6 px12 centered monR"><span>Signing up means you have read and agreed with<span className="color-code-1 pd-5 bold  c-pointer" onClick={()=> {history.push('/general/terms')}}>Lmobile terms and conditions.</span></span></div> */}
      <div className="mother down-3 xs-down-2 xs-down-5 centered"><span className="color-code-1 pd-5 c-pointer px13" onClick={()=> {history.push('/agents_login')}}>I already registered <i className="fas fa-angle-right"></i></span></div>
   </div>
 </div>
 </> }
 </> );
}
 
export default SellerSignUp;