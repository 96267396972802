import { useContext, useState, useEffect } from "react";
import useUtils from "../utils/useutils";
import { CategoryContext } from "../context/categorycontext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const GroceryCat = () => {
 const {closeModal} = useUtils()
 const {allCat} = useContext(CategoryContext);
 const history = useHistory();
 const [category, setCategory] = useState([])

 useEffect(() => {
  const elem = allCat?.filter((i) => i.main_Cat == 'Groceries');
   if(elem){
    setCategory(elem)
   }
}, [allCat])



 return ( <>
 <div id="grocery" className="">
 {/* <span className="side-bar-link-pointer"></span> */}
 <div className="bg-white my-mother my-bottom-50">
   <div className="my-container down-3">
     <div className="mother">
      <div className="my-col-10 off-1 px20 bold bd-bottom">Top Category</div>
       {category?.map((i, index) => (
         <div className="my-col-12 pd-5 down-1" key={'catss' + index}>
         <div className="my-col-10 off-1">
         {/* <div className="mother bd-bottom-bold"><span className="bold upper-case black ">{i.cat_name}</span></div> */}
         {JSON.parse(i.sub_cat).map((x, index) => (
          <div className="mother down-1 exended-links upper-case" key={'nn' + index} onClick={()=> {history.push(`/products/${i.cat_name}/${x.name}`)}}><span className="">{x.name}</span></div>
         ))}
        </div>
        </div>
       ))}
      </div>
     </div>
    </div>
 </div>
 </> );
}
 
export default GroceryCat;