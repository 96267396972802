import { useEffect, useRef, useState } from "react";
import Navbar from "../components/navbar";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useApi from "../hooks/useApi";
import useUtils from "../utils/useutils";
import Snowfall from "react-snowfall";

const EmailVar = ({params, api, tokenTitle, path}) => {
 const history = useHistory();
 const {requestMaker} = useApi();
 const {setToast, isPending} = useUtils();
 const [vars, setVars] = useState({pin:"", email:params?.email, action:'very_email'});
 const [emailVar, setEmailVar] = useState(false);
 const nameRef = useRef();


 useEffect(()=> {
  document.title = 'L-Mobile ~ Sign Up'
  // document.body.style.background = '#f3f3f3';
  return(()=> {document.body.style.backgroundColor='white'})
 })

 const submitHandler = async(e) => {
  e.preventDefault()
  if(vars?.pin === '' ){
   nameRef.current.focus();
   setToast('Please enter verification pin');
   return;
  }
  isPending('veriy_btn', true);
  const res = await requestMaker(api, vars);
  if(res?.status === 'successful'){
   setEmailVar(false);
   localStorage.setItem(tokenTitle, res?.data);
   history.push(path);
   setToast('Account Creation Was successful. Please Login');
  }else {
   isPending('veriy_btn', "Verify");
  }
 }

 return ( <>
 <Navbar/>
 <div className="mother down-10 xs-down-20vh my-fade-in">
   <div className="my-col-4 off-4 bg-faded my-container rad-10  my-bottom-50 down-1 xs-down-3">
    <Snowfall/>
    <div className="my-col-10 off-1 xs-10 xs-off-1 down-3 xs-down-5">
     <div className="bd-bottom-bold mother bold">Verify Email</div>
     <div className="mother down-5 px13 xs-down-5"><span>Please enter verification pin sent to <b className="color-code-1">{params?.email}</b></span></div>
     <form action="" className="mother down-3 xs-down-5">
      <div className="mother">
       {/* <div><span className="px13">Verification Pin</span></div> */}
       <div><input ref={nameRef}  value={vars.pin} placeholder="Enter Pin" maxLength={6} onChange={(e)=> {setVars(prev => ({...prev, pin:e.target.value}))}} type="number" className="input bg-white px13" /></div>
      </div>
      <button className="my-btn-sm mother down-5 xs-down-5 bg-color-code-1 white px13" id="veriy_btn" onClick={(e)=> {submitHandler(e)}}>Verify</button>
     </form>
    </div>
   </div>
 </div>
 </> );
}
 
export default EmailVar;