import { useEffect, useRef, useState } from "react";
import Navbar from "../components/navbar";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import useApi from "../hooks/useApi";
import useUtils from "../utils/useutils";

const AgentReset = () => {
 const history = useHistory();
 const {requestMaker} = useApi();
 const {setToast, isPending} = useUtils();
 const nameRef = useRef();
 const {token} = useParams();
 const [ResetPassword, setResetPassword] =  useState({action:"referer", password:"", confirm:"", token:""});
 
 useEffect(() => {
  if(token) {
    setResetPassword((prev) => ({...prev, token:token}))
  }
 }, [token])

 const submitHandler = async(e) => {
  e.preventDefault();
  if(ResetPassword?.password === ''){
    setToast('Enter password');
    nameRef.current.focus();
    return;
  }
  if(ResetPassword?.password !== ResetPassword?.confirm){
    setToast('Password does not match !');
    nameRef.current.focus();
    return;
  }
  isPending('veriy_btn', true)
  const res = await requestMaker('forgotpass/resetter.php', ResetPassword);
  if(res?.status === 'successful'){
    isPending('veriy_btn', "Submit");
    history.push('/agents_login')
    setToast('Password reset successful');
  }else {
    isPending('veriy_btn', 'Submit');
  }
 if(res?.status === 'SESSION EXPIRED') {
  history.push('/')
  setToast('Expired Reset Link');
  return;
 }
 }

 return ( <>
 <Navbar/>
  <div className="mother">
   <div className="my-col-4 off-4 down-10 bg-faded my-container rad-10  my-bottom-50 down-1 xs-down-20vh">
    <div className="my-col-10 off-1 xs-10 xs-off-1 down-3 xs-down-5">
     <div className="bd-bottom-bold mother bold monR"> Password Reset</div>
     <form action="" className="mother down-8 xs-down-5">
      <div className="mother"><div><input ref={nameRef} placeholder="Enter New Password" onChange={(e)=> {setResetPassword(prev => ({...prev, password:e.target.value}))}} type="text" className="input bg-white px13" /></div></div>
      <div className="mother down-2 xs-down-5"><div><input ref={nameRef} placeholder="Confirm New Password" onChange={(e)=> {setResetPassword(prev => ({...prev, confirm:e.target.value}))}} type="text" className="input bg-white px13" /></div></div>
      <button className="my-btn-sm mother down-5 xs-down-5 bg-color-code-1 white px13 monR" id="veriy_btn" onClick={(e)=> {submitHandler(e)}}>Submit</button>
     </form>
    </div>
   </div>
 </div>
 </> );
}
 
export default AgentReset;