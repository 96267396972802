import { useContext, useEffect, useRef, useState } from "react";
import { CategoryContext } from "../context/categorycontext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const SubDb = ({options, i, title}) => {
 const caretRef = useRef();
 const optionsRef = useRef();
 const history = useHistory();
 const [Options, setOptions] = useState([])
 var x = 0;
 
 
 useEffect(() => {
 if(options){
  const elem = JSON.parse(options);
  if(elem){
   setOptions(elem);
  }
 } 
 }, [options])



 const dpHandler = () => {
  if(x === 0){
   caretRef.current.classList.add('rotate')
   optionsRef.current.style.display = 'block';
   // setOptions(true)
   x = 1
  }else {
   // setOptions(false)
   caretRef.current.classList.remove('rotate')
   optionsRef.current.style.display = 'none';
   x = 0
  }
 }


 return ( <>
  <div className="mother xs-px10 bd-bottom" onClick={dpHandler}>
   <span className='black'>{title}</span>
   <span className='faded xs-down-3 fl-right values_from_array' ref={caretRef}><i className='fas fa-angle-right'></i></span>
  </div>
 <div className="mother values_from_array fade-in my-d-none" ref={optionsRef}>
  <div className="mother sub_car_dp top-8 pd-5">
   {Options?.map((x,index) => (
    <div className="xs-11 my-col-12 down-3 xs-down-2 pd-5"  onClick={()=> {history.push(`/products/${i.cat_name}/${x.name}`)}} key={'namess' + index}> 
    <span className='px10 pd-5 faded-2 upper-case'>{x.name}</span>
   </div>
   ))}
  </div>
  </div>
 </> );
}
 
export default SubDb;