import { useContext, useEffect, useState } from "react";
import { AiOutlineBell, AiOutlineCar, AiOutlineCheck, AiOutlineHistory, AiOutlineLogout, AiOutlineShopping } from "react-icons/ai";
import { DeliverContext } from "../../context/delivercontext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Menu = ({active}) => {
 const {currentUser, getPersonnel, logoutOut} = useContext(DeliverContext);
 const [logout, setLogout] = useState(false);
 const history = useHistory();
 const token = localStorage.getItem('l-dPers');
 
 useEffect(() => {
  if(!token){
   history.push('/riders_login');
   return;
  }
  getPersonnel(token);
 }, [])
 
return ( <>

{logout &&  <div className="my-modal my-fade-in">
  <div className="my-col-4 off-4 rad-10 xs-10 xs-off-1 xs-down-20vh down-10 bg-white my-bottom-50">
   <div className="my-col-10 off-1 down-3 xs-10 xs-off-1 xs-down-10">
    <div className="mother bd-bottom"><span className="px13 bold">Logout</span></div>
    <div className="mother down-5">
     <span>Are you sure you want to logout ?</span>
    </div>
    <div className="down-5 mother righ xs-down-10">
     <span className="pd-10 rad-10 bg-color-code-2 color-code-1 px13" onClick={logoutOut}>Yes</span>
     <span className="pd-10 rad-10 bg-color-code-1 white mg-10 px13" onClick={()=> {setLogout(false)}}>Cancle</span>
    </div>
   </div>
  </div>
 </div> }
<div className="seller-nav-bar hidden-ls">
  <div className="my-container my-col-8 off-2">
   <div className="my-col-6 xs-12 off-1">
    <div className="my-col-1 xs-1"><span className="profile-pics"><i className="fas fa-user px13"></i></span></div>
    <div className="my-col-5 xs-10 xs-down- down-1"><span className="mg-10 px13 bold">{currentUser?.firstname} {currentUser?.lastname}</span></div>
   </div>
  </div>
 </div>
   <div className="i-seller-side-bars down- bg-black my-col-2 hidden-xs">
    <div className="my-col-10 off-1 down-20">
      <div><span className="px30 alice">L-Mobile <span className="px13 bold color-code-1">Delivery</span></span></div>
       <div className="mother down-10 bg-color-code-2 pd-10 px13 color-code-1"> <i className="fas fa-user pd-5"></i> {currentUser?.firstname} {currentUser?.lastname}</div>
      <div className="mother">
       <div className="mother l-seller-links down-30 hover pd-5 " onClick={()=> {history.push('/orderdelivery/Orders')}}>
        <span className="color-code-1 px20"><AiOutlineShopping/></span>
        <span className="mg-10 alice alice monR">Orders</span>
       </div>
      </div>
      <div className="mother down-5">
       <div className="mother l-seller-links hover pd-5"  onClick={()=> {history.push('/orderdelivery/Tasks')}}>
        <span className="color-code-1 px20"><AiOutlineCar/></span>
        <span className="mg-10 alice alice monR">My Tasks</span>
       </div>
      </div>
      <div className="mother down-5"  onClick={()=> {history.push('/orderdelivery/History')}}>
       <div className="mother l-seller-links hover pd-5">
        <span className="color-code-1 px20"><AiOutlineHistory/></span>
        <span className="mg-10 alice alice monR">Work History</span>
       </div>
      </div>

    <div className="mother l-seller-links hover pd-5 down-20" onClick={()=> {setLogout(true)}}>
    <span><span className="color-code-1 px20"><AiOutlineLogout/></span></span>
    <span className="mg-10 alice alice monR">Logout</span>
     </div>
    </div>
   </div>


 <div className="bottom-nav hidden-ls bg-black alice">
  <div className="xs-10 xs-off-1 centered">
   <span className={`xs-3 px20 ${active === 'order' && 'color-code-1'}`} onClick={()=> {history.push('/orderdelivery/Orders')}}><AiOutlineShopping/></span>
   <span className={`xs-3 px20 ${active === 'tasks' && 'color-code-1'}`} onClick={()=> {history.push('/orderdelivery/Tasks')}}><AiOutlineCar/></span>
   <span className={`xs-3 px20 ${active === 'history' && 'color-code-1'}`} onClick={()=> {history.push('/orderdelivery/History')}}><AiOutlineHistory/></span>
   <span className={`xs-3 px20`} onClick={()=> {setLogout(true)}}><AiOutlineLogout/></span>
  </div>
 </div>
  </>  );
}
 
export default Menu;