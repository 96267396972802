import { useContext, useEffect, useState } from "react";
import SellerSideBar from "./sellersidebar";
import useUtils from "../../utils/useutils";
import AddProduct from "./addproduct";
import { SellerContext } from "../../context/sellercontext";
import { AiOutlineCopy, AiOutlineExclamationCircle } from "react-icons/ai";
import CatSkeleton from "../skeleton/catSeleton";
import EditProduct from "./editProduct";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AddBank from "./addbank";
import SellerWithdrawal from "./withdrawal";
import moment from "moment";
import Notifications from "./notifications";
import Settings from "./settings";

const SellerDash = () => {
 const {formatNumber, formatTransactionTime} = useUtils();
 const [addProduct, setAddProduct] = useState(false);
 const {currentUser, products, pendingWithdrawal, path} = useContext(SellerContext);
 const [allProduct, setAllProducts] = useState([])
 const [editproduct, setEditProduct] = useState(null)
 const history = useHistory();
 const now = moment();
 const formattedTime = now.format('YYYY-MM-DD HH:mm:ss');
 const [Delete, setDelete] = useState(null); 


 useEffect(()=> {
  document.title = 'L-Mobile ~ seller'
  document.body.style.background = '#f3f3f3';
  return(()=> {document.body.style.backgroundColor='white'})
 })

 useEffect(() => {
   const reducedData = products?.reduce((acc, current) => {
  const x = acc.find(item => item.item_name === current.item_name);
  if (!x) {
    return acc.concat([current]);
  }
  return acc;
 }, []);
 setAllProducts(reducedData)
 }, [products])

 const closeEdit =(e)=> {
  e.stopPropagation();
  setEditProduct(null);
 } 

 const [withdraw, setWithdraw] = useState(false);
 const [notification, setNotifications] = useState(false);
 const [settings, setSettings] = useState(false);


 return ( <>
  <SellerSideBar active={'home'} setNotifications={setNotifications} setSettings={setSettings} />
  {addProduct && <AddProduct setAddProduct={setAddProduct} />}
  {withdraw && <SellerWithdrawal setWithdraw={setWithdraw} balance={currentUser?.amount} />}
  {notification && <Notifications setNotification={setNotifications} />}
  {settings && <Settings setSettings={setSettings} />}



  <div className="my-col-10 off-2 xs-12 down-2 xs-down-10vh">
   <div className="my-col-10 off-1 xs-container my-bottom-50 down-1">
    <div className="my-col-5 my-bottom-50 xs-12 b-shadow bg-color-code-1 rad-10 dot">
     <div className="my-col-10 off-1 xs-10 xs-off-1 xs-down-6 down-5">
      <span className="bold">Total Income <i className="fas fa-ey mg-10 px12"></i></span>
      <div className="mother down-4 bold xs-down-2  px20 color-code-1">{currentUser === null ? '****' : <span>₦ {formatNumber(currentUser?.amount)}</span>}</div>
      <div className="mother down-2 xs-down-2 xs-px13 faded"><span className="px13">withdrawal may take up to 24hrs to get credited to bank</span></div>
      <div className="mother down-3 xs-down-5"><span className="seller-cta bold bg-color-code-2 my-btn-sm c-pointer px13 monR" onClick={()=> {setWithdraw(true)}}>Withdraw</span></div>
     </div>
    </div>
    <div className="my-col-5 off-1 xs-5 hidden-xs xs-off-1 my-bottom-50 bg-color-code-1 rad-10 b-shadow dot">
     <div className="my-col-10 off-1 down-5">
      <span className="bold">Product In Sales</span>
      <div className="mother down-4 color-code-1 bold">{currentUser === null ? '****' : <span>{allProduct?.length} Item(s)</span>}</div>
      <div className="mother down-2 xs-down-2"><span className="px13 faded monR">You can add up to 100 products</span></div>
      <div className="mother down-5"><span className="seller-cta bold bg-color-code-2 color-code-1 my-btn-sm monR c-pointer px13" onClick={()=> {setAddProduct(true)}}>Add Product</span></div>
     </div>
    </div>
    {pendingWithdrawal?.map((i, index) => (
    <div className="mother down-5 xs-down-10" key={index}>
      <div className="px13 mother bd-bottom-bold black-2 bold monR">Pending Withdrawal</div>
      <div className="my-col-6 xs-12 down-3">
        <div className="my-col-11 xs-12 my-bottom-20 bg-white b-shadow xs-down-5 rad-10 monR">
          <div className="my-col-10 xs-10 xs-off-1 xs-down-5 off-1 down-5">
            <div className="my-col-1 xs-2"><span className="profile-pics"><i className="fas fa-arrow-up px13 color-code-1"></i></span></div>
            <div className="my-col-10 off-1 xs-10 top-2">
              <span className="px13 black-2 bold">Withdrawal in progress</span>
            <div className="my-col-12"><span className="px12 black-2 faded">{formatTransactionTime(i.trn, formattedTime)}</span></div>
            <div className="my-col-12"><span className="px10 black-2 faded rad-10">May take upto 24hrs to reflect in bank</span></div>
            <div className="my-col-12 right xs-12 xs-down-3"><span className="px12 color-code-1 bold px13 rad-10"> - NGN {formatNumber(i.amount)}</span></div>
          </div>
          </div>
        </div>
      </div>
    </div>
    ))}
   <div className="mother down-5 xs-down-10 my-bottom-50">
    <div className="bd-bottom-bold faded-2 mother bold">
     <div className="my-col-2 xs-12 bd-botom-bold">
      <span className="px13 bold monR black-2">
       Your Products 
       {/* <i className="mg-10 fas pd-5 fl-right faded fa-search hidden-ls"></i> */}
       {/* <i className="mg-10 fas pd-5 fl-right faded fa-sort hidden-ls"></i> */}
      </span>
     </div>
    </div>
    <div className="mother down-1 xs-down-5">
     <div className="mother hidden-ls"> <span className="fl-right pd-5 px13 bg-color-code-1 white" onClick={()=> {setAddProduct(true)}}>Add Product</span></div>
     <div className="mother down-1 xs-down-5">
      {allProduct?.map((i, index) => (
       <div className="my-col-3 xs-6 xs-down-3"
        onClick={()=> {{i.review_status !== '0'? setEditProduct(`open-edit${index}`) : setEditProduct(null)}}}
        // onClick={()=> {setEditProduct(`open-edit${index}`)}} 
        >
      {editproduct === `open-edit${index}` && <EditProduct item={i} setEditProduct={closeEdit}/>}
      <div className="my-col-11 off- bg-white my-bottom-50 down-5 top-selling-img-con my-b-shadow">
       <div className="top-selling-img-con">
        <div className="my-col-10 off-1 xs-10 xs-off-1 xs-down-5 down-5"><span className="yp-pic"><img src={path + i.image_1} alt="" /></span></div>
        <div className="my-col-10 off-1 xs-10 xs-off-1 "><span className="px13 bold">{i.item_name?.slice(0, 10) + "..."}</span></div>
        <div className="my-col-10 off-1 xs-10 xs-off-1 bold faded"><span className="px13">N{formatNumber(i.price)}</span></div>
        <div className="my-col-10 off-1 xs-10 xs-off-1 down-3 px10 xs-down-4 bold">{i.review_status === '1' && <span>{i.total_sales} pieces sold</span>}</div>
        <div className="my-col-10 off-1 xs-10 xs-off-1 down-3 px10 xs-down-4 ">{i.review_status === '0' && <span className="pd-5 color-code-1 bg-color-code-2">Under review</span>}</div>
        <div className="my-col-10 off-1 xs-10 xs-off-1 down-3 px10 xs-down-4">{i.review_status === '2' && <span className="pd-5 color-code-1 bg-color-code-2" > <i className="fas fa-triangle-exclamation"></i> Rejected</span>}</div>
       </div>
      </div>
     </div>
      ))}
     </div>
    </div>
   </div>
   </div>

  </div>

 
 </> );
}
 
export default SellerDash;