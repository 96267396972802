import {createContext, useEffect, useState} from 'react';
import useApi from '../hooks/useApi';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import useUtils from '../utils/useutils';

export const SellerContext = createContext();

const SellerContextProvider = (props) => {
 const token = localStorage.getItem('l-seller');
 const path = 'https://lmobileapi.000webhostapp.com/images/'
 const {requestMaker} = useApi();
 const history  = useHistory();
 const {isSending} = useUtils();
 const [pendingWithdrawal, setPendingWithdrawal] = useState([]);
 const [currentUser, setCurrentUsers] = useState(null);
 const [products, setProducts] = useState(null)
 const [transactions, setTransactions] = useState([]);
 
 const logoutOut = () => {
  localStorage.removeItem('l-seller');
  setCurrentUsers(null); 
  history.push('/seller_login')
 }
 
 const setSeller = async(Token) => {
  const params = {token:Token, action:'get_seller'}
  isSending(true)
  const result = await requestMaker('sellers/seller', params);
  if(result?.status === 'successful'){
   setCurrentUsers(result?.data);
   isSending(false)
   return;
  }if(result?.status === 'SESSION EXPIRED'){
    logoutOut()
    isSending(false)
    return;
   }
 }

 const getProduct = async(Token) => {
  const params = {token:Token, action:'get_products'}
  const result = await requestMaker('sellers/product', params);
  if(result?.status === 'successful'){
   setProducts(result?.data);
   return;
  }if(result?.status === 'SESSION EXPIRED'){
    logoutOut()
    return;
   }
 } 

 const getPendingWithdrawal = async(Token, type, email) => {
  const params = {token:Token, action:'get_pending_wit', type, email}
  const result = await requestMaker('sellers/withdrawalreq', params);
  if(result?.status === 'successful'){
    setPendingWithdrawal(result?.data);
    return;
  }if(result?.status === 'SESSION EXPIRED'){
    logoutOut()
    return;
   }
 }


 const getTrns = async(Token, email) => {
  const params = {token:Token, action:'get_trn_for_seller', email}
  const result = await requestMaker('sellers/transactions', params);
  if(result?.status === 'successful'){
    setTransactions(result?.data);
    return;
  }if(result?.status === 'SESSION EXPIRED'){
    logoutOut()
    return;
   }
 }

 
 return ( 
  <SellerContext.Provider value={{
   setSeller,
   getPendingWithdrawal,
   currentUser,
   logoutOut,
   products,
   pendingWithdrawal,
   getProduct,
   token,
   path,
   getTrns,
   transactions
  }}>
   {props.children}
  </SellerContext.Provider>
 );
}
 
export default SellerContextProvider;
