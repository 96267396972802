import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./pages/home";
import CategoryContextProvider from "./context/categorycontext";
import AllProducts from "./pages/allproducts";
import Product from "./pages/products";
import ProductUnderCat from "./pages/Productsundercat";
import CartContextProvider from "./context/cartcontext";
import SellerSignUp from "./pages/sellersignup";
import SellerLogin from "./pages/sellerlogin";
import SellerDash from "./components/sellers/selldash";
import AuthContextProvider from "./context/authcontex";
import UserSignUp from "./pages/usersignup";
import UserLogin from "./components/userlogin";
import MyOrders from "./components/orders";
import OrderContextProvider from "./context/ordercontext";
import SellerContextProvider from "./context/sellercontext";
import Agents from "./pages/agents";
import RefLink from "./pages/reflink";
import DeliveryLogin from "./components/Delivery/login";
import DeliverContextProvider from "./context/delivercontext";
import Tasks from "./components/Delivery/mytasks";
import History from "./components/Delivery/history";
import Orders from "./components/Delivery/orders";
import SearchItem from "./pages/search";
import Notifications from "./components/sellers/notifications";
import Settings from "./components/sellers/settings";
import AgentContextProvider from "./context/agentcontext";
import AgentLogin from "./components/agents/login";
import Dashboard from "./components/agents/dashboard";
import GeneralTerms from "./components/generalterms";
import MySettings from "./pages/settings";
import Updatebank from "./components/agents/updaatebank";
import { AiOutlineLoading } from "react-icons/ai";
import ResetPassword from "./components/resetpassword";
import AgentReset from "./components/agentreset";
import SellerReset from "./components/sellerreset";
import Item from "./pages/item";
const App = () => {
return (
 <>
  <div className="my-modal bg-blur my-d-none" id="isSending">
    <div className="my-col-4 off-4  down-15 xs-down-30vh xs-12  my-bottom-50 rad-10">
      <div className="my-col-10 off-1 centered down-5">
        <span className="anchors rad-10 bg-white"><AiOutlineLoading className="fas fa-spin "/></span>
      </div>
    </div>
  </div>
   <div className="my-toast" id="my-toast">
    <div className="xs-container my-col-10">
      <div className="xs-12 pdl-10 down-1"><span id="msg" className="xs-px13"></span></div>
    </div>
   </div>
 <Router>
  <Switch>
  <DeliverContextProvider>
   <Route path={'/riders_login'}  component={DeliveryLogin}/>
   <Route path={'/orderdelivery/Orders'} component={Orders}/>
   <Route path={'/orderdelivery/Tasks'} component={Tasks}/>
   <Route path={'/orderdelivery/History'} component={History}/>
</DeliverContextProvider>
  </Switch>
  <Switch>
   <AuthContextProvider>
    <CategoryContextProvider>
     <CartContextProvider>
      <OrderContextProvider>
      <Route path={'/'} exact component={Home}/>
      <Route path={'/agentresetter/:token'} component={AgentReset}/>
      <Route path={'/sellerresetter/:token'} component={SellerReset}/>
      <Route path={'/user_sign_up'}  component={UserSignUp}/>
      <Route path={'/signUp/:code'}  component={RefLink}/>
      <Route path={'/agents_signUp'} component={Agents}/>
      <Route path={'/user_login'}  component={UserLogin}/>
      <Route path={'/products/:subcat'} exact component={AllProducts}/>
      <Route path={'/products/:under_main_cat/:sub_cat'} component={Product}/>
      <Route path={'/Allproducts/:main_cat/:under_main_cat'} component={ProductUnderCat}/>
      <Route path={'/seller_sign_up'} component={SellerSignUp}/>
      <Route path={'/seller_login'} component={SellerLogin}/>
      <Route path={'/agents_login'} component={AgentLogin}/>
      <Route path={'/item/:id'} component={Item}/>
      <SellerContextProvider>
       <Route path={'/l-seller/account'} component={SellerDash}/>
      </SellerContextProvider>
      <AgentContextProvider>
       <Route path={'/agents/dashboard'} component={Dashboard}/>
       <Route path={'/agents/transactions'} component={Notifications}/>
      </AgentContextProvider>
        <Route path={'/Settings'} component={MySettings}/>
        <Route path={'/search/item'} component={SearchItem}/>
        <Route path={'/orders'} component={MyOrders}/>
        <Route path={'/general/terms'} component={GeneralTerms} />
      </OrderContextProvider>
     </CartContextProvider>
    </CategoryContextProvider>
   </AuthContextProvider>
  </Switch>
 </Router> 
 </>
);
}

 
export default App;
