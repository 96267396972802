import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import useApi from "../../hooks/useApi";
import useUtils from "../../utils/useutils";
import PageNav from "../pagenav";
import Navbar from "../navbar";
// import Navbar from "../components/navbar";
;

const DeliveryLogin = () => {
 const history = useHistory();
 const {requestMaker} = useApi();
 const {setToast, isPending} = useUtils();
 const [params, setParams] = useState({email:""});
 const emailRef = useRef()

 useEffect(()=> {
  document.title = 'L-Mobile ~  Order Delivery'
  // document.body.style.background = '#f3f3f3';
  return(()=> {document.body.style.backgroundColor='white';  document.title = 'L-Mobile - stores'})
 })

const submitHandler = async(e) => {
  e.preventDefault()
  if(params?.email.trim() === '' ){
   emailRef.current.focus();
   setToast('Please enter email');
   return;
  }
  isPending('sub_btn', true);
  const res = await requestMaker('/delivery/login', params);
  if(res?.status === 'successful'){
   localStorage.setItem('l-dPers', res?.data)
   history.push('/orderdelivery/Orders')
  }else {
   isPending('sub_btn', 'Login');
  }
 }


 return ( <>
 {/* <nav className="hidden-xs">
  <div className="my-container">
  <div className="my-col-2"><div className="my-logo px20 bold upper-case black">L- <span className='color-code-1'>MOBILE</span></div></div>
  </div>
 </nav> */}
 <div className="mother down-8 xs-down-3vh my-fade-in">
  {/* <div className="my-col- off-4 my-container"><i className="pd-5 fas fa-arrow-left" onClick={()=> {history.push('/')}}></i></div> */}
   <div className="my-col-4 off-4 xs-10 xs-off-1 bg-faded rad-10 my-b-shadow my-bottom-50 down- xs-down-20vh">
    <div className="my-col-10 off-1  xs-10 xs-off-1 down-8 xs-down-5">
    {/* <div className="my-bottom-10 mother"><i className="fas fa-arrow-left pd-5 c-pointer" onClick={()=> {history.push('/')}}></i></div> */}
     <div className="mother bold px20 xs-down-10">L-Mobile Dispatchers <i className="fas fa-bicycle"></i></div>
     <div><span className="px13 faded">L-mobile Delivery Personnels Only....</span></div>
     <form action="" className="mother down-3 xs-down-10">
      <div className="my-col-12 xs-12 down-1">
       <div className="mother">
       <i className="fas fa-envelope px12 color-code-1"></i>
        <span className="bol mg-5 px13 ">Personnel's Email Address</span>
       </div>
       <div className="my-col-12 down-1 xs-down-2 xs-12"><input ref={emailRef} placeholder="Registered Personels only" type="text" onChange={(e)=> {setParams(prev => ({...prev, email:e.target.value}))}} className="input bg-white px13" /></div>
      </div>
      <div className="mother down-1 xs-down-3">
        <button className="my-btn-sm down-3  mother centered xs-12 xs-centered xs-down-5 bg-color-code-1 white px13" id="sub_btn" onClick={(e)=> {submitHandler(e)}}>Login</button>
      </div>
     </form>
    </div>
   </div>
 </div>
 </> );
}
 
export default DeliveryLogin;