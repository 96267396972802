import Snowfall from "react-snowfall";
import Category from "./category";
import TopSelling from "./topselling";
import PageNav from "./pagenav";
import { useContext, useState } from 'react';
import {AiOutlineLoading} from 'react-icons/ai'
import { CategoryContext } from '../context/categorycontext';
import { CartContext } from '../context/cartcontext';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import useUtils from '../utils/useutils';
import { useRef } from 'react';
import useApi from '../hooks/useApi';
import { Zoom } from "react-reveal";
import { AuthContext } from "../context/authcontex";

const NewPage = () => {
  const {location, setSearchResults, setLocations} = useContext(CategoryContext);
  const {currentUser} = useContext(AuthContext);
  const history = useHistory();
  const {setToast} = useUtils();
  const [search, setSearch] = useState({keyword:"", table:'products', column:'item_name', location, action:"search"});
  const [searching, setSearching] = useState(false);
  const searchRef = useRef();
  const {requestMaker} = useApi()
 
 
 
  const searchHandler = async (e)=> {
   e.preventDefault();
  if(search?.keyword === "") {
   setToast('Please enter a keyword');
   searchRef.current.focus()
   return;
  }
  setSearching(true);
  const res = await requestMaker('frontend/category', search);
  if(res?.status === 'successful' ){
   history.push('/search/item')
   setSearchResults(res?.data);
   setSearching(false);
   }else {
     setSearching(false);
   }
  }

  const [soon, setSoon] = useState(false);


  return ( <>
  <PageNav active={'home'}/>
   <div className="new-bg-landing">
    <div className="dark-scarf">

      <div className="my-col-10 off-1 xs-container xs-down-8 down-3">
        <div className="my-col-12 xs-12 down-3  xs-down-20">
        {/* {currentUser &&  <div className="mother top-5"><span className="px13 unset-mg anchors bg-white rad-10 my-b-shadow color-code-1 monR">Welcome {currentUser?.name}</span></div>} */}
          <div className="my-mother xs-centered down-2">
            <span className="white px80 xs-px30 bold"> Glow Up With  <span className="color-code-1">Pvma</span></span>
            <div className="my-mother top-1 xs-down-1"><span className="xs-px13 px20 white">Unleash Your Skin's Potential</span></div>
          </div>
          <div className="my-col-6 off- xs-12  down-3 hidden-xs">
            <form onSubmit={(e)=> {searchHandler(e)}} className="-xs" >
              <div className="search-con">
              <input type="text" ref={searchRef}  className='monR px13 faded' onChange={(e) => {setSearch(prev => ({...prev, keyword:e.target.value}) )}} placeholder='Search for Products'/>
              {!searching? <span onClick={(e)=> {searchHandler(e)}} ><i className="fas fa-search" ></i></span> :  <span ><i className="fas fa-refresh fa-spin"></i></span>}
            </div>
            </form>
          </div>
        </div>
      </div>
    </div>
   </div>

  <div className="my-mother my-bottom-50 bg-white hidden-ls">
   <div className="xs-container xs-down-10 hidden-ls">
    <div className="my-col-6 off- xs-12  down-3 hidden-ls">
      <form onSubmit={(e)=> {searchHandler(e)}} className="-xs" >
        <div className="search-con">
        <input type="text" ref={searchRef}  className='monR px13 faded' onChange={(e) => {setSearch(prev => ({...prev, keyword:e.target.value}) )}} placeholder='Search for Products'/>
        {!searching? <span onClick={(e)=> {searchHandler(e)}} ><i className="fas fa-search" ></i></span> :  <span ><i className="fas fa-refresh fa-spin"></i></span>}
      </div>
      </form>
    </div>
    <div className="mother down-2 xs-down-12"><span className="px20 black-2"><b>Categories</b></span> <div className="px13 faded xs-12 xs-down-1">We have varieties for you</div> </div> 
    <div className="mother hidden-ls xs-down-3">
      <div className="hidden-ls xs-12"><Category slideToShow={3} arrow={true}/></div>
    </div>
    </div>
  </div>

   <div className="my-mother my-bottom-50 hidden-xs">
     <div className="my-col-10 off-1 down-3">
      <div className="my-mother"><span className="px20 black-2"><b>Categories</b></span> <div className="px13 faded">We have varieties for you</div> </div>
      <div className="my-mother down-3">
        <div className="hidden-xs my-col-12"><Category slideToShow={5} arrow={true}/></div>
      </div>
     </div>
   </div>


  <div className="my-col-10 off-1 xs-container xs-down-10 down-3">
    <span className="fl-right anchors bg-faded unset-mg px10 rad-10" onClick={()=> {history.push('/products/Groceries')}}>All Products  <i className="fas fa-arrow-right"></i> </span>
    <span className="px20 black-2"><b>Best Selling</b></span> <div className="px13 faded">We have varieties for you</div> 
  </div>
  <div className="my-col-10 off-1 xs-container xs-down-4">
    <div className="hidden-ls"><TopSelling slideToShow={2} arrows={false} /></div>
    <div className="hidden-xs"><TopSelling slideToShow={4} arrows={true} /></div>
  </div>
  
  </> );
}
 
export default NewPage;