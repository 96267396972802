import { useContext, useEffect, useState } from "react";
import { AiOutlineBell, AiOutlineHome, AiOutlineLogout, AiOutlineSetting} from "react-icons/ai";
import { SellerContext } from "../../context/sellercontext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AddBank from "./addbank";

const SellerSideBar = ({active, setNotifications, setSettings}) => {
const [out, setOut] = useState(false);
const history = useHistory();
const {currentUser, setSeller, getProduct, getTrns, getPendingWithdrawal, logoutOut} = useContext(SellerContext);
const token = localStorage.getItem('l-seller')

useEffect(()=> {
  if(token){
   setSeller(token);
   getProduct(token)
  }
 }, [token])

 useEffect(()=> {
  if(currentUser){
    getPendingWithdrawal(token, 'seller', currentUser?.email); 
    getTrns(token, currentUser?.email)
  }
 }, [currentUser])


 return ( <>
 {!currentUser?.bank_code === null && <AddBank from='set_up'/>}
 <div className="seller-nav-bar hidden-ls b-shadow">
  <div className="my-container">
   <div className="my-col-12">
    <span className="mg-10 px13 bold">{currentUser?.name}</span>
    <span className="px20 color-code-1 pd-5 fl-right top-1 xs-top-2 c-pointer" onClick={() => {setNotifications(true); setSettings(false)}} ><AiOutlineBell/><sup className="notification-dot"></sup></span>
   </div>
  </div>
 </div>
 {out && <div className="my-modal my-fade-in">
  <div className="my-col-4 off-4 rad-10 down-10 xs-down-20vh my-container bg-white my-bottom-50">
   <div className="my-col-10 off-1 xs-10 xs-off-1 xs-down-5 down-3">
    <div className="mother bd-bottom-bold"><span className="px13 bold">Logout</span></div>
    <div className="mother down-5 xs-down-5">
     <span>Are you sure you want to logout ?</span>
    </div>
    <div className="down-5 mother righ xs-down-8">
     <span className="pd-10 rad-10 bg-color-code-2 color-code-1 px13 c-pointer" onClick={logoutOut}>Yes</span>
     <span className="pd-10 rad-10 bg-color-code-1 white mg-10 px13 c-pointer" onClick={()=> {setOut(false)}}>Cancel</span>
    </div>
   </div>
  </div>
 </div> }

 <div className="my-col-2 i-seller-side-bars bg-color-code-1 hidden-xs">
  <div className="my-col-10 off-1  white"> 
   <div className="mother down-20 px13"><span className="bold">L - <b className="white">MOBILE</b> <span className="color-code-1">seller</span> </span></div>
  <div className="mother down-10 alice px13 monR ">Welcome:  {currentUser?.name}</div>
   <div className="mother l-seller-links c-pointer down-50 hover pd-5 down-10" onClick={() => {setNotifications(true); setSettings(false)}}>
     <span className="color-code-1 px30"><AiOutlineBell/></span>
    <span className="mg-10 alice alice monR">Notifications</span>
   </div>
   <div className="mother l-seller-links c-pointer down-5 hover pd-5 down-10" onClick={()=> {setSettings(true); setNotifications(false)}}>
    <span><span className="color-code-1 px30"><AiOutlineSetting/></span></span>
    <span className="mg-10 alice alice monR">Settings</span>
   </div>
   <div className="mother l-seller-links c-pointer down-5 hover pd-5 down-10" onClick={()=> {setOut(true)}}>
    <span><span className="color-code-1 px30"><AiOutlineLogout/></span></span>
    <span className="mg-10 alice alice monR">Logout</span>
   </div>

   <div className="mother down-50">
    <span><i className="fab pd-5 bg-color-code-2 color-code-1 fa-facebook brad"></i></span>
    <span><i className="fab pd-5 bg-color-code-2 color-code-1 fa-instagram mg-10 brad"></i></span>
    <span><i className="fab pd-5 bg-color-code-2 color-code-1 fa-twitter mg-10 brad"></i></span>
    <div className="mother down-10">
     <span className="pd-5 c-pointer px13 color-code-1">Home</span>|
     <span className="pd-5 c-pointer px13 color-code-1">About</span>|
     <span className="pd-5 c-pointer px13 color-code-1">Terms</span>|
   </div>
   </div>
  </div>
 </div>

 <div className="bottom-nav hidden-ls bg-black alice">
  <div className="xs-10 xs-off-1 centered">
   <span className={`xs-3 px20 ${active == 'home' && 'color-code-1'}`} onClick={()=> {history.push('/l-seller/account')}}><AiOutlineHome/></span>
   <span className={`xs-3 px20 ${active == 'notification' && 'color-code-1'}`} onClick={() => {setNotifications(true); setSettings(false)}} ><AiOutlineBell/></span>
   <span className={`xs-3 px20 ${active == 'settings' && 'color-code-1'}`} onClick={()=> {setSettings(true); setNotifications(false)}}><AiOutlineSetting/></span>
   <span className={`xs-3 px20`} onClick={()=> {setOut(true)}}><AiOutlineLogout/></span>
  </div>
 </div>
 
 </> );
}
 
export default SellerSideBar;