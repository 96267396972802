import {createContext, useEffect, useState} from 'react';
import useApi from '../hooks/useApi';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import useUtils from '../utils/useutils';

export const AgentContext = createContext();

const AgentContextProvider = (props) => {
 const token = localStorage.getItem('l-agent');
 const path = 'https://lmobileapi.000webhostapp.com/images/'
 const {requestMaker} = useApi();
 const history  = useHistory();
 const [currentUser, setCurrentUsers] = useState(null);
 const [products, setProducts] = useState(null)  
 const [isLoading, setisLoading] = useState(false);  
 const [pendingWithdrawal, setPendingWithdrawal] = useState([]);
 const [transactions, setTransactions] = useState([]);
 const [referee, setReferee] = useState([])
 const {isSending} = useUtils();

 const logoutOut = () => {
  localStorage.removeItem('l-agent');
  setCurrentUsers(null);
  history.push('/agents_login')
 }

 const getAgent = async(Token, loading) => {
  const params = {token:Token, action:'get_agent'}
  isSending(true);
  const result = await requestMaker('agents/agents', params);
  if(loading === true) {setisLoading(true)}
  if(result?.status === 'successful'){
   setCurrentUsers(result?.data);
   setReferee(result?.referee)
   setisLoading(false);
   isSending(false);
   return;
  }
  if(result?.status === 'SESSION EXPIRED'){
    logoutOut()
    isSending(false);
    return;
   }
 }

 const getTrns = async(Token, email) => {
  const params = {token:Token, action:'get_trn_for_agents', email}
  const result = await requestMaker('agents/transactions', params);
  if(result?.status === 'successful'){
    setTransactions(result?.data);
    return;
  }
  if(result?.status === 'SESSION EXPIRED'){
    logoutOut()
     isSending(false);
    return;
   }
 }


 const getPendingWithdrawal = async(Token, type, email) => {
  const params = {token:Token, action:'get_pending_wit', type, email}
  const result = await requestMaker('agents/withdrawalreq', params);
  if(result?.status === 'successful'){
    setPendingWithdrawal(result?.data);
    return;
  }if(result?.status === 'SESSION EXPIRED'){
    logoutOut() 
     isSending(false);
    return;
   }
 }
    
 return ( 
  <AgentContext.Provider value={{
   getAgent,
   currentUser,
   logoutOut,
   getPendingWithdrawal,
   pendingWithdrawal,
   setPendingWithdrawal,
   getTrns,
   setTransactions,
   transactions,
   referee,
   token,
   path
  }}>
   {props.children}
  </AgentContext.Provider>
 );
}
 
export default AgentContextProvider;
