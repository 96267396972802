import Slider from "react-slick";
import img1 from '../images/25929-removebg-preview.png'
import img2 from '../images/25929-removebg-preview.png'
import img3 from '../images/25929-removebg-preview.png'
import img4 from '../images/25929-removebg-preview.png'
import img5 from '../images/red-shoe-man-removebg-preview.png'
import { useContext } from "react";
import { CategoryContext } from "../context/categorycontext";
import CatSkeleton from "./skeleton/catSeleton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Category = ({slideToShow, arrow}) => {
 const {allCat, path} = useContext(CategoryContext);
 const history = useHistory();

 const settings = {
  dots: false,
  arrows:arrow,
  infinite: true,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 6000,
  slidesToShow: slideToShow,
  slidesToScroll: 1
};


 return ( <>
 {allCat?.length < 1 ? 
 <>
 <span className="hidden-xs"><CatSkeleton slideToShow={5} /> </span>
 <span className="hidden-ls"><CatSkeleton slideToShow={3} /> </span>
 </>
 
 :   <Slider {...settings}>
    {allCat?.map((i) => (
      <div key={'cat'+ i.id} onClick={()=> {history.push(`/Allproducts/${i.main_Cat}/${i.cat_name}`)}}>
       <div className="cat-img-container bg-white">
        <span className="scarf-">
        {/* <span className="cat-name hidden-xs">{i.cat_name}</span> */}
        </span>
        <img src={path + i.images} alt=""/>
        </div>
      <div className="mother down-1 hidden-xs centerd down-5 upper-case px10 xs-down-5">
       <div className="my-col-10 my-container xs-centered monR px10"><span className="xs-px9">{i.cat_name}</span></div>
       <div className="mother down-5"><span className="anchors unset-mg px10 bg-color-code-1 rad-10 white">Shop Now <i className="fas fa-arrow-right"></i></span></div>
      </div>
       {/* <div className="scarf"></div> */}
      <div className="mother down-1 hidden-ls centerd down-8 upper-case px10 xs-down-5">
       <div className="my-container xs-centered"><span className="xs-px8 bold">{i.cat_name}</span></div>
      </div>
     </div>
    ))}
   </Slider> }
    

  



 
 </> );
}
 
export default Category;