import { useContext, useEffect, useRef, useState } from "react";
import useUtils from "../../utils/useutils";
import useApi from "../../hooks/useApi";
import { AgentContext } from "../../context/agentcontext";
import { SellerContext } from "../../context/sellercontext";

const AddBank = ({setChangeBank}) => {
  const {setSeller, token} = useContext(SellerContext);
  const {currentUser} = useContext(SellerContext);
  const {formatNumber, setToast} = useUtils();
  const {requestMaker, handleBankVerify} = useApi();


  const [params, setParams]= useState({
    account_number:currentUser?.account_number,
    bank_code:currentUser?.bank_code,
    name:"",
    token,
    action:'update_bank'
  })
  
  useEffect(() => {
   setParams((prev) => ({...prev, account_number:currentUser?.account_number, bank_code:currentUser?.bank_code}))
  }, [currentUser])

  const [bankname, setBankname] = useState(null);
  const [bankdetails, setBankdetails] = useState({
    account_number:"",
    account_name:"",
    bank_code:"",
    token,
    action:'update_bank'
  });
  const [updateDetails, setUpdateDetails]  = useState(false);

  const [nofunds, setNofunds] = useState(null);
  const [pending, setPending] = useState(null);
  const amountRef = useRef();
  const codeRef = useRef();

  const provideName = async () => {
    const res = await handleBankVerify(params);
    if(res?.status === true){
      setBankname(res?.data?.account_name);
    }
  }


  useEffect(() => {
    provideName()
  }, [currentUser])



 const confirmBankDetails = async (e) => {
  e.preventDefault();
  if(params?.account_number === ""){
    setToast('Please enter account number')
    amountRef.current.focus();
    return
  }
  if(params?.account_number?.length < 10){
    setToast('Invalid account number')
    amountRef.current.focus();
    return
  }
  if(params?.bank_code === ""){
    setToast('Please select bank')
    codeRef.current.focus();
    return
  }
  setUpdateDetails('checking')
  const res = await handleBankVerify(params)
  if(res?.status === true){
    setParams(prev => ({...prev, name:res?.data?.account_name}))
    setBankname(res?.data?.account_name);
    setUpdateDetails(true);
   }else {
    setToast('Invalid Account Details');
    setUpdateDetails(false);
   }
 }

  const updateBankDetails = async (e) => {
    e.preventDefault();
    setPending(true)
    const res = await requestMaker('sellers/updatebank', params);
    if(res?.status === 'successful'){
      await setSeller(token, false);
      setUpdateDetails(false);
      setToast('Bank details saved successfully')
      setPending(false);
      setChangeBank(false);
    }else {
      setPending(false)
      setToast('Error.Please try again later')
    }
 }

return (<>
   <div className="modal-v my-fade-in">
   <div className="my-col-10 off-1 down-3 xs-down-">
    <div className="xs-container xs-down-1 my-col-10 off-1 down-">
      <div className="mother bd-bottom"><span className="bold"> <i className="pd-10 fas fa-angle-left" onClick={()=> {setChangeBank(false)}}></i> Bank Details</span></div>
       <form className="my-col-8 off-2 xs-12 my-bottom-20 down-5 xs-down-10 rad-10 bg-faded">
        <div className="my-col-10 off-1 xs-10 xs-off-1 down-5">
        <div className="my-col-6 xs-down-8 down-3">
        <div className="my-col-11 xs-12">
          <div><span className="px13 bold">Account Number </span></div>
          <input type="tel" value={params?.account_number} maxLength={11} ref={amountRef} placeholder="11 digit number" onChange={(e) => {setParams(prev => ({...prev, account_number:e.target.value}), setUpdateDetails(false))}} className="input bg-white px13 xs-down-2 down-2" />
        </div>
      </div>
      <div className="my-col-6 xs-down-4 down-4">
        <div className="my-col-12 xs-12">
          <div><span className="px13 bold">Select Bank</span></div>
          <select ref={codeRef} className="input bg-white px13 faded" value={params?.bank_code}  onChange={(e)=>{setParams((prev) => ({...prev, bank_code:e.target.value}), setBankdetails(false))}}>
          <option value="">Select Bank</option>
        <option value="044">Access Bank Plc</option>
        <option value="058">Guaranty Trust Bank</option>
        <option value="050">Ecobank Nigeria</option>
        <option value="011">First Bank of Nigeria Limited</option>
        <option value="215">Unity Bank Plc</option>
        <option value="033">United Bank for Africa Plc</option>
        <option value="035">Wema Bank Plc</option>
        <option value="214">First City Monument Bank Limited</option>
        <option value="301">Jaiz Bank Plc</option>
        <option value="082">Keystone Bank Limited</option>
        <option value="221">Stanbic IBTC Bank Plc</option>
        <option value="057">Zenith Bank Plc</option>
        <option value="032">Union Bank of Nigeria Plc</option>
        <option value="566">VFD Microfinance Bank</option>
        <option value="451">Accion Microfinance Bank</option>
        <option value="023">Citibank Nigeria Limited</option>
        <option value="512">Covenant Microfinance Bank Ltd</option>
        <option value="526">Empire Trust Microfinance Bank</option>
        <option value="070">Fidelity Bank Plc</option>
        <option value="138">Finca Microfinance Bank Limited</option>
        <option value="608">Fina Trust Microfinance Bank</option>
        <option value="001">Globus Bank Limited</option>
        <option value="030">Heritage Bank Plc</option>
        <option value="562">Infinity Microfinance Bank</option>
        <option value="101">LOTUS BANK</option>
        <option value="281">Mint Finex MFB</option>
        <option value="503">Mutual Trust Microfinance Bank</option>
        <option value="551">Peace Microfinance Bank</option>
        <option value="304">Pearl Microfinance Bank Limited</option>
        <option value="113">Rephidim Microfinance Bank</option>
        <option value="057">Sparkle Bank</option>
        <option value="068">Standard Chartered</option>
        <option value="232">Sterling Bank Plc</option>
        <option value="302">TAJBank Limited</option>
        <option value="102">Titan Trust bank</option>
          </select>
          </div>
        </div>
          {bankname &&  <div className="mother xs-down-10 down-2"><button className="my-btn-sm mother bg-white bold px13 centered black-2 c-pointer" disabled >{bankname}</button> </div>  }
          <div className="mother xs-down-10 down-2">
            {updateDetails === 'checking' && <button className="my-btn-sm mother bg-color-code-1 white px13 centered bold c-pointer" disabled >Checking....</button> }
            {updateDetails === false &&  <button className="my-btn-sm mother bg-color-code-1 white px13 centered bold c-pointer" onClick={(e) => {confirmBankDetails(e)}}> Confirm Details </button> }
            {updateDetails === true && <button className="my-btn-sm mother bg-color-code-1 white px13 centered bold c-pointer" onClick={(e) => {updateBankDetails(e)} } > {pending? "Saving" : 'Save Bank Details'}</button> }
      </div>
        </div>
     </form>
    </div>
   </div>
   </div>
  </>  );
}
 
export default AddBank;