import {createContext, useEffect, useState} from 'react';
import useApi from '../hooks/useApi';

export const OrderContext = createContext();

const OrderContextProvider = (props) => {
 const token = localStorage.getItem('lm-user');
 const {requestMaker} = useApi();
 const [orders, setOrders] = useState(null)
 
 const getOrders = async(Token) => {
  const params = {token:Token}
  const result = await requestMaker('user/getorders', params);
  if(result?.status === 'successful'){
   setOrders(result?.data);
   return;
  }
  if(result?.status === 'SESSION EXPIRED'){
    localStorage.removeItem('lm-user');
    return;
   }
 }


   
 return ( 
  <OrderContext.Provider value={{
   getOrders,
   orders,
  }}>
   {props.children}
  </OrderContext.Provider>
 );
}
 
export default OrderContextProvider;
