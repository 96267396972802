import { AiOutlineArrowDown, AiOutlineArrowsAlt, AiOutlineCarryOut, AiOutlineShoppingCart, AiOutlineSortAscending } from "react-icons/ai";
import Navbar from "../components/navbar";
import Dropdowns from "../components/dropdown";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import CatSkeleton from "../components/skeleton/catSeleton";
import { useContext, useEffect, useState } from "react";
import ViewProducts from "./viewproducts";
import useApi from "../hooks/useApi";
import { CategoryContext } from "../context/categorycontext";
import useUtils from "../utils/useutils";
import { CartContext } from "../context/cartcontext";
import PageNav from "../components/pagenav";


const ProductUnderCat = () => {
const {main_cat, under_main_cat} = useParams();
const [results, setResults] = useState([]);
const [views, setViews] = useState(null);
const {requestMaker} = useApi();
const {formatNumber} = useUtils()
const {addToCart} = useContext(CartContext)
const {allCat, location, path} = useContext(CategoryContext)
const [sub, setSub] = useState([]);
const history = useHistory();


const getProductshandler = async ()=>{
 const params = {action:'get_under_main', main_cat:main_cat, category:under_main_cat, location:location};
 const res = await requestMaker('frontend/category', params);
 if(res?.status === 'successful'){
  setResults(res?.data);
 }else {

 }
}


useEffect(() => {
 var obj = allCat?.find((i) => i.cat_name == under_main_cat);
 if(obj){
  var subArr = JSON.parse(obj.sub_cat);
  setSub(subArr)
 }
}, [allCat, main_cat, under_main_cat, location])



useEffect(() => {
 if(under_main_cat && main_cat ){
  getProductshandler();
 }else {
  history.push('/')
 }
}, [under_main_cat, main_cat, location])




 return ( <>
 <PageNav/>
 <div className="my-container my-col-10 off-1 down-9 my-bottom-50 xs-down-15">
  <div className="mother xs-down-5 bd-bottom">
   <div><span className="fas fa-arrow-left pd-5 c-pointer" onClick={()=> {history.push('/')}}></span></div>
   <div className="my-col-6 xs-8 down-2 xs-down-5 xs-px10">
    <span className="upper-case bold faded">Products 
    <i className="fas fa-angle-right mg-10"></i>
    <span className="mg-10 black">{under_main_cat}</span>
   </span>
   </div>
   <div className="my-col-4 xs-4 off-2 down-1">
   <span className="fl-right my-btn-sm top-1 xs-top-2 bg-color-code-1 upper-case white px13 c-pointer">
    Sub-Cat
     <div className="sort-db bg-faded">
      {sub?.map((i, index) => (
       <div className="links mother down-2 xs-down-2 faded upper-case px13 my-btn-sm bg-white xs-px10" onClick={()=> {history.push(`/products/${under_main_cat}/${i.name}`)}} key={'sjdk' + index}>{i.name}</div>
       ))}
     </div>
   </span>
   </div>
 </div>

 <div className="mother down-1 xs-down-1 my-bottom-50">

  {results?.length < 1 ?
  <>
  <span className="hidden-xs"><CatSkeleton slideToShow={5} /> </span>
  <span className="hidden-ls"><CatSkeleton slideToShow={3} /> </span>
  </> :
   <>
     {results?.map((i, index) => (
       <div className="my-col-3 xs-6 down-1 xs-down-5" key={'pro' + index} >
       <div className="my-col-12 down-2 my-bottom-50" key={i.id}>
       <div className="product-card" onClick={()=> {setViews('view' + i.id)}}>
        <div className="scarf"></div>
        <div className="product-card-img-con"><img src={path + i.image_1} alt="" /></div>
        <div className="my-container-2 down-5">
         <div className="mother">
          <div className="my-col-12 xs-12 xs-down-8">
           <div className="px10 faded upper-case">{i.sub_cat}</div>
           <span className="px13 xs-px10  bold upper-case hidden-ls">{i.item_name?.slice(0, 20)+'...'}</span>
           <span className="px13 xs-px10  bold upper-case hidden-xs">{i.item_name?.slice(0, 25)+'...'}</span>
          <div className="mother down-1 px10">
           {/* <span>{i.ratings} <i className=" px12 fas fa-star color-code-1"></i></span> */}
          </div>
         </div>
         </div>
         <div className="mother down-5 xs-down-2">
          <div className="my-col-12 xs-12" >
          <div className="mother top-2 xs-down-2"> <span className=" color-code-1 xs-px13 px13  bold mother">₦ {formatNumber(i.price)}</span></div>
           <div className="mother xs-top-1"><s className="xs-px10 px13 bold">₦ {formatNumber(i.slash_price)}</s></div>
          </div>
          <div className="my-col-12 xs-12 down-4"  onClick={(e)=> {addToCart(e, i)}}><span className="my-btn-sm bd-code-1 color-code-1 px13 xs-px10 centered mother">Add to Cart <i className="fas fa-arrow-right"></i> </span></div>
         </div>
        </div>
       </div>
      </div>
       </div>
      ))}
   </>

  }
 </div>
 {results?.map((i, index) => (
   <div  key={index + 'vwo'}>
    {views === 'view' + i.id && <ViewProducts setViews={setViews} i={i}/>}
   </div>
  ))}


 </div>
 </> );
}
 
export default ProductUnderCat;
