import { useContext, useEffect, useState } from "react";
import CatSkeleton from "../skeleton/catSeleton";
import Menu from "./menu";
import ViewOrder from "./vieworder";
import { DeliverContext } from "../../context/delivercontext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import useUtils from "../../utils/useutils";

const History = () => {
  const [details, setDetails] = useState(null);
  const {formatTransactionTime} = useUtils();
  const history = useHistory();
  const {History, getHistory, currentUser, token} = useContext(DeliverContext);
  const now = moment();
  const formattedTime = now.format('YYYY-MM-DD HH:mm:ss');
  
  useEffect(() => {
   if(token){
    getHistory(token)
   }
  }, [token])

  // useEffect(()=> {
  //   console.log(History)
  // }, [])

  return ( <>
  <Menu active={'history'} />
  <div className="my-col-10 off-2 xs-12 down-7 xs-down-10vh">
    <div className="my-col-10 off-1 my-container">
      <div className="mother bd-bottom-bold"><span className="px13 upper-case bold faded">My History</span></div>
     
      <div className="mother down-4 xs-down-5">
      {History === null ?  <div className="mother down-4 xs-down-5"><CatSkeleton slideToShow={4}/></div> : <>
      {History?.length < 1 && <span className="faded">No History</span>  }
       {History?.map((i, index) => (
         <div className="my-col-3 xs-12 xs-down-3" key={index + 'Historys'}>
          {details === 'see-details' + i.id && <ViewOrder setDetails={setDetails} i={i} from={'History'} />}
           <div className="my-col-11 xs-12 bg-faded my-b-shadow rad-10 my-bottom-20" onClick={()=> {setDetails('see-details' + i.id)}}>
             <div className="my-col-10 off-1 xs-10 xs-off-1 xs-down-5 down-6">
               <div className="mother"><span className="bold px13 color-cod1 upper-case">Order: {i.order_id} - {i.location}</span></div>
               <div className="mother"><span className="px10 upper-case">{formatTransactionTime(i?.trn, formattedTime)}</span></div>
               <div className="mother down-5 xs-down-5"><span className="pd-10 bg-white faded px10 c-pointer" onClick={()=> {setDetails('see-details' + i.id)}}>View details</span></div>
             </div>
           </div>
         </div>
       ))}
      </>}
      </div>
    </div>
  </div>
  </> );
}
 
export default History;