import { useContext, useState } from "react";
import { CartContext } from "../context/cartcontext";
import { usePaystackPayment } from 'react-paystack';
import useUtils from "../utils/useutils";
import { AuthContext } from "../context/authcontex";
import useApi from "../hooks/useApi";
import { CategoryContext } from "../context/categorycontext";
import { OrderContext } from "../context/ordercontext";
import  img1  from '../images/successfully-done.gif';

const Payment = ({setPay, setOpenCart}) => {
 const token = localStorage.getItem('lm-user');
 const {cart, weight, setCart, totalPrice} = useContext(CartContext);
 const {location} = useContext(CategoryContext);
 const {getOrders} = useContext(OrderContext)
 const {requestMaker} = useApi();
 const {setToast, isPending, calculateDelieveryPrice, makeid, agentAmount} = useUtils();
 const {currentUser} = useContext(AuthContext);
 const [confirmed, setConfirmed] = useState(false)

//  alert(process.env.REACT_APP_API);

 const [order, setOrder] = useState({
  token:token,
  items:JSON.stringify(cart),
  name: currentUser?.name,
  email:currentUser?.email,
  referer:currentUser?.referer,
  total_paid: totalPrice + calculateDelieveryPrice(weight),
  order_id: "",
  ref:"",
  delivery_code:"",
  location:location,
  delivery_status:0,  // 0 = pending
  address:currentUser?.address,
  phone:currentUser?.phone,
  amount_to_agent:agentAmount(totalPrice),
 });
 
 const config = {
  reference: (new Date()).getTime().toString(),
  email: currentUser?.email,
  amount: (totalPrice + calculateDelieveryPrice(weight)) * 100, 
  publicKey: process.env.REACT_APP_API,
  // publicKey: `pk_test_b2d43ea33a9145a00cb321f23010282bf1466772`,
}

const onSuccess = (reference) => {
  submitOrderHandler(reference);
};

const onClose = () => {
  alert("Are you sure you want to close this dialog ?. Might affect the payment process")
  isPending('pay_btn', 'Proceed To Pay');
}


const initializePayment = usePaystackPayment(config);

const ProceedToPay = ()  => {
  isPending('pay_btn', true);
  initializePayment(onSuccess, onClose)
}

const submitOrderHandler = async(reference) => {
 order['ref'] = reference.trxref;
 order['order_id'] = reference.transaction;
 order['delivery_code'] = 'Odr-'+makeid(5)+makeid(3);
 const res = await requestMaker('user/submitorder', order);
 isPending('pay_btn', true);
 if(res?.status === 'successful'){
  await getOrders(token)
  setConfirmed(true);
  setCart([]);
  localStorage.removeItem('LM_cart');
  setTimeout(() => {
   setOpenCart(false)
  }, 4000);
 }else {
   setTimeout(() => {
    submitOrderHandler(reference);
   }, 1000);
 }
}

return (<>
  <div className="my-modal my-fad z-400">
   {confirmed ? <div className="my-col-4 my-container xs-down-15vh off-4 rad-10 down-8 rad-10 my-bottom-50">
    <div className="my-col-10 off-1 bg-white xs-10 xs-off-1 xs-down-10 down-5">
     <div className="product-card-img-con xs-down-10">
      <img src={img1} alt="" />
     </div>
     <div className="my-col-10 off-1 xs-10 xs-off-1 centered down-4 my-bottom-50"><span><b>Payment Confirmed !</b> <br /> Your Order Is On It way ✨</span></div>
    </div>
   </div> :     
   <div className="my-col-4 my-container xs-down-15vh off-4 down-8 bg-white my-bottom-50">
   <div className="my-col-10 off-1 xs-10 xs-off-1 xs-down-8 down-5">
    <div className="mother bd-bottom bold"> Order Confirmation <i className="fl-right fas fa-times px13 faded pd-5" onClick={()=> {setPay(false)}}></i></div>
   <div className="mother down-5">
      <div className="mother xs-down-5 px13 faded">
        <span className="px13 faded">Please double-check your cart for accurate selection.</span>
        <div className="mother down-3 xs-down-3 pd-10 bg-color-code-2 color-code-1"> <span className="color-code-1">Once confirmed, orders cannot be undone</span>. </div>
        <div className="mother down-3 xs-down-3">It is essential that you have the correct items in the correct quntity to avoid any discrepancies.</div>
      </div>
      <div className="mother down-3 xs-down-5">
        {/* <div className="pd-10 bg-color-code-2"><span className="color-code-1 px13">The delivery time frame for your order is estimated to be within 2 to 3 hours from the time of payment.</span> </div> */}
      </div>
    </div>
     <div className="mother down-5 xs-down-8">
      <button onClick={() => {ProceedToPay()}}  className="px13 my-btn-sm white bg-color-code-1  px13"  id='pay_btn' > Proceed To Pay </button>
     </div>
   </div>
 </div>
   }
 </div>
 
 </>  );
}
 
export default Payment;