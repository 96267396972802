import { AiOutlineShoppingCart } from "react-icons/ai";
import img4 from '../images/25929-removebg-preview.png'
import Slider from "react-slick/lib/slider";
import { useContext, useState } from "react";
import { CategoryContext } from "../context/categorycontext";
import ViewProducts from "../pages/viewproducts";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CatSkeleton from "./skeleton/catSeleton";
import useUtils from "../utils/useutils";
import { CartContext } from "../context/cartcontext";

const TopSelling = ({slideToShow, arrows}) => {
 const {products, path} = useContext(CategoryContext)
 const {addToCart} = useContext(CartContext)
 const [views, setViews] = useState()
 const {formatNumber} = useUtils();
 const history = useHistory()
 const settings = {
  dots: false,
  fade: false,
  arrows: arrows,
  infinite: false,
  speed: 1000,
  autoplay: true,
  autoplaySpeed: 7000,
  pauseOnHover: true, // prevent pausing on hover
  slidesToShow: slideToShow,
  slidesToScroll: 1
 };


const addTocartHandler = (e) => {
 e.stopPropagation()
 alert('added o cart')
}

 return ( <>
  <div className="mother down-2">
   {products === null ?
    <>
    <span className="hidden-xs"><CatSkeleton slideToShow={5} /> </span>
    <span className="hidden-ls"><CatSkeleton slideToShow={3} /> </span>
    </> : 
      <Slider {...settings} >
      {products?.map((i, index) => (
       <div className="mother" key={'pro' + index} >
       <div className="my-col-12 down-2 xs-12 my-bottom-10" key={i.id}>
       <div className="product-card b-shadow" onClick={()=> {setViews('view' + i.id)}}>
        <div className="scarf"></div>
        <div className="product-card-img-con"><img src={path + i.image_1} alt="" /></div>
        <div className="my-container-2 down-5">
         <div className="mother">
          <div className="my-col-12 xs-12 xs-down-8">
           <div className="px10 faded upper-case">{i.category?.slice(0, 20)}</div>
           <span className="px13 xs-px10  bold upper-case hidden-ls">{i.item_name?.slice(0, 20)+'...'}</span>
           <span className="px13 xs-px10  bold upper-case hidden-xs">{i.item_name?.slice(0, 25)+'...'}</span>
          <div className="mother down-1 px10">
           {/* <span>{i.ratings} <i className=" px12 fas fa-star color-code-1"></i></span> */}
          </div>
         </div>
         </div>
         <div className="mother down-5 xs-down-2">
          <div className="my-col-12 xs-12" >
          <div className="mother top-2 xs-down-2"> <span className=" color-code-1 xs-px13 px13  bold mother">₦ {formatNumber(i.price)}</span></div>
           <div className="mother xs-top-1"><s className="xs-px10 px10 bold unset-mg bg-faded">₦ {formatNumber(i.slash_price)}</s></div>
          </div>
          <div className="my-col-12 xs-12 down-4"  onClick={(e)=> {addToCart(e, i)}}><span className="my-btn-sm bd-code-1 color-code-1 px13 xs-px10 centered mother monR">Add to Cart </span></div>
         </div>
        </div>
       </div>
      </div>
       </div>
      ))}
     </Slider>
  }


  {products?.map((i, index) => (
   <div  key={index + 'vw'}>
    {views === 'view' + i.id && <ViewProducts setViews={setViews} i={i} />}
   </div>
  ))}
   </div>
 </>  );
}
 
export default TopSelling;