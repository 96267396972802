import { useEffect, useRef, useState } from "react";
import Navbar from "../components/navbar";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useApi from "../hooks/useApi";
import useUtils from "../utils/useutils";
import EmailVar from "../components/emailvar";
import PageNav from "../components/pagenav";

const SellerSignUp = () => {
 const history = useHistory();
 const {requestMaker} = useApi();
 const {setToast, isPending, makeid} = useUtils();
 const [params, setParams] = useState({name:"", email:"", ref_code:"", location:"",  action:'signup', phone:"", password:"", retype:""});
 const [emailVar, setEmailVar] = useState(false);
 const nameRef = useRef()
 const emailRef = useRef()
 const phoneRef = useRef()
 const locationRef = useRef()
 const passwordRef = useRef();


 const states = [
  "Abia",
  "Adamawa",
  "Akwa Ibom",
  "Anambra",
  "Bauchi",
  "Bayelsa",
  "Benue",
  "Borno",
  "Cross River",
  "Delta",
  "Ebonyi",
  "Edo",
  "Ekiti",
  "Enugu",
  "Gombe",
  "Imo",
  "Jigawa",
  "Kaduna",
  "Kano",
  "Katsina",
  "Kebbi",
  "Kogi",
  "Ilorin",
  "Lagos",
  "Nasarawa",
  "Niger",
  "Ogun",
  "Ondo",
  "Osun",
  "Oyo",
  "Plateau",
  "Rivers",
  "Sokoto",
  "Taraba",
  "Yobe",
  "Zamfara",
  "Federal Capital Territory (FCT)"
];


 useEffect(()=> {
  document.title = 'L-Mobile ~ Merchant Sign Up'
  return(()=> {document.body.style.backgroundColor='white'; document.title = 'L-Mobile ~ Supermarket'})
 })


 const submitHandler = async(e) => {
  e.preventDefault()
  if(params?.name.trim() === '' ){
   nameRef.current.focus();
   setToast('Please enter company name');
   return;
  }
  if(params?.email.trim() === '' ){
   emailRef.current.focus();
   setToast('Please enter email');
   return;
  }
  if(params?.location.trim() === '' ){
   locationRef.current.focus();
   setToast('Please choose location');
   return;
  }
  if(!params?.email.includes('@')){
   emailRef.current.focus();
   setToast('Please enter a valid email');
   return;
  }
  if(params?.phone.trim() === '' ){
   phoneRef.current.focus();
   setToast('Please enter phonenumber');
   return;
  }
  if(params?.password.trim() === '' ){
   passwordRef.current.focus();
   setToast('Please enter password');
   return;
  }
  if(params?.password.trim() !== params?.retype.trim()){
   passwordRef.current.focus();  
   setToast('Password does not match !');
   return;
  }
  isPending('sub_btn', true);
  const res = await requestMaker('sellers/signup', params);
  if(res?.status === 'successful'){
   setEmailVar(true);
  }else {
   isPending('sub_btn', 'Create Account');
  }
 }


 return ( <>
 <PageNav/>
 {emailVar? <EmailVar params={params} api={'sellers/signup'} tokenTitle={'l-seller'} path={'/seller_login'} /> : <>
 <div className="mother down-7 xs-down-20 my-fade-in">
   <div className="my-col-6 off-3 rad-10 bg-faded xs-container my-bottom-20 xs-down-3">
    <div className="my-col-10 off-1 xs-10  xs-off-1 down-2 xs-down-5">
      <div className="img-location"></div>
     <div className="mother centered xs-down-5 monR bold down-2">Merchants Sign Up</div>
     <div className="mother centered monR xs-down-5"><span className="px13 faded">Give your products the exposure they need at frendly commisions.</span></div>
     <form action="" className="mother down-3 xs-down-5">
      <div className="mother ">
       <div><input ref={nameRef} placeholder="Merchant / Business Name" onChange={(e)=> {setParams(prev => ({...prev, name:e.target.value}))}} type="text" className="input px13 bg-white monR" /></div>
      </div>
      <div className="my-col-6 xs-6 down-1 xs-down-2">
       <div className="my-col-11 xs-11"><input placeholder="Email Address" ref={emailRef} type="text" onChange={(e)=> {setParams(prev => ({...prev, email:e.target.value}))}} className="input px13 bg-white monR" /></div>
      </div>
      <div className="my-col-6 xs-6 down-1 xs-down-2">
       <div className="my-col-12 xs-12"><input  placeholder="Phone Number" ref={phoneRef} type="text" onChange={(e)=> {setParams(prev => ({...prev, phone:e.target.value}))}} className="input px13 bg-white monR" /></div>
      </div>
      <div className="my-col-6 xs-6 down-1 xs-down-2">
       <select ref={locationRef} onChange={(e)=> {setParams(prev => ({...prev, location:e.target.value}))}} className="input my-col-11 xs-11 px13 bg-white">
        <option value="">Select Location</option>
      {states.map((state, index) => (
        <option key={index} value={state}>
          {state}
        </option>
      ))}
    </select>
      </div>
      <div className="my-col-6 xs-6 down-1 xs-down-2">
       <div className="my-col-12 xs-12"><input ref={passwordRef} placeholder="Password"  type="text" onChange={(e)=> {setParams(prev => ({...prev, password:e.target.value}))}} className="input px13 bg-white monR" /></div>
      </div>
      <div className="mother down-1 xs-down-2">
       <div><input type="text" placeholder="Confirm Password" onChange={(e)=> {setParams(prev => ({...prev, retype:e.target.value}))}} className="input px13 bg-white monR" /></div>
      </div>
     </form>
    </div>
    <div className="my-col-10 off-1 xs-10 xs-off-1 down-1 xs-down-6 px13 faded"><span>Sign up means you have read and agreed with<span className="color-code-1 pd-5 bold  c-pointer" onClick={()=> {history.push('/general/terms')}}>L-mobile terms and conditions.</span></span></div>
    <div className="my-col-10 off-1 down-1 xs-10 xs-off-1 xs-down-1">
      <button className="my-btn-sm  xs-12 down- xs-down-3 bg-color-code-1 mother white px13 " id="sub_btn" onClick={(e)=> {submitHandler(e)}}>Create Account</button>
    </div>
   </div>
      <div className="mother dow1  xs-down-5 centered"><span className="color-code-1 anchors bg-fade c-pointer px13" onClick={()=> {history.push('/seller_login')}}>I already registered <i className="fas fa-angle-right"></i> </span></div>
 </div>
 </> }
 </> );
}
 
export default SellerSignUp;