import { useContext, useEffect, useState} from "react";
import { CartContext } from "../context/cartcontext";
import { CategoryContext } from "../context/categorycontext";
import useUtils from "../utils/useutils";

const CartItem = ({i}) => {
 const {formatNumber} = useUtils();
 const {cart, setCart, RemoveFromCart, addQuantity} = useContext(CartContext);
 const {path} = useContext(CategoryContext);
 const [quantity, setQuantity] = useState(i.quantity);

 const addQts = ()=> {
  setQuantity(prev => prev + 1);
 }

 useEffect(()=> {
  addQuantity(i.id, quantity, i.unitPrice, i.unitWeight)
 }, [quantity])

 const removeQuantity = ()=> {
  if(quantity < 2){
   setQuantity(1)
  }else {
   setQuantity(prev => prev - 1)
  }
 }



 return ( <>
  <div className="mother down-1 xs-down-1 bg-white rad-10 pd-5" key={'sjkdjsd' + i.id}>
   <div className="my-col-3 xs-4"><div className="cart-img-container "><img src={path + i.image} alt="" /></div></div>
      <div className="my-col-7 xs-7">
       <div className="my-col-10 xs-10 xs-off-1 off-1">
        <span className="px12 bold">{i?.item_name?.slice(0, 19) + '...'}</span>
         <div className="mother top-2 xs-top-5"><span className="px10 color-code-1 bold unset-mg">₦{formatNumber(parseFloat(i.price))}</span></div>
         <div className="mother top-2 xs-top-2">
          <div className="my-col-12 down-1 xs-down-2 xs-12 ">
           <span className="pd-5 bg-color-code-1 white px10 c-pointer" onClick={removeQuantity}><i className="fas fa-minus"></i></span>
           <span className="pd-5 bg-white px10 c-pointer mg-10">{quantity}</span>
           <span className="pd-5 bg-color-code-1 white px10 mg-10 c-pointer" onClick={addQts}><i className="fas fa-plus"></i></span>
           <div>  <span className="down-9 xs-down-3 p-absolute bg-faded px10 xs-px13 bold">{i.weight} kgs</span></div>
          </div>
        </div>
       </div>
       </div>
       <div className="my-col-1 xs-1">
        <div className="my-container right">
          <span className="px13 c-pointer pd-5 brad" onClick={()=> {RemoveFromCart(i.id)}} title="Remove This Item"><i className="fas fa-times px15"></i></span>
        </div>
       </div>
     </div>
 
 </> );
}
 
export default CartItem;