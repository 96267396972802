import Slider from "react-slick/lib/slider";
import Navbar from "../components/navbar";
import { AiOutlinePhone, AiOutlineShoppingCart } from "react-icons/ai";
import { useContext, useEffect, useState } from "react";
import { CategoryContext } from "../context/categorycontext";
import { CartContext } from "../context/cartcontext";
import useUtils from "../utils/useutils";
import useApi from "../hooks/useApi";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import PageNav from "../components/pagenav";
import { Helmet } from 'react-helmet';
import parse from 'react-html-parser';

const Item = () => {

  
 const {path} = useContext(CategoryContext);
 const {addToCart} = useContext(CartContext)
 const {formatNumber, formatNigerianPhoneNumber, isSending} = useUtils();
 const {id} = useParams();
 const {requestMaker} = useApi();
 const [i, setI] = useState({})
 const [error, setError] = useState();
 const history = useHistory()
 
 const getItem = async () => {
   const params = {id, action:'get_item'}
   setError(false)
   isSending(true)
   const res = await requestMaker('frontend/category', params);
   if(res?.status === 'successful'){
    if(res?.data !== false){
      setI(res?.data);
    }else {
      history.push('/')
    }
    isSending(false)
   }else {
    isSending(false)
    setError(true)
   }
 }

 useEffect(() => {
   if(id){
    getItem(id);
   }else{
    history.push('/')
   } 
 }, [])

 const settings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 1000,
  autoplay: true,
  autoplaySpeed: 7000,
  pauseOnHover: true, // prevent pausing on hover
  slidesToShow: 1,
  slidesToScroll: 1
 };


 const whatsapp =()=> {
  window.open(`https://api.whatsapp.com/send?phone=${formatNigerianPhoneNumber(i.phone)}&text=Hello%2C%20I%20want%20more%20information%20about%20${i.item_name}`)
 }



 return (
 <>
 <Helmet>
  <title>{i?.item_name}</title>
  <meta name="description" content={i?.description} />
 </Helmet>
 <Helmet>
  <link rel="icon" type="image/ico" href={path + i.image_1} />
 </Helmet>

{error &&  <div className="my-modal bg-blur">
  <div className="my-col-4 off-4 xs-10 xs-off-1 xs-down-10 my-bottom-50 bg-white down-10 rad-10">
     <div className="img-location down-10"></div>
    <div className="centered monR black-2 down-1">There Was an Error loading this Page</div>
    <div className="centered mother down-3 xs-down-3"><span className="anchors c-pointer bg-faded px13 c-pointer" onClick={getItem}>Retry</span></div>
  </div>
 </div>}
 <div className="mother"> <PageNav/></div>
 <div className="mother down-10 xs-down-10vh">
 <div className="my-container xs-down-2 hidden-ls">
  <Slider {...settings} >
  {i.image_1 !== '' && <div className="view-pro-img-con">
   <div className="darkscarf"></div>
       <img src={path + i.image_1} alt="" />
  </div>}
  {i.image_2 !== '' && <div className="view-pro-img-con">
   <div className="darkscarf"></div>
       <img src={path + i.image_2} alt="" />
  </div>}
  {i.image_3 !== '' && <div className="view-pro-img-con">
   <div className="darkscarf"></div>
       <img src={path + i.image_3} alt="" />
  </div>}
  </Slider>
 </div>

 <div className="my-col-10 off-1 down-1 hidden-xs">
 <div className="my-col-4">
 <Slider {...settings} >
  {i.image_1 !== '' && <div className="view-pro-img-con">
   <div className="dark-scarf"></div>
       <img src={path + i.image_1} alt="" />
  </div>}
  {i.image_2 !== '' && <div className="view-pro-img-con">
   <div className="dark-scarf"></div>
       <img src={path + i.image_2} alt="" />
  </div>}
  {i.image_3 !== '' && <div className="view-pro-img-con">
   <div className="dark-scarf"></div>
       <img src={path + i.image_3} alt="" />
  </div>}
  </Slider>
 </div>
 <div className="my-col-8 xs-down-10 top-1 my-bottom-50 monR">
  <div className="my-container off-1">
   <div className="mother top-1 upper-case px10 faded"><span className="">Products
   <i className="fas fa-angle-right mg-5 px10"></i>
    <span className="mg-5">{i.category}</span>
    <i className="fas fa-angle-right mg-5 px10"></i>
    <span className="mg-5">{i.sub_cat}</span>
    </span>
    </div>
   <div className="mother down-1"><span className="bold px20">{i.item_name} --- (₦{formatNumber(i.price)})</span></div>
   <div className="mother down-4">
    <div className="mother xs-down-3 bd-botto">
    <div className="my-col-6"><span className="faded px13 bold upper-case">About Item</span></div>
    </div>
    <div className="mother my-bottom-50"> <span className="black-2 px13">{ parse(i.description) }</span></div>
    </div>
    <div className="my-bottom-5 top-4 mother">
  <div className="mother">
   <span className="upper-case px13 faded"><b>Seller</b></span>
   <div><span className="black-2 px13 bold upper-case">{i.owner}</span></div>
  </div>
  <div className="mother down-2">
   <div><span className="upper-case px13 faded"><b>Enquires</b> </span></div>
    <div className="mother down-1">
    <a href={`tel: ${i.phone}`}><i className="pd-10 color-code-1 bg-color-code-2 px10 fas my-b-shadow rad-10 c-pointer fa-phone"></i></a>
   <i className="pd-10 color-code-1 bg-color-code-2 px10 my-b-shadow fab rad-10 mg-10 c-pointer fa-whatsapp" onClick={whatsapp}></i>
    </div>
    <div className="mother down-3"><span className="my-btn-sm bg-color-code-1 white px13 c-pointer rad-10" onClick={(e)=> {addToCart(e, i)}}>Add To Cart</span></div>
  </div>
 </div>
 </div>
 </div>

 </div>

 <div className="my-container xs-down-10 my-bottom-50 hidden-ls">
  <div className="my-container">
   <div><span className="px13 faded">{i.main_cat} 
   <i className="fas fa-angle-right mg-5 px10"></i>
    <span className="mg-5">{i.category}</span>
    <i className="fas fa-angle-right mg-5 px10"></i>
    <span className="mg-5">{i.sub_cat}</span>
    </span>
    </div>
   <div className="mother xs-down-3"><span className="bold px20">{i.item_name} --- (₦{formatNumber(i.price)})</span></div>
   <div className="mother xs-down-4">
    <div className="mother xs-down-3 bd-botto">
    <div className="xs-5"><span className="pd-5 bg-color-code-2 color-code-1 xs-px13">About Item</span></div>
    </div>
    <div className="mother xs-down-5 my-bottom-50"> <span className="faded-2 px13">{ parse(i.description) }</span></div>
    </div>
 </div>
 <div className="my-bottom-50 mother"></div>
 </div>

 <div className="price-container bg-color-code-1 xs-top-15 hidden-ls my-bottom-50">
  <div className="my-container">
   <div className="xs-4 xs-down-2">
    <a href={`tel: ${i.phone}`}>
    <span  className="px20 pd-10 bg-color-code-2 color-code-1 rad-10"><i className="fas fa-phone"></i></span>
    </a>
    <span className="px20 pd-10 bg-color-code-2 xs-off-1 color-code-1 rad-10" onClick={whatsapp}><i className="fab fa-whatsapp"></i></span>
  </div>
   <div className="xs-8"><span className="add-to-cart-btn bg-color-code-1 white" onClick={(e)=> {addToCart(e, i)}}>Add to Cart <AiOutlineShoppingCart/> </span></div>
  </div>
 </div>
 <div className="mother down-10"></div>
 <div className="mother down-10"></div>
 <div className="mother down-10"></div>
 <div className="mother down-10"></div>
 <div className="mother down-10"></div>
 </div></>);
}
 
export default Item;