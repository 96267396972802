import { AiOutlineArrowDown, AiOutlineArrowsAlt, AiOutlineCarryOut, AiOutlineShoppingCart, AiOutlineSortAscending } from "react-icons/ai";
import Navbar from "../components/navbar";
import Dropdowns from "../components/dropdown";
import { useEffect } from "react";
import PageNav from "../components/pagenav";
import Slider from "react-slick";
import { useContext } from "react";
import { CategoryContext } from "../context/categorycontext";
import { useRef } from "react";
import ViewProducts from "./viewproducts";
import useUtils from "../utils/useutils";
import { useState } from "react";
import { CartContext } from "../context/cartcontext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useApi from "../hooks/useApi";
import CatSkeleton from "../components/skeleton/catSeleton";

const SearchItem = () => {
  const {allCat, path, searchResults, setSearchResults, location} = useContext(CategoryContext)
  const [fetching, setFetching] = useState(false)
  const {formatNumber, setToast} = useUtils();
  const [] = useState(null)
  const [searching, setSearching] = useState(null)
  const history = useHistory();
  const {addToCart} = useContext(CartContext)
 
  const settings = {
    dots: false,
    fade: false,
    arrows: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 7000,
    pauseOnHover: true, // prevent pausing on hover
    slidesToShow: 6,
    slidesToScroll: 1
  };
  const settingss = {
    dots: false,
    fade: false,
    arrows: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 7000,
    pauseOnHover: true, // prevent pausing on hover
    slidesToShow: 2.3,
    slidesToScroll: 1
  };
  const [views, setViews] = useState(null);

  // const scrollHandler = async () => {
  //   const scrollTop = document.body.scrollTop 
  //   const scrollHeight = document.body.scrollHeight
  //   const clientHeight = document.body.clientHeight 
  //   if (scrollTop + clientHeight === scrollHeight) {
  //     setFetching(true)
  //     getMoreProduct(location, products?.length)
  //    setFetching(false)
  //     // window.alert('Reached bottom of the page. Make API call!');
  //   }
  // };

  // useEffect(() => {
  //   document.body.addEventListener('scroll', scrollHandler);
  //   return () => {
  //     document.body.removeEventListener('scroll', scrollHandler);
  //   };
  // }, [products]);


 const [search, setSearch] = useState({keyword:"", table:'products', column:'item_name', location, action:"search"});
 const searchRef = useRef();
 const {requestMaker} = useApi();



 const searchHandler = async (e)=> {
  e.preventDefault();
 if(search?.keyword === "") {
  setToast('Please enter a keyword');
  searchRef.current.focus()
  return;
 }
 setSearching(true);
 const res = await requestMaker('frontend/category', search);
 if(res?.status === 'successful' ){
  history.push('/search/item')
  setSearchResults(res?.data);
  setSearching(false);
  }
 }


 return ( <>
 <PageNav/>
 <div className="my-container my-col-10 off-1 down-10 xs-down-15">
  <div className="mother xs-down-5">
  <div><span className="fas fa-arrow-left pd-5 c-pointer" onClick={()=> {history.push('/')}}></span></div>
   <div className="mother bd-bottom xs-down-3 down-2">
    <span className="bold black-2 upper-case">Find Products </span>
   </div>
   <div className="mother xs-down-5 hidden-ls">
    <form onSubmit={(e)=> {searchHandler(e)}} >
    <div className="xs-11">
      <input type="search" className="search-input px13 monR" ref={searchRef} onChange={(e) => {setSearch(prev => ({...prev, keyword:e.target.value}) )}} placeholder='Search for items' />
    </div>
    <div className="xs-1 xs-down-"><span className="pd-10" onClick={(e)=> {searchHandler(e)}}><i className={ searching ? `fas fa-spinner fa-spin`:`fas fa-search`}></i></span></div>
  </form>
</div>
 <div className="mother down-1 xs-down-5"></div>
  <div className="mother down-1 hidden-xs">
    <Slider {...settings} >
    {allCat?.map((i, index) => (
    <div className="px10 my-col-2 c-pointer" key={index + 'iioio'} onClick={()=> {history.push(`/Allproducts/${i.main_Cat}/${i.cat_name}`)}}>
      <div className="my-col-11 down-1 pd-5 bg-faded  rad-10 upper-case">
       <div className="my-col-3"> <span className="profile-pics"><img src={path + i.images} alt="" /></span></div>
       <div className="my-col-8 down-6 bold"> <div> {i.cat_name.slice(0, 11)}</div></div>
      </div>
    </div>
      ))}      
    </Slider>
  </div>

  <div className="mother down-1 hidden-ls">
  <Slider {...settingss} >
      {allCat?.map((i, index) => (
       <div className="px10 xs-12 c-pointer" key={index + 'popo'} onClick={()=> {history.push(`/Allproducts/${i.main_Cat}/${i.cat_name}`)}}>
      <div className="xs-11 down-1 anchors bg-color-code-2 color-code-1 rad-10 unset-mg monR xs-px10">
       <div className="my-col-3 hidden-xs"> <span className="profile-pics"><img src={path + i.images} alt="" /></span></div>
       <div className="my-col-8 down-6"> <div> {i.cat_name?.slice(0,15)}</div></div>
      </div>
    </div>
      ))}      
    </Slider>
  </div>


  {searchResults === null &&  <div className="mother centered down-5 xs-down-10 monR faded">Search for something. Enter a keyword e.g Ginger tea</div>}


  <div className="mother down-2 xs-down-5 my-bottom-50">
  {searchResults?.length < 1 && <div className="mother down-5 centered px20">
    <span className="bold">Sorry, no Items found !</span>
    <div className="mother down-2 xs-down-5"><span className="pd-10 white bg-color-code-1 c-pointer rad-10 px13" onClick={()=> {history.push(`/`)}}>See all items</span></div>
</div> }
  {searchResults?.map((i, index) => (
       <div className="my-col-3 xs-6 xs-down-2" key={'pro' + index} onClick={()=> {setViews('view' + i.id)}}>
       <div className="my-col-12 my-bottom-50" key={i.id}>
       <div className="product-card">
        <div className="scarf"></div>
        <div className="product-card-img-con"><img src={path + i.image_1} alt="" /></div>
        <div className="my-container-2 down-5">
         <div className="mother">
          <div className="my-col-12 xs-12 xs-down-8">
           <div className="px10 faded upper-case">{i.category}</div>
           <span className="px13 xs-px10  bold upper-case hidden-ls">{i.item_name?.slice(0, 20)+'...'}</span>
           <span className="px13 xs-px10  bold upper-case hidden-xs">{i.item_name?.slice(0, 25)+'...'}</span>
          <div className="mother down-1 px10">
           {/* <span>{i.ratings} <i className=" px12 fas fa-star color-code-1"></i></span> */}
          </div>
         </div>
         </div>
         <div className="mother down-5 xs-down-2">
          <div className="my-col-12 xs-12" >
          <div className="mother top-2 xs-down-2"> <span className=" color-code-1 xs-px13 px13  bold mother">₦ {formatNumber(i.price)}</span></div>
           <div className="mother xs-top-1"><s className="xs-px10 px13 bold">₦ {formatNumber(i.slash_price)}</s></div>
          </div>
          <div className="my-col-12 xs-12 down-4"  onClick={(e)=> {addToCart(e, i)}}><span className="my-btn-sm bd-code-1 color-code-1 px13 xs-px10 centered mother">Add to Cart <i className="fas fa-arrow-right"></i> </span></div>
         </div>
        </div>
       </div>
      </div>
       </div>
      ))}
      
     
  </div>
  
{fetching &&   <div className="p-fixed mother my-bottom-50 px10 bold centered">......<i className="fas fa-spinner fa-spin"></i> <span className="mg-5">Loading more......</span></div>}
 </div>

 {searchResults?.map((i, index) => (
   <div  key={index + 'vwo'}>
    {views === 'view' + i.id && <ViewProducts setViews={setViews} i={i}/>}
   </div>
  ))}


 </div>
 </> );
}
 
export default SearchItem;
