import {createContext, useEffect, useState} from 'react';
import useApi from '../hooks/useApi';

export const AuthContext = createContext();

const AuthContextProvider = (props) => {
 const token = localStorage.getItem('lm-user');
 const {requestMaker} = useApi();
 const [currentUser, setCurrentUsers] = useState(null)
 
 const logoutOut = () => {
  localStorage.removeItem('lm-user');
  setCurrentUsers(null);
 }

 const getUser = async(Token) => {
  const params = {token:Token}
  const result = await requestMaker('user/getuser', params);
  if(result?.status === 'successful'){
   setCurrentUsers(result?.data);
   return;
  } 
  if(result?.status === 'SESSION EXPIRED'){
    logoutOut()
    return;
   }
 }

 useEffect(()=> {
  if(token){
   getUser(token);
  }
 }, [token])
   
 return ( 
  <AuthContext.Provider value={{
   getUser,
   currentUser,
   logoutOut,
   token
  }}>
   {props.children}
  </AuthContext.Provider>
 );
}
 
export default AuthContextProvider;
