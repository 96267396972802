import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import PageNav from "../pagenav";
import useApi from "../../hooks/useApi";
import useUtils from "../../utils/useutils";
import { useEffect, useRef, useState } from "react";
import Snowfall from "react-snowfall";
import ResetPassword from "../resetpassword";

const AgentLogin = () => {
 const history = useHistory();
 const token = localStorage.getItem('l-agent');
 const {requestMaker} = useApi();
 const {setToast, isPending, seePassword} = useUtils();
 const [params, setParams] = useState({email:"",  password:""});
 const emailRef = useRef()
 const passwordRef = useRef();

 useEffect(()=> {
  document.title = 'L-Mobile ~ Agent Login'
  // document.body.style.background = '#f3f3f3';
  return(()=> {document.body.style.backgroundColor='white'})
 })

 useEffect(()=> {
  if(token){
   history.push('/agents/dashboard')
  }
 }, [token])

const submitHandler = async(e) => {
  e.preventDefault()
  if(params?.email.trim() === '' ){
   emailRef.current.focus();
   setToast('Please enter email');
   return;
  }
  if(!params?.email.includes('@')){
   emailRef.current.focus();
   setToast('Please enter a valid email');
   return;
  }
  if(params?.password.trim() === '' ){
   passwordRef.current.focus();
   setToast('Please enter password');
   return;
  }
  isPending('sub_btn', true);
  const res = await requestMaker('agents/login', params);
  if(res?.status === 'successful'){
   localStorage.setItem('l-agent', res?.data);
   history.push('/agents/dashboard');
  }else {
   isPending('sub_btn', 'Login');
  }
 }

 const [re, setRe] = useState(false)

 return ( <>
  {re &&  <ResetPassword action={'referer'} btn={setRe} />}
  <PageNav/>
 <div className="mother down-8 xs-down-10vh my-fade-in">
  {/* <div className="my-col-4 off-4 my-container"><i className="pd-5 fas fa-arrow-left" onClick={()=> {history.push('/')}}></i></div> */}
   <div className="my-col-6 off-3 rad-10 xs-container bg-faded down-1 my-bottom-20 xs-down-10">
    <Snowfall/>
    <div className="my-col-10 off-1 xs-10 xs-off-1 down-3 xs-down-7">
      <div className="img-log"></div>
     <div className="mother bold px20 xs-px20 monR xs-down-2 centered">Refer and Earn</div>
     <div className="mother xs-down-1 monR centered"><span className="px12">Sell Products. Earn Smart</span></div>
     <form action="" className="mother down-2 xs-down-5">
      <div className="my-col-10 off-1 down-2">
       <div className="my-col-12 down-1 xs-12 xs-down-1"><input placeholder="Email address" ref={emailRef} type="text" onChange={(e)=> {setParams(prev => ({...prev, email:e.target.value}))}} className="input px13 bg-white" /></div>
      </div>
      <div className="my-col-10 off-1 down-1 xs-12 xs-down-4">
       <div className="my-col-12 xs-12 down-1"><input id="passwerd-we" placeholder="Password" ref={passwordRef} type="password" onChange={(e)=> {setParams(prev => ({...prev, password:e.target.value}))}} className="input bg-white px13" /></div>
       <div className="my-col-1 off-10 xs-off-10 xs-down-2 xs-1 down-3 p-abs">
        <span className="fl-right"> <i className="mg-5 fas fa-eye px13 pd-5 c-pointer faded" id="iconss" onClick={()=> {seePassword('passwerd-we', 'iconss')}}></i></span>
       </div>
        <div className="mother down-1"><button className="my-btn-sm down-2 mother xs-down-5 bg-color-code-1 white px13 monR" id="sub_btn" onClick={(e)=> {submitHandler(e)}}>Login</button></div>
      </div>
      <div className="mother centered down-4 xs-down-5"><span className="pd-5 rad-10 monR c-pointer color-code-1 px13" onClick={()=> {setRe(true)}}>Forgotten Password ?</span></div>
     </form>
   <div className="mother centered down-1 xs-down-3 monR"><span className="pd-5 color-code-1 px13" onClick={()=> {history.push('/agents_signUp')}}>I haven't registered <i className="fas fa-angle-right"></i> </span></div>
    </div>
   </div>
 </div>
  
  </> );
}
 
export default AgentLogin;