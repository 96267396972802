import { useEffect } from "react";
import SellerSideBar from "./sellersidebar";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useContext } from "react";
import { SellerContext } from "../../context/sellercontext";
import { useState } from "react";
import AddBank from "./addbank";

const Settings = ({setSettings}) => {
const {currentUser, logoutOut} = useContext(SellerContext) ;
const [out, setOut] = useState(false);
const [bank, setBank] = useState(false)
  const history = useHistory();
  useEffect(()=> {
    document.title = 'L-Mobile ~ seller dashboard'
    return(()=> {document.body.style.backgroundColor='white'})
   })
   const [changeBank, setChangeBank] = useState(false);
  
  return ( <>
   <div className="modal-v off-1 bg-white in-ov-scroll my-bottom-50">
   {changeBank && <AddBank setChangeBank={setChangeBank}/>}
{out &&  <div className="my-modal my-fade-in">
  <div className="my-col-4 off-4 rad-10 down-10 xs-down-20vh my-container bg-white my-bottom-50">
   <div className="my-col-10 off-1 xs-10 xs-off-1 xs-down-5 down-3">
    <div className="mother bd-bottom-bold"><span className="px13 bold">Logout</span></div>
    <div className="mother down-5 xs-down-5">
     <span>Are you sure you want to logout ?</span>
    </div>
    <div className="down-5 mother righ xs-down-8">
     <span className="pd-10 rad-10 bg-color-code-2 color-code-1 px13" onClick={logoutOut}>Yes</span>
     <span className="pd-10 rad-10 bg-color-code-1 white mg-10 px13" onClick={()=> {setOut(false)}}>Cancel</span>
    </div>
   </div>
  </div>
 </div> }
   <div className="v-modal bg-white">
    <div className="my-col-10 off-1 down-3 my-container monR xs-down-10">
     <div className="my-col-10 off-1 xs-12">
       <div className="bd-bottom-bold">
        <span className="bold pd-10 fas fa-angle-left c-pointer" onClick={()=> {setSettings(false)}}></span>
        <span className="bold">Settings</span>
      </div>
       <div className="my-col-12 pd-10 bd-bottom c-pointer down-3 xs-12 xs-down-4" onClick={()=> {setChangeBank(true)}}>
        <span className="px13 black"><i className="pd-5 fas fa-bank"></i>Bank details</span>
       </div>
       <div className="my-col-12 pd-10 c-pointer down-10 xs-12 xs-down-10vh">
        <div className="my-col-4 centered pd-10 bg-color-code-1 white px13" onClick={()=> {setOut(true)}}>Logout</div>
       </div>
     </div>
    </div>
   </div>
   </div>
  </> );
}
 
export default Settings;