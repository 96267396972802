import { useContext, useEffect, useRef, useState } from "react";
import useUtils from "../../utils/useutils";
import useApi from "../../hooks/useApi";
import { Zoom } from "react-reveal";
import { SellerContext } from "../../context/sellercontext";

const SellerWithdrawal = ({setWithdraw, balance}) => {
  const {currentUser, setSeller, getPendingWithdrawal, token} = useContext(SellerContext)
  const {formatNumber, setToast, copyTextToClipboard} = useUtils();
  const {requestMaker} = useApi()

  const [params, setParams]= useState({
    withdrawal_amount:"",
    balance,
    account_number:currentUser?.account_number,
    bank_code:currentUser?.bank_code,
    token,
    name:"",
    email:"",
    action:"req_withdrawal_seller"
  }) 


  useEffect(() => {
   setParams((prev) => ({...prev, name:currentUser?.name, email:currentUser?.email, account_number:currentUser?.account_number, bank_code:currentUser?.bank_code}))
  }, [currentUser])

  const [nofunds, setNofunds] = useState(null);
  const [pending, setPending] = useState(null);
  const [pen, setPen] = useState(null);
  const amountRef = useRef();

  const submitRequest = async (e) => {
    e.preventDefault();
   if(parseInt(params?.withdrawal_amount) < 1000){
      setToast('cannot withdraw less than NGN 1,000');
      amountRef.current.focus();
      return
    }
    if(params?.withdrawal_amount === ''){
      setToast('Please enter an amount to withdraw');
      amountRef.current.focus();
      return
    }
    if(params?.withdrawal_amount.includes('-')){
      setToast('Invalid Amount');
      amountRef.current.focus();
      return
    }
    if(parseInt(params?.withdrawal_amount) > parseInt(balance)){
      setNofunds(true);
      return
    }
    setPending(true);
    const res = await requestMaker('sellers/withdrawalreq', params);
    if(res?.status === 'successful'){
      await getPendingWithdrawal(token, 'seller', currentUser?.email); 
      await setSeller(token, false)
      setPending(false)
      setWithdraw(false)
      setToast('Request Was Sent Successfully')
    }else {
      setPending(false)
    }
 }

 const copyLink = async(e, text)=> {
  e.stopPropagation();
  copyTextToClipboard(text)
 }


return (<>
   <div className="modal-v off-1 bg-faded de-in">
   {nofunds &&
     <div className="my-modal bg-blur" onClick={()=>{setNofunds(null)}}>
      <Zoom>
      <div  className="xs-10 xs-off-1 my-col-4 off-4 my-bottom-50 down-10 z-400 down-10 xs-down-30vh rad-10 b-shadow p-fixed bg-white" onClick={(e)=>{e.stopPropagation()}}>
      <div className="xs-10 xs-off-1 xs-down-10 centered my-col-10 off-1 down-7">
        <div className="mother xs-donw-10"><span className="bold color-code-1"><i className="fas fa-times px20"></i></span></div>
        <div className="mother xs-donw-10 down-3"><span className="bold">Insufficient Funds !</span></div>
        <div className="mother down-1 xs-dwon-5"><span className="px13 faded">Make more sales to top up</span></div>
        {/* <div className="mother down-5 xs-down-5"><span className="px13 rad-10 c-pointer pd-10 white bg-color-code-1 px13" onClick={(e) => {copyLink(e, `https://lmobile.com.ng/signUp/${currentUser?.ref_code}`)}}>Copy Referal Link</span></div> */}
      </div>
    </div>
      </Zoom>
     </div>
   }
   {pen && <div onClick={()=>{setPen(null)}} className="xs-10 xs-off-1 my-col-4 off-4 my-bottom-50 down-10 z-400 down-10 xs-down-10vh rad-10 b-shadow p-fixed bg-white">
      <div className="xs-10 xs-off-1 xs-down-5 centered my-col-10 off-1 down-7">
        <div className="mother xs-donw-10"><span className="bold color-code-1"><i className="fas fa-times px20"></i></span></div>
        <div className="mother xs-donw-10 down-3"><span className="bold">Request Denied !</span></div>
      </div>
    </div>}

   <div className="my-col-10 off-1 down-1 xs-down-4 monR">
    <div className="xs-10 xs-off-1 xs-down-8 down-3 my-col-10 off-1 dow">
      <div className="mother bd-bottom"><span className="bold"> <i className="fas fa-angle-left pd-5" onClick={()=> {setWithdraw(false)}}></i> <span className="mg-10">Withdrawal</span></span></div>
      <div className="img-wit xs-down-15 down-5"></div>
      <div className="mother xs-down-5 down-3 centered">
        <span className="px13 faded">Available Balance</span>
        <div className="mother"><span className="bold px13">NGN {formatNumber(balance)}</span></div>
      </div>
      <div className="mother xs-down-4 down-1 centered"><span className="faded px13">Redrawable amount is between NGN 1,000.00 and NGN 50,000.00</span></div>
       <form onSubmit={(e) => {submitRequest(e)}} >
       <div className="mother xs-down-4 down-4">
        <div className="my-col-6 off-3 xs-12">
          <div><span className="px13 bold">Amount<span className="mg-10 faded">(NGN)</span> </span></div>
          <input type="number" ref={amountRef} placeholder="NGN 1,000 - NGN 50,000" onChange={(e) => {setParams(prev => ({...prev, withdrawal_amount:e.target.value}))}} min={1000} className="input bg-faded px13 xs-down-2 down-2"/>
        </div>
      </div>
      <div className="mother xs-down-4 down-1">
        <div className="my-col-6 off-3 xs-12">
          <div><span className="px13 bold">Payment Method</span></div>
          <input type="text" value={'Bank transfer'} readOnly className="input bg-faded px13 xs-down-2"/>
          <div className="mother xs-down-10 down-7">
            {pending ? <button className="my-btn-sm mother bg-color-code-1 white px13 centered bold c-pointer" disabled >Processing...</button> : <button className="my-btn-sm mother bg-color-code-1 white px13 centered bold c-pointer">Confirm</button> }
          </div>
        </div>
      </div>
     </form>
    </div>
   </div>
   </div>
  </>  );
}
 
export default SellerWithdrawal;