import { useContext, useState } from 'react';
import {AiOutlineLoading, AiOutlineLogout, AiOutlineSearch, AiOutlineSetting, AiOutlineShop, AiOutlineShopping, AiOutlineShoppingCart, AiOutlineUser} from 'react-icons/ai'
import Dropdowns from './dropdown';
import { CategoryContext } from '../context/categorycontext';
import Locations from './locations';
import Cart from './cart';
import { CartContext } from '../context/cartcontext';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Accounts from './accounts';
import useUtils from '../utils/useutils';
import { useRef } from 'react';
import useApi from '../hooks/useApi';
import GroceryCat from './grocerycategory';
import { Zoom } from 'react-reveal';
import { AuthContext } from '../context/authcontex';

const Navbar = ({focus}) => {

 const [sidenav, setSideNav] = useState(false);
 const {cart} = useContext(CartContext)
 const {location, state, setSearchResults, setLocations} = useContext(CategoryContext);
 const [openCart, setOpenCart] = useState(false);
 const history = useHistory();
 const {setToast} = useUtils();
 const [search, setSearch] = useState({keyword:"", table:'products', column:'item_name', location, action:"search"});
 const [searching, setSearching] = useState(false);
 const searchRef = useRef();
 const {requestMaker} = useApi()
 const {currentUser, logoutOut} = useContext(AuthContext);



 const searchHandler = async (e)=> {
  e.preventDefault();
 if(search?.keyword === "") {
  setToast('Please enter a keyword');
  searchRef.current.focus()
  return;
 }

 setSearching(true);
 const res = await requestMaker('frontend/category', search);
 if(res?.status === 'successful' ){
  history.push('/search/item')
  setSearchResults(res?.data);
  setSearching(false);
  }else {
    setSearching(false);
  }
 }


 return ( <>
 {openCart && <Cart setOpenCart={setOpenCart}/>}
 {location === null &&  <div className='my-modal bg-blur'>
  <Zoom>
  <div className='my-col-6 rad-10 off-3 xs-container centered xs-down-20vh down-10 my-bottom-50 bg-white'>
   <div className='my-col-10 off-1 xs-10 xs-off-1 xs-down-5 down-5 '>
    {/* <div className='mother bd-bottom' >Welcome To L-Mobile ✨ </div> */}
     <div className='mother down-3'><div className='img-location'></div></div>
    <div className='mother down-2'><span className='px20 bold black-2 monR'>Welcome To PVMA<span className='color-code-1'>Store</span></span> <div className='mother down-3 xs-down-5 px13  black bold monR'>Our Services Are Based On Your Location</div> </div>
    <div className='mother down-1 xs-down-2'><span className='px13 xs-px12 '><span className='faded monR'>We offer delievery only to the listed locations below.</span> </span></div>
    <div className='my-col-6 off-3 xs-10 xs-off-1 rad-10 down-4 xs-down-5'>
     <select name="" className='input rad-10  centered px10 upper-case' id="" onChange={(e)=> {setLocations(e.target.value)}}>
      <option value={null}>Choose Location</option>
      {state?.map((i, index) => (
       <option value={i.value}>{i.value}</option>
      ))}
     </select>
     {/* <div className='mother xs-down-5'><span className='pd-5 color-code-1 xs-px10'>Can't find my Location </span></div> */}
    </div>
   </div>
  </div>
  </Zoom>
 </div>}

{currentUser && <div className='dash-code-bar hidden-xs'>
 <div className=' down-100'>
  <div className='mother down-100'></div>
  <div className='mother xs-3 down-60 '><span className={`anchors rad-10 ${focus == 'home'? 'color-code-1' : 'faded'}`} onClick={() => {history.push('/')}} title={currentUser?.name}><AiOutlineUser/></span></div>
    <div className='mother xs-3 down-60'><span className={`anchors rad-10 ${focus == 'orders'? 'color-code-1' : 'faded'}`} title='Shop History' onClick={() => {history.push('/orders')}}><AiOutlineShopping/></span></div>
      <div className='mother xs-3 down-60'><span className={`anchors rad-10 ${focus == 'settigs'? 'color-code-1' : 'faded'}`} onClick={() => {history.push('/settings')}} title='Settings' ><AiOutlineSetting/></span></div>
      <div className='mother xs-3  down-60'><span className={`anchors rad-10 ${focus == ''? 'color-code-1' : 'faded'}`} title='Logout' onClick={logoutOut}><AiOutlineLogout/></span></div>
    </div>
  </div>}

{currentUser && <div className='dash-codes-bar hidden-ls'>
    <div className='xs-12'>
      <div className='xs-3 '><span  className={`anchors rad-10 ${focus == 'home'? 'color-code-1' : 'faded'}`} onClick={() => {history.push('/')}} title={currentUser?.name}><AiOutlineUser/></span></div>
      <div className='xs-3'><span className={`anchors rad-10 ${focus == 'orders'? 'color-code-1' : 'faded'}`} title='Shop History' onClick={() => {history.push('/orders')}}><AiOutlineShopping/></span></div>
      <div className='xs-3'><span className={`anchors rad-10 ${focus == 'settigs'? 'color-code-1' : 'faded'}`} onClick={() => {history.push('/settings')}} title='Settings' ><AiOutlineSetting/></span></div>
      <div className='xs-3 '><span className={`anchors rad-10 ${focus == '' ? 'color-code-1' : 'faded'}`} title='Logout' onClick={logoutOut}><AiOutlineLogout/></span></div>
    </div>
  </div>}
 


 <div className="new-nav hidden-xs">
 <div className="my-col-10 off-1 down-1">
    {/* <div className='mother bd-bottom' >Welcome To L-Mobile ✨ </div> */}
        <div className="my-col-1">
          {/* //lgo */}
          <h1 className="color-code-1"><b className="black-2">Planted</b><b>Value</b> </h1>
          <div className="top-15 bold"><span className="px10 black-2">products...</span></div>
       </div>
        <div className="my-col-7 off-1  px13 centered">
          <span className="anchors new-active " onClick={() => {history.push('/')}}>Home</span>
           
          <div className='new-ex-con left'>
          <span className="anchors fade-2" >Products</span>
         <div className='ddrop-down wid-100 px10'>
          <GroceryCat/>
         </div>
         </div>

         
          <div className='new-ex-con'>
          <span className="anchors fade-2" >Locations <i className="fas fa-angle-down"></i></span>
          <div className='ddrop-down bg-faded'>
            <div className='my-col-10 off-1 black-2'>
            {state.map((i, index) => (
              <div className={`ex-links px13 down-3 hover ${location === i.value && 'active-loc'} `} onClick={()=> {setLocations(i.value)}} key={'loc' + index}>{i.value}</div>
             ))}
            </div>
          </div>
          </div>

          <div className='new-ex-con  '>
          <span className="anchors fade-2" ><i className='fas fa-search'></i></span>
         <div className='h-100 ddrop-down for-search'>
          <div className='my-mother down-2 faded'>
          <form onSubmit={(e)=> {searchHandler(e)}}>
            <input type="search" ref={searchRef}  className='search-input' onChange={(e) => {setSearch(prev => ({...prev, keyword:e.target.value}) )}} placeholder='Search for items' />
             {/* {searching? <button><AiOutlineLoading className='fa fa-spin'/></button> :  <button><AiOutlineSearch/></button>} */}
           </form>
           {/* <input type="text" className='search-input' placeholder='Search items' /> */}
          </div>
         </div>
         </div>

          
        </div>
        <div className="my-col-3 px13 right">
          <span className="anchors black" onClick={()=> {setOpenCart(true)}}>
            <AiOutlineShoppingCart className='px20 black-2'/>
            {cart?.length > 0 && <sup className="notification-point bg-color-code-1"></sup>}
          </span>
        </div>
      </div>
     </div>
  <div className='hidden-ls new-mobile-nav bg-white'>
    <div className='xs-container'>
    <div className="p-abs xs-off-2 xs-down-2">
        <h1 className="color-code-1"><b className="black-2">Planted</b><b>Value</b> </h1>
          <div className="xs-top-15 bold"><span className="px10 black-2">products...</span></div>
       </div>
      <div className='xs-6 left'><i className='pd-10 bg-  black-2 bold px20 fas fa-bars' onClick={()=> {setSideNav(true)}}></i></div>
      <div className='xs-6 right xs-down-2'>
        <span className='pd-10 bg- xs-px20' onClick={()=> {history.push('/products/Groceries')}}><AiOutlineSearch/></span>
        <span className='pd-5 xs-px20 mg-10 color-code-1' onClick={()=> {setOpenCart(true)}}><AiOutlineShoppingCart/>{cart?.length > 0 &&<sup className="notification-point bg-color-code-1"></sup>}</span>
        
      </div>
    </div>
  </div>


  {sidenav && <>
   <div className='mobile-side-nav-bar-modal hidden-ls'  onClick={()=> {setSideNav(false)}}></div>
  <div className='mobile-side-nav-bar hidden-ls'>
   <div className='xs-10 xs-off-1 xs-down-5vh in-ov-scroll'>
   <span className="anchors unset-mg  bg-faded px13" onClick={() => {history.push('/')}}>Home</span>
    <div className='xs-12 bd-bottom-bold xs-down-10'><span className='xs-px13 upper-case bold'>Main Menu</span></div>
   <div className='mother'>
   <div className='xs-11 xs-down-10 bd-bottom'>
     <Dropdowns title={'Groceries'}/>
    </div>
   </div>
   <div className='mother xs-down-30'>
   <div className='xs-12 bd-bottom-bold'><span className='xs-px13 upper-case bold'>General</span></div>
   <div className='xs-12 xs-down-10'>
    <div className='xs-10 xs-off-1'>
     <span className='mg-10 px13'>
      <Locations title="Locations" />
     </span>
    </div>
    
    {!currentUser && <div className='xs-10 xs-off-1'>
    <span className='mg-10 px13'>
      <Accounts title="Accounts" />
     </span>
    </div>}
    
    {/* <div className='xs-10 xs-off-1 bd-bottom xs-down-10'>
     <span className='px13 upper-case'>About</span>
    </div> */}
    <div className='xs-10 xs-off-1 bd-bottom xs-down-10'>
     <span className='px10 upper-case'>Terms of Use</span>
    </div>
   </div>
   </div>
   </div>
  </div>
  </>}
 </>);
}
 
export default Navbar;