import { useContext } from "react";
import useApi from "../../hooks/useApi";
import useUtils from "../../utils/useutils";
import { DeliverContext } from "../../context/delivercontext";
import { useState } from "react";
import { useRef } from "react";

const ViewOrder = ({setDetails, i, from}) => {
  const {requestMaker} = useApi();
  const {isPending, setToast, formatNigerianPhoneNumber, formatNumber} = useUtils();
  const {currentUser, token, getOrders, getTasks} = useContext(DeliverContext);
  const [confirmParams, setConfirmParams] = useState(i);
  const [code, setCode] = useState("");
  const codeRef = useRef()


  const addToTaskHandler = async()=> {
   const params = {id:i.id, token, action:"add_to_tasks", dl_email:currentUser?.email, dl_name:currentUser?.firstname + " " + currentUser?.lastname, dl_phone:currentUser?.phone};
   isPending('add-task', true)
   const res = await requestMaker('delivery/current', params);
   if(res?.status === 'successful'){
    await getOrders(token);
    setToast('Task Added');
   }
  }

  const whatsapp =()=> {
    window.open(`https://api.whatsapp.com/send?phone=${formatNigerianPhoneNumber(i.phone)}`)
   }

   const confirmDeliveryHandler = async ()  => {
    if(code === ""){
      setToast("please Enter code");
      codeRef.current.focus();
      return;
    }
    if(code !== i.delivery_code){
      setToast('Invalid delivery code');
      return;
    }
    isPending('confirm-d', true)
    confirmParams['token'] = token;
    const res = await requestMaker('delivery/confirm', confirmParams);
    if(res?.status === 'successful'){
     await getTasks(token);
     setToast('Delivery Confirmed !');
    }else {
      isPending('confirm-d', "Confirm delivery")
    }
   }


  return ( <>
  <div className="my-modal bg-blur">
    <div className="my-col-4 off-4 my-container xs-down-20 bg-white down-3 my-bottom-20">
      <div className="my-col-10 off-1 down-5 xs-10 xs-off-1 xs-down-10">
        <div className="bd-bottom-bold mother px13 bold">Order {i.order_id}</div>
        <div className="my-col-5 down-3 xs-12 xs-down-5">
          <div><span className="px12 faded">Owner:</span></div>
          <div className="mother xs-top-2 top-1"><span className="px12 bold">{i.name}</span></div>
        </div>
        <div className="my-col-4 down-3 xs-12 xs-down-5">
          <div><span className="px12 faded">Phone number:</span></div>
          <div className="mother xs-top-2 top-1"><span className="px12 bold">{i.phone}</span></div>
        </div>
          <div className="my-col-3 right xs-12 down-2 xs-down-5">
          <div><span className="px12 faded">Contact Buyer:</span></div>
            <a href={`tel:${i.phone}`}><i className="pd-10 bg-faded color-code-1 px13 fas fa-phone rad-10"></i></a>
            <a onClick={whatsapp} className="mg-10" ><i className="pd-10 bg-faded color-code-1 px13 fab fa-whatsapp rad-10"></i></a>
          </div>
        <div className="my-col-12 down-3 xs-12 xs-down-5">
          <div><span className="px13 faded">Address:</span></div>
          <div className="mother xs-top-2 top- justify"><span className="px12 bold">{i.address} </span></div>
        </div>
        <div className="my-col-12 xs-12 xs-down-5 down-2">
          <div><span className="px13 faded">Items:</span></div>
          <div className="mother top- in-sc-order">
          {JSON.parse(i.items).map((x, indexs) => (
          <div className="my-col-10 xs-12" key={'iyw' + indexs}>
           <span className="px10 ">{indexs + 1}. </span><span className="px9 xs-px10 bold">{x.item_name}</span>
           <div className="mother top-3 xs-top-3">
            <span className="px10 faded">Qty: <b>{x.quantity}</b></span>
            <span className="px10 faded mg-5">(<b>NGN {formatNumber(x.price)}</b>)</span>
          </div>
          </div>
        ))} 
          </div>
        </div>
       {from == 'order' &&   <div className="mother down-5 xs-down-5">
          <span className="my-btn-sm bg-color-code-1 white px13 c-pointer" id="add-task" onClick={()=> {addToTaskHandler()}}>Add to Task</span>
          <span className="my-btn-sm bg-color-code-2 color-code-1 px13 mg-10 c-pointer" onClick={()=> {setDetails(null)}}>Cancle</span>
       </div>}
      {from == 'tasks' && <>
      <div className="mother down-3 xs-down-5">
        <div className="my-col-7 xs-6"><input ref={codeRef} type="text" value={code} onChange={(e) => {setCode(e.target.value)}} className="input px13" placeholder="Delivery Code" /></div>
        <div className="my-col-4 mg-10 xs-down-2 xs-5 down-2"><span id="confirm-d" className="pd-10 bg-color-code-1 white px13 xs-px10" onClick={()=> {confirmDeliveryHandler()}} >Confirm delivery </span></div>
       </div>

       <div className="mother xs-down-20 down-5"> <span className="pd-10 fl-right bg-color-code-2 color-code-1 px13 mg-10 c-pointer" onClick={()=> {setDetails(null)}}>Cancle</span></div>
      </>}

      {from == 'History' &&  <div className="mother xs-down-20 down-5"> <span className="pd-10 fl-right bg-color-code-2 color-code-1 px13 mg-10 c-pointer" onClick={()=> {setDetails(null)}}>Cancle</span></div>}
      </div>
    </div>
  </div>
  
  </> );
}
export default ViewOrder;