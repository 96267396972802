import {createContext, useEffect, useState} from 'react';
import useApi from '../hooks/useApi';

export const CategoryContext = createContext();

const CategoryContextProvider = (props) => {
//  const path = 'http://localhost/api.admin.Lmobile/images/'
 const path = 'https://pvmabeauty.000webhostapp.com/images/'
 const loc = localStorage.getItem('location');
 const {requestMaker} = useApi();
 const [category, setCategory] = useState([]);
 const [values_under_main_cat, setValues_under_main_cat] = useState([])
 const [allCat, setAllCat] = useState([])
 const [products, setProducts] = useState(null)
 const [error, seterror] = useState(false);
 const [state, setState] = useState([]);
 const [location, setLocation] = useState(loc);
 const [s, setS] = useState(false);
 const [searchResults, setSearchResults] = useState(null);

 const getAllCategory = async(Token) => {
  const params = {token:Token, action:'get_cat'}
  const result = await requestMaker('frontend/category', params);
  if(result?.status === 'successful'){
   setCategory(result?.data);
  }else {
   seterror(true);
  }
 }

 const getAllUnderMainCategory = async(main_cat) => {
  const params = {action:'get_under_main_cat', main_cat:main_cat }
  const result = await requestMaker('frontend/category', params);
  if(result?.status === 'successful'){
   setValues_under_main_cat(result?.data);
  }
 }

 const getAllSubCat = async() => {
  const params = {action:'get_all_cat'}
  const result = await requestMaker('frontend/category', params);
  if(result?.status === 'successful'){
   setAllCat(result?.data);
  }
 }

  const getAllProduct = async(locs, offset) => {
  const params = {action:'get_all_product', location:locs, offset}
  const result = await requestMaker('frontend/category', params);
  if(result?.status === 'successful'){
   setProducts(result?.data);
  }
 }

 const getMoreProduct = async(locs, offset) => {
  const params = {action:'get_all_product', location:locs, offset}
  const result = await requestMaker('frontend/category', params);
  if(result?.status === 'successful'){
   setProducts(prev => ([...prev, ...result?.data]));
  }
 }


 const getAllLocations = async () => {
  const params = {action:"get_locations"};
  const result = await requestMaker('frontend/category', params);
  if(result?.status === 'successful'){
   setState(result?.data);
  }
 }

 const setLocations =(x)=> {
  localStorage.setItem('location', x);
  setLocation(x)
 }


 useEffect(()=> {
  getAllSubCat();
  getAllProduct(location, 0);
 }, [location])


 useEffect(() => {
  getAllLocations();
 }, [])
 
 

   
 return ( 
  <CategoryContext.Provider value={{
   getAllCategory,
   getAllUnderMainCategory,
   category,
   values_under_main_cat,
   error,
   allCat,
   getAllSubCat,
   getAllProduct,
   products,
   setProducts,
   state, 
   setLocations,
   location,
   getMoreProduct,
   setState,
   searchResults, 
   setSearchResults,
   path,
   s,
   setS
  }}>
   {props.children}
  </CategoryContext.Provider>
 );
}
 
export default CategoryContextProvider;
