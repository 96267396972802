import { AiOutlineArrowDown, AiOutlineArrowsAlt, AiOutlineCarryOut, AiOutlineShoppingCart, AiOutlineSortAscending } from "react-icons/ai";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import CatSkeleton from "../components/skeleton/catSeleton";
import { useContext, useEffect, useState } from "react";
import ViewProducts from "./viewproducts";
import useApi from "../hooks/useApi";
import { CategoryContext } from "../context/categorycontext";
import useUtils from "../utils/useutils";
import { CartContext } from "../context/cartcontext";
import PageNav from "../components/pagenav";


const Product = () => {
const {under_main_cat, sub_cat} = useParams();
const [results, setResults] = useState([]);
const [views, setViews] = useState(null);
const {addToCart} = useContext(CartContext)
const {location, path} = useContext(CategoryContext);
const {requestMaker} = useApi();
const history = useHistory();
const {formatNumber} = useUtils();

const getProductshandler = async ()=>{
 const params = {action:'sort', category:under_main_cat, sub_cat:sub_cat, location:location}
 const res = await requestMaker('frontend/category', params);
 if(res?.status === 'successful'){
  setResults(res?.data);
 }else {
 }
}

useEffect(() => {
 if(under_main_cat && sub_cat ){
  getProductshandler();
 }else {
  history.push('/')
 }
}, [under_main_cat, sub_cat, location])




 return ( <>
 <PageNav/>
 <div className="my-container my-col-10 off-1 down-9 xs-down-15 my-bottom-50">
  <div className="mother xs-down-5 bd-bottom">
  <div><span className="fas fa-arrow-left pd-5 c-pointer" onClick={()=> {history.push(`/Allproducts/Groceries/${under_main_cat}`)}}></span></div>
   <div className="my-col-6 xs-8 down-2 xs-down-5 xs-px10">
    <span className="upper-case bold faded">{under_main_cat} 
    <i className="fas fa-angle-right mg-10"></i>
    <span className="mg-10 black">{sub_cat}</span>
   </span>
   </div>
   <div className="my-col-4 xs-4 off-2 down-1">
    {/* <input type="number" placeholder="price range" min={0} className="inputs bg-faded " /> */}
    {/* <button className="button bg-color-code-1 white"><i className="fas fa-search"></i></button> */}
   </div>
 </div>

 <div className="mother down-1 xs-down-10">

  {results?.length < 1 ?
  <>
  <span className="hidden-xs"><CatSkeleton slideToShow={5} /> </span>
  <span className="hidden-ls"><CatSkeleton slideToShow={3} /> </span>
  </> :
   <>
     {results?.map((i, index) => (
       <div className="my-col-3 xs-6 down-2 xs-down-5" key={'pros' + index}>
       <div className="my-col-12 xs-12 down-2">
       <div className="product-card" onClick={()=> {setViews('view' + i.id)}}>
        <div className="scarf"></div>
        <div className="product-card-img-con"><img src={path + i.image_1} alt="" /></div>
        <div className="my-container-2 down-5">
         <div className="mother">
          <div className="my-col-12 xs-12 xs-down-8">
           <div className="px10 faded upper-case">{i.sub_cat}</div>
           <span className="px13 xs-px10  bold upper-case hidden-ls">{i.item_name?.slice(0, 20)+'...'}</span>
           <span className="px13 xs-px10  bold upper-case hidden-xs">{i.item_name?.slice(0, 25)+'...'}</span>
          <div className="mother down-1 px10">
           {/* <span>{i.ratings} <i className=" px12 fas fa-star color-code-1"></i></span> */}
          </div>
         </div>

         <div className="mother down-5 xs-down-2">
          <div className="my-col-12 xs-12" >
          <div className="mother top-2 xs-down-2"> <span className=" color-code-1 xs-px13 px13  bold mother">₦ {formatNumber(i.price)}</span></div>
           <div className="mother xs-top-1"><s className="xs-px10 px13 bold">₦ {formatNumber(i.slash_price)}</s></div>
          </div>
          <div className="my-col-12 xs-12 down-4"  onClick={(e)=> {addToCart(e, i)}}><span className="my-btn-sm bd-code-1 color-code-1 px13 xs-px10 centered mother monR"> Add to Cart <i className="fas fa-angle-right"></i> </span></div>
         </div>
         </div>
        </div>
       </div>
      </div>
       </div>
      ))}
   </>

  }
 </div>
 {results?.map((i, index) => (
   <div  key={index + 'vwo'}>
    {views === 'view' + i.id && <ViewProducts setViews={setViews} i={i}/>}
   </div>
  ))}


 </div>
 </> );
}
 
export default Product;
