import {createContext, useEffect, useState} from 'react';
import useApi from '../hooks/useApi';
import useUtils from '../utils/useutils';

export const CartContext = createContext();

const CartContextProvider = (props) => {
 const loc = localStorage.getItem('LM_cart');
 const {setToast, deductPercentage} = useUtils()
 const [cart, setCart] = useState([]);
 const [totalPrice, setTotalPrice] = useState();
 const [weight, setWeight] = useState()
  const addToCart =(e, i)=> {
   e.stopPropagation();
   const item = {
    unitPrice:i.price, 
    unitWeight:i.weight, 
    item_name:i.item_name, 
    image:i.image_1, 
    price:i.price, 
    weight:i.weight, 
    quantity:1, 
    id:i.id,
    owner:i.owner,
    email:i.email,
    amount_to_owner:deductPercentage(i.price)
   };

   const chk = cart?.filter((x)=> x.id == i.id);
   if(chk?.length > 0){
    setToast('Item is already present in cart');
    return;
   }
   setCart(prev => [...prev, item]);
   setToast('Item sucessfully added to Cart');
  }

 const RemoveFromCart = (id)=> {
  const err = cart?.filter((i) => i.id !== id);
  setCart(err);
  setToast('Item Removed Successfully'); 
 }


 const addQuantity = (id, updatedQuantity, unitPrice, unitWeight) => {
  const newPrice = parseFloat(updatedQuantity) * parseFloat(unitPrice);
  const newWeight = parseFloat(updatedQuantity) * parseFloat(unitWeight)
  const updatedData = cart?.map((i) => {
    if (i.id === id) {
      return { ...i, quantity:updatedQuantity, amount_to_owner:deductPercentage(newPrice),  weight:newWeight.toFixed(2), price:newPrice};
    }
    return i;
  });

  setCart(updatedData);
};


useEffect(() => {
 localStorage.setItem('LM_cart', JSON.stringify(cart));
 const tP = cart?.reduce((total, item) => parseFloat(total) + parseFloat(item.price), 0);
 const tW = cart?.reduce((total, item) => parseFloat(total) + parseFloat(item.weight), 0);
 setTotalPrice(tP)   
 setWeight(tW)   
}, [cart])
 
  useEffect(() => {
   if(loc){
    const arr = JSON.parse(loc);
    setCart(arr)
   }
  }, [])

   
 return ( 
  <CartContext.Provider value={{
   cart,
    setCart,
    addToCart,
    RemoveFromCart,
    addQuantity,
    totalPrice,
    weight
  }}>
   {props.children}
  </CartContext.Provider>
 );
}
 
export default CartContextProvider;
