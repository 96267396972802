import { useContext, useEffect } from "react";
import SellerSideBar from "./sellersidebar";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useUtils from "../../utils/useutils";
import moment from "moment";
import { SellerContext } from "../../context/sellercontext";

const Notifications = ({setNotification}) => {
  const history = useHistory();
  const token = localStorage.getItem('l-seller');
  const {formatTransactionTime, formatNumber} = useUtils();
  const {transactions} = useContext(SellerContext)
  const now = moment();
  const formattedTime = now.format('YYYY-MM-DD HH:mm:ss');

  useEffect(()=> {
    document.title = 'L-Mobile ~ seller dashboard'
    document.body.style.background = '#f3f3f3';
    return(()=> {document.body.style.backgroundColor='white'})
  })

  return ( <>
   <div className="modal-v off-1 bg-white in-ov-scroll my-bottom-50">
    <div className="my-col-10 off-1 down-3 my-container xs-down-10">
     <div className="my-col-10 off-1 xs-12">
       <div className="bd-bottom-bold">
        <span className="bold pd-10 fas fa-angle-left c-pointer" onClick={()=> {setNotification(false)}}></span>
        <span className="bold">Notifications</span>
      </div>

      <div  className="my-col-12 xs-container down-3 xs-down-10  bd-bottom"><span className="bold px13 monR black-2">My Transactions</span></div>
    {transactions?.length > 0 ?
    <>
     {transactions?.map((i, index) => (
      <div className="mother xs-down-1 down-1">
       <div className="my-bottom-10 my-b-shadow mother bg-white rad-10 pd-10 px13 faded">
          <div className="my-container xs-down-4 down-3">
               <div className="my-col-1 top-2 xs-2">
                 <span className="profile-pics bg-faded color-code-1">
                  {i.type === 'credit'?  <i className="fas fa-arrow-down px13"></i> :  <i className="fas fa-arrow-up px13"></i>}
                 
                </span>
             </div>
               <div className="my-col-8 xs-7 top-2 down-1 xs-down-1">
                 <span className="black bold">{i.type === 'credit'? 'Account Credited Successfully' : 'Withdrawal Successful' }</span>
                 <div className="mother down-1 xs-down-4"><span className="px13 faded">{i.message} </span></div>
                 <div className="mother down-1 xs-down-4"><span className="px13 faded">{formatTransactionTime(i.trn, formattedTime)}</span></div>
             </div>
               <div className="my-col-3 right top-1 xs-3 xs-down-1"><span className={`bold ${i.type === 'credit'? 'color-code-1': 'red'}`}> {i.type==='credit'?"+":"-"}{formatNumber(i.amount)}</span></div>
             </div>
           </div>
         </div>
     ))}
    </>:
     <div className="mother centered xs-down-10 down-5"><span className="faded px13">You have no transactions</span></div>}
     </div>
    </div>
    <div className="mother my-bottom-50 hidden-ls"></div>
    <div className="mother my-bottom-50 hidden-ls"></div>
    <div className="mother my-bottom-50 hidden-ls"></div>
   </div>
  </> );
}
 
export default Notifications;