import { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useApi from "../hooks/useApi";
import useUtils from "../utils/useutils";
import { CategoryContext } from "../context/categorycontext";
import UserEmailVar from "../components/useremailvar";
import PageNav from "../components/pagenav";
import { useParams } from "react-router-dom";
import Snowfall from "react-snowfall";

const RefLink = () => {
 const history = useHistory();
 const {code} = useParams()
 const {requestMaker} = useApi();
 const {setToast, isPending} = useUtils();
 const [emailVar, setEmailVar] = useState(false);
 const nameRef = useRef()
 const {state} = useContext(CategoryContext)
 const emailRef = useRef()
 const phoneRef = useRef()
 const locationRef = useRef();
 const addressRef = useRef();
 const [params, setParams] = useState({name:"", email:"",  action:'signup', phone:"", location:"", address:"", referer:""});

 useEffect(()=> {
  document.title = 'L-Mobile ~ Sign Up'
  return(()=> {document.body.style.backgroundColor='white'; document.title = 'L-Mobile - Supermarket'})
 })

 useEffect(()=> {
  setParams((prev) => ({...prev, referer:code}))
 }, [code])


 const submitHandler = async(e) => {
  e.preventDefault()
  if(params?.email.trim() === '' ){
   emailRef.current.focus();
   setToast('Please enter email');
   return;
  }
  if(!params?.email.includes('@')){
   emailRef.current.focus();
   setToast('Please enter a valid email');
   return;
  }
  if(params?.name.trim() === '' ){
   nameRef.current.focus();
   setToast('Please enter fullname');
   return;
  }
  if(params?.phone.trim() === '' ){
   phoneRef.current.focus();
   setToast('Please enter phonenumber');
   return;
  }
  if(params?.location.trim() === '' ){
   locationRef.current.focus();
   setToast('Please choose a location');
   return;
  }
  if(params?.address.trim() === '' ){
   addressRef.current.focus();
   setToast('Please enter address');
   return;
  }
  isPending('sub_btn', true);
  const res = await requestMaker('user/signup', params);
  if(res?.status === 'successful'){
   setEmailVar(true);
  }else {
   isPending('sub_btn', 'Sign Up');
  }
 }

 
 return ( <>
 <PageNav/>
 {emailVar? <UserEmailVar params={params} /> : <>
 <div className="mother down-6 xs-down-15 my-fade-in">
  <div className="my-col-6 off-3 rad-10 my-bottom-20 bg-faded down-2 xs-container xs-down-15 xs-cenered  xs-p-abso">
  <div className='mother down-3 xs-down-5'><div className='img-location'></div></div>
 <Snowfall/>
   <div className="mother centered "><span className="bold monR">Register </span></div>
   <div className="centered down-1 my-col-10 off-1 xs-10 xs-off-1 xs-down-3"><span className="px13 monR">Register and start shopping amaizing items today.</span></div>
    <div className="my-col-10 off-1 down-4 xs-10 xs-off-1 xs-down-5">
     <form action="" className="my-col-10 off-1 xs-down-2 top-5">
      <div className="mother down-2">
       <div className="my-col-12 xs-12">
       <div><input ref={emailRef} type="text" placeholder="Email Address" onChange={(e)=> {setParams(prev => ({...prev, email:e.target.value}))}} className="input px13 bg-white" /></div>
       </div>
        
        <div className="my-mother">
         <div className="my-col-6 xs-6 xs-down-3 down-2">
          <div className="my-col-11 xs-11">
          <div><input ref={nameRef} placeholder="Full Name" onChange={(e)=> {setParams(prev => ({...prev, name:e.target.value}))}} type="text" className="input px13 bg-white" /></div>
           </div>
          </div>
         <div className="my-col-6 xs-6 xs-down-3 down-2">
          <div className="my-col-12 xs-12">
            <div><input ref={phoneRef} type="text" placeholder="Phone Number" onChange={(e)=> {setParams(prev => ({...prev, phone:e.target.value}))}} className="input px13 bg-white" /></div>
           </div>
          </div>
         <div className="my-col-6 xs-6 xs-down-3 down-2">
          <div className="my-col-11 xs-11">
          <div><select ref={locationRef} onChange={(e)=> {setParams(prev => ({...prev, location:e.target.value}))}} className="input px13 bg-white upper-case">
       <option value="">select Location</option>
        {state?.map((i, index)=> (
         <option value={i.value} key={'kwe' + index}>{i.value}</option>
        ))}
          </select>
          </div>
        </div>
      </div>
      <div className="my-col-6 xs-6 xs-down-3 down-2">
        <div className="my-col-12 xs-12">
        <div><input ref={addressRef} type="text" placeholder="Delivery address" onChange={(e)=> {setParams(prev => ({...prev, address:e.target.value}))}} className="input px13 bg-white" /></div>
        </div>
       </div>
        </div>
      </div>
     </form>
     <div className="my-col-10 off-1 xs-12 centered down-3 xs-down-6 px13 monR"><span>Sign up means you have read and agreed with<span className="color-code-1 pd-5 bold  c-pointer" onClick={()=> {history.push('/general/terms')}}>L-Mobile Terms.</span></span></div>
      <button className="my-btn-sm my-col-10 off-1 centered mother down-2 xs-down-5 bg-color-code-1 white px13" id="sub_btn" onClick={(e)=> {submitHandler(e)}}>Sign Up</button>
    </div>
      <div className="mother xs-down-5 down-1 centered monR bg-faded"><span className=" pd-5 px13 anchors color-code-1" onClick={()=> {history.push('/user_login')}}>I already registered  <i className="fas fa-angle-right"></i>  </span></div>
   </div>
 </div>
 </> }
 </> );
}
 
export default RefLink;