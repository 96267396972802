import { useContext, useState } from "react";
import useUtils from "../utils/useutils";
import { CartContext } from "../context/cartcontext";
import CartItem from "./cartItem";
import { AuthContext } from "../context/authcontex";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Payment from "./payment";


const Cart = ({setOpenCart}) => {
 const {formatNumber, calculateDelieveryPrice, convertToTwoDecimalPlaces} = useUtils();
 const {cart, weight, totalPrice} = useContext(CartContext);
 const {currentUser} = useContext(AuthContext);
 const history = useHistory();
 const [pay, setPay] = useState(false);

return ( <>
{pay && <Payment setPay={setPay} setOpenCart={setOpenCart}/>}
 <div className="my-modal my-fade-in">
  <div className="my-cart-modal my-col-4 xs-down- bg-faded">
   <div className="my-col-10 off-1 xs-10 xs-off-1 xs-down-5 down-5">
    <div className="mother bd-bottomld">
     <span className="bold black-2">Order Summary</span>
     <span className="fl-right pd-5" onClick={()=> {setOpenCart(false)}}><i className="fas fa-times c-pointer"></i></span>
    </div>
   <div className="mother down-1 xs-down-3 monR">
    <div className="px12 my-col-4 xs-6 faded  xs-px13">
      Total Items: 
      <div> <span className="black-2 bold">{cart?.length}</span>  </div>
    </div>
    <div className="px12 my-col-4 xs-6 xs-px13 faded monR">Total Weight:
    <div> <span className="black-2 bold">{convertToTwoDecimalPlaces(weight)}Kg</span></div>
    </div>
   </div>
   <div className="mother down-5 xs-down-5 ov-scroll-cart">
    {cart?.length < 1? <div className="mother down-30 xs-down-10 faded centered">Empty Cart</div> :
     <>
     {cart?.map((i, index) => (
       <CartItem i={i} key={'asas' + index} />
     ))}
     </>
    }
  </div>
  <div className="mother down-1 monR ">
   <div className="my-col-6 xs-6 down-2 bd-bottom">
    <div className="my-col-12"><span className="px13 faded">Subtotal:</span></div>
    <div className="my-col-12"><span className="px10 bold">₦{formatNumber(totalPrice)}</span></div>
   </div>
   <div className="my-col-6 xs-6  down-2 bd-bottom">
    <div className="my-col-12"><span className="px13 faded">Delivery Fee:</span></div>
    <div className="my-col-12"><span className="px10 bold">₦{formatNumber((calculateDelieveryPrice(weight)))}</span></div>
   </div>

   {currentUser === null ? <>
    <div className="my-col-12 down-2 xs-12 xs-down-5" onClick={()=> {history.push('/user_login')}}>
    {totalPrice > 0 && <span className="mother my-btn-sm centered white px13 bg-color-code-1 c-pointer">
     checkout ₦{formatNumber(parseInt(totalPrice) + parseInt(calculateDelieveryPrice(weight)))}
    </span>}
   </div>
   </> : <>
   {/* // fluter wave integration */}
   <div className="my-col-12 down-2 xs-12 xs-down-5">  
    {totalPrice > 0 && 
   <span className="mother my-btn-sm centered white px13 bg-color-code-1 c-pointer" onClick={()=> {setPay(true)}}>
     checkout ₦{formatNumber(parseInt(totalPrice) + parseInt(calculateDelieveryPrice(weight)))}
    </span>
    }
   </div>
   </>}
   </div>
   </div>
  </div>
 </div>
 </> );
}
 
export default Cart;