import { useContext, useEffect, useState } from "react";
import CatSkeleton from "../skeleton/catSeleton";
import Menu from "./menu";
import ViewOrder from "./vieworder";
import { DeliverContext } from "../../context/delivercontext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import useUtils from "../../utils/useutils";

const Orders = () => {
  const [details, setDetails] = useState(null);
  const {formatTransactionTime} = useUtils();
  const history = useHistory();
  const {orders, getOrders, currentUser} = useContext(DeliverContext);
  const now = moment();
  const formattedTime = now.format('YYYY-MM-DD HH:mm:ss');
  const token = localStorage.getItem('l-dPers');
  
  useEffect(() => {
   if(token){
    getOrders(token)
   }
  }, [token])

  return ( <>
  <Menu active={'order'}/>
  <div className="my-col-10 off-2 xs-12 down-7 xs-down-10vh">
    <div className="my-col-10 off-1 my-container">
      <div className="mother bd-bottom-bold"><span className="px13 upper-case faded bold">Orders</span></div>
     
      <div className="mother down-4 xs-down-5">
      {orders === null ?  <div className="mother down-4 xs-down-5"><CatSkeleton slideToShow={4}/></div> : <>
      {orders?.length < 1 && <span className="faded">No Orders</span>  }
       {orders?.map((i, index) => (
         <div className="my-col-3 xs-12 xs-down-3" key={index + 'orderss'}>
          {details === 'see-details' + i.id && <ViewOrder setDetails={setDetails} i={i} from={'order'} />}
           <div className="my-col-11 xs-12 bg-color-code-2 my-b-shadow rad-10 my-bottom-20" onClick={()=> {setDetails('see-details' + i.id)}}>
             <div className="my-col-10 off-1 xs-10 xs-off-1 xs-down-5 down-6">
               <div className="mother"><span className="bold px13 color-cod1 upper-case">{i.order_id} - {i.location}</span></div>
               <div className="mother"><span className="px10 upper-case">{formatTransactionTime(i?.trn, formattedTime)}</span></div>
               <div className="mother down-5 xs-down-5"><span className="pd-10 bg-color-code-1 white px10 c-pointer" onClick={()=> {setDetails('see-details' + i.id)}}>See details</span></div>
             </div>
           </div>
         </div>
       ))}
      </>}
      </div>
    </div>
  </div>
  </> );
}
 
export default Orders;