import {createContext, useEffect, useState} from 'react';
import useApi from '../hooks/useApi';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export const DeliverContext = createContext();

const DeliverContextProvider = (props) => {
 const token = localStorage.getItem('l-dPers');
 const path = 'https://lmobileapi.000webhostapp.com/images/'
 const {requestMaker} = useApi();
 const history  = useHistory();
 const [currentUser, setCurrentUsers] = useState(null);
 const [orders, setOrders] = useState(null)
 const [Tasks, setTasks] = useState(null)
 const [History, setHistory] = useState(null)
 
 const logoutOut = () => {
  localStorage.removeItem('l-dPers');
  setCurrentUsers(null);
  history.push('/riders_login')
 }


 const getPersonnel = async(Token) => {
  const params = {token:Token, action:'get_personnel'}
  const result = await requestMaker('delivery/current', params);
  if(result?.status === 'successful'){
   setCurrentUsers(result?.data);
   return
  }
  if(result?.status === 'SESSION EXPIRED'){
    logoutOut()
    return;
   }
 }

 const getOrders = async(Token) => {
  const params = {token:Token, action:'get_orders'}
  const result = await requestMaker('delivery/current', params);
  if(result?.status === 'successful'){
    setOrders(result?.data);
    return
  }
  if(result?.status === 'SESSION EXPIRED'){
    logoutOut()
    return;
   }
 }

 const getTasks = async(Token) => {
  const params = {token:Token, action:'get_tasks'}
  const result = await requestMaker('delivery/current', params);
  if(result?.status === 'successful'){
    setTasks(result?.data);
    return
  }
  if(result?.status === 'SESSION EXPIRED'){
    logoutOut()
    return;
   }
 }
 

 const getHistory = async(Token) => {
  const params = {token:Token, action:'get_history'}
  const result = await requestMaker('delivery/current', params);
  if(result?.status === 'successful'){
    setHistory(result?.data);
    return
  }
  if(result?.status === 'SESSION EXPIRED'){
    logoutOut()
    return;
   }
 }

   
 return ( 
  <DeliverContext.Provider value={{
   getPersonnel,
   currentUser,
   getTasks,
   logoutOut,
   getHistory,
   setHistory,
   History,
   orders,
   Tasks,
   setTasks,
   getOrders,
   token,
   path
  }}>
   {props.children}
  </DeliverContext.Provider>
 );
}
 
export default DeliverContextProvider;
