import { useContext, useEffect, useRef, useState } from "react";
import { CategoryContext } from "../context/categorycontext";
import SubDb from "./subdb";


const Dropdowns = ({Array, title}) => {
 const {allCat} = useContext(CategoryContext);
 const caretRef = useRef();
 const optionsRef = useRef();
 const [subCat, setSubCat] = useState([]);
 const [Options, setOptions] = useState(false)
 var x = 1;
 
 useEffect(() => {
   const elem = allCat?.filter((i) => i.main_Cat == title);
    if(elem){
     setSubCat(elem)
    }
 }, [allCat])



 const dpHandler = () => {
  if(x === 1){
   caretRef.current.classList.add('rotate')
   optionsRef.current.style.display = 'block';
   // setOptions(true)
   x = 1
  }else {
   // setOptions(false)
   caretRef.current.classList.remove('rotate')
   optionsRef.current.style.display = 'none';
   x = 1
  }
 }


 return ( <>
  <div className="mother xs-px13" >
   <span className='black bold'>Products</span>
   <span className='faded xs-down-3 fl-right values_from_array rotate' ref={caretRef}><i className='fas fa-angle-right'></i></span>
  </div>
 <div className="mother values_from_array fade-in  down-3" ref={optionsRef}>
  <div className="mother sub_car_dp xs-down-5">
   {subCat?.map((i) => (
    <div className="xs-11 xs-down-5 my-col-12" key={'name' + i.id}>
     <SubDb title={i.cat_name} i={i} options={i.sub_cat}/>
   </div>
   ))}
  </div>
  </div>
 </> );
}
 
export default Dropdowns;